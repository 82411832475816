<template>
  <div v-if="isVisible" :class="[errClass, formWidthClass, 'input-field oba-dropdown']">
    <vue-multiselect
      :id="customId"
      v-model="v$.value.$model"
      :options="options"
      :class="errClassDropdown"
      label="label"
      track-by="label"
      placeholder="-"
      :disabled="isDisabled"
      :searchable="true"
      v-on:clear="clearAll()"
      @select="selectedData($event)">
  </vue-multiselect>
    <span 
    v-for="error of v$.$errors" :key="error.$uid"
    class="helper-text" :data-error="error.$message">
  </span>
  <label :class="['active', isDisabled ? 'disabled-label' : 'dropdown-label']">{{ customLabel }}</label>
  <span v-if="helperText" class="helper-text">{{ helperText }}</span>
</div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'
import VueMultiselect from '@vueform/multiselect'


export default {
  name: 'oba-dropdown-search',
  setup(props) {
  const v$ = setValidations(props.validations, props.inputValue, props.label)
  return { v$ }
  },
  mixins: [formInputMixin],
  components: {
    'vue-multiselect': VueMultiselect},
  props: {
    change: { type: Function },
    extra: {
      type: Object,
      required: true,
      default() {
        return {
          /**
          options props is an array of object, 
          here's an example :
          {
            id : 1,
            label : "Jakarta",
            value: "Jakarta",
            disabled: false
          }
           */
          options  : []
        }
      }
    },
  },
  data() {
      return {
        options: this.extra.options,
      }
  },
  beforeMount(){
    
  },
  computed:{
    errClassDropdown() {
      return this.v$.value.$error ? "error" : ""
    },
  },
  methods: {  
    selectedData(value){
      this.v$.value.$model == value

       //update value
      this.validateData(value)
      this.publishValue(value, "dropdown")
      this.$emit("updated", value)
    },
    clearAll () {
      this.v$.value.$model = ""
      this.validateData("")
      this.publishValue("", "dropdown")
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "./index.scss";
</style>