<template>
  <div class="dot-more">
    <i :class="['material-icons', 'right', isShowMenu ? 'clicked' : '']" @click.stop="isShowMenu = !isShowMenu">more_horiz</i>
    <div v-if="isShowMenu" v-click-outside="() => {isShowMenu = false}" class="menu">
      <span v-for="item, index in menu" :key="index" @click.stop="selected(item)" class="item">
        {{item.label}}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'oba-dot-more',
  props: {
    menu: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isShowMenu: false,
    }
  },
  methods: {
    selected(item){
      this.isShowMenu = false
      this.$emit('selected', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>