import store from '@/store'
import {convertToDataForm} from "@/lib/transform"

function showNotification(type, text) {
  let icon = ''
  let color = ''

  if(type.toLowerCase() == 'success'){
    icon = "check"
    color = "#6DD400"
  }else{
    icon = "warning"
    color = "#FF9191"
  }
 
  const toastHTML = `<i class="material-icons" style="margin-right:8px; color:${color}">${icon}</i><span>${text}</span>`
  M.toast({html: toastHTML});
}

function generateRandomId(){
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const charactersLength = characters.length;
  for ( let i = 0; i < 6; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result
}

function generateSummary(collection, data){
  let formData = JSON.parse(JSON.stringify(data))
  let summaryFormat = store.getters['config/getCollectionSummary'](collection)
  let specialChar = /[$}{]/g
  let wordlist = []
  let result = []
  if(summaryFormat){
    wordlist = summaryFormat.replace(specialChar,'.').split('.')
    wordlist.forEach(item => {
      if(item != ''){
        if(formData[item]){
          if(item.toLowerCase() == 'assignee'){
            const assignee = formData[item].uid ? formData[item].uid : formData[item]
            formData[item] = store.getters['user/getEmailByUid'](assignee)
          } else {
            formData[item] = convertToDataForm(item, formData[item], collection)
          }
          result.push(formData[item])
        } else {
          result.push(item)
        }
      }
    })
  } else {
    return ''
  }
  return result.join('')
}

export{
  showNotification,
  generateRandomId,
  generateSummary
}