<template>
  <div class="wrapper">
    <div class="form-container">
      <div class="form">
        <div class="label">Login</div>
        <oba-form-builder ref="formLogin" :fields="fields" @keydown.enter="login"/>
        <div class="submit">
          <oba-button
            label="Login"
            category="primary"
            size="large"
            :isDisabled="isDisabled"
            :isLoading="isLoading"
            @onClick="login"
          />
        </div>
      </div>
      <div v-if="invalidAuth || tooManyRequest" class="error">
        <img class="icon" src="@/assets/icon/adx-ic-warning.svg" alt="" />
        <span v-if="invalidAuth" class="text">
          Incorrect username or password, please try again
        </span>
        <span v-if="!invalidAuth && tooManyRequest" class="text">
          Too many login failed attempts. Please wait before try to login
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { createNamespacedHelpers } from "vuex";

import FormBuilder from "@/components/formBuilder";
import Button from "@/components/button";

import { generateProps } from "@/lib/propsGenerator";
import { config } from "@/lib/config.js";

const { mapMutations: mapMutationUser } = createNamespacedHelpers("user");

export default {
  name: "oba-login",
  setup() {
    const data = [
      {
        name: "Email",
        type: "text",
        placeholder: 'Input email address',
        validations: {
          required: true,
          email: true,
        },
        inputType: "email",
      },
      {
        name: "Password",
        type: "password",
        placeholder: "Input password",
        icon: {
          name: "visibility",
          position: "right",
          function: (value, _this) => { 
            if (_this.extra.icon.name == "visibility") {
              _this.extra.icon.name = "visibility_off"
              _this.extra.inputType = "text"
            } else {
              _this.extra.icon.name = "visibility"
              _this.extra.inputType = "password"
            }
          },
        },
        validations: {
          required: true,
        },
      },
    ];
    let fields = generateProps(data);
    return { fields };
  },
  mounted() {
    this.$refs.formLogin.$el.getElementsByTagName(`input`)[0].focus()
  },
  components: {
    "oba-form-builder": FormBuilder,
    "oba-button": Button,
  },
  data() {
    return {
      invalidAuth: false,
      isLoading: false,
      isDisabled: false,
      tooManyRequest: false,
    };
  },
  methods: {
    ...mapMutationUser({
      saveUserInfo: "SAVE_USER_INFO",
    }),
    setLoadingAndDisable(state) {
      this.isLoading = state;
      this.isDisabled = state;
    },
    login() {
      this.setLoadingAndDisable(true);
      const form = this.$refs["formLogin"];
      const dataForm = form.collectData();
      if (dataForm.length == 0) {
        this.setLoadingAndDisable(false);
        return;
      }
      const email = dataForm[0].value.toLowerCase();
      const password = dataForm[1].value;
      // use emulator if environtment is development
      if (config.environtment == "development") {
        firebase.auth().useEmulator("http://localhost:9099");
      }
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
          this.invalidAuth = false;
          this.tooManyRequest = false;
          const user = userCredential.user;
          const userInfo = user.toJSON();
          const idToken = await user.getIdToken();
          this.saveUserInfo({
            email: userInfo.email,
            token: idToken,
            uid: userInfo.uid,
          });
          this.setLoadingAndDisable(false);
          if (this.$route.query.next) {
            let queries = { ...this.$route.query };
            let next = queries.next;
            delete queries.next;
            this.$router.push({ name: next, query: queries });
          } else {
            this.$router.push({ name: "inputproject" });
          }
        })
        .catch((error) => {
          this.setLoadingAndDisable(false);
          if(error.code == 'auth/too-many-requests') {
            this.invalidAuth = false;
            this.tooManyRequest = true;
          }
          else {
            this.tooManyRequest = false;
            this.invalidAuth = true;
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style> 
