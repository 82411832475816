import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import router from './router'

const customCollections = [
  {
    name: "queue",
    displayText: "Queue",
    headerDesc: "This is all your queue reports",
    table: ["name","created at", "status"],
    fieldsType: {
      "name": "text",
      "created at": "datetime",
      "status": "text"
    },
    entries: [
      {
        name: "name",
        displayText: "Name",
        type: "text",
      },
      {
        name: "created at",
        displayText: "Created at",
        type: "date",
      },
      {
        name: "status",
        displayText: "Status",
        type: "text",
      },
    ],
  },
  {
    name: "members",
    displayText: "Member Management",
    table: ["email", "groups", "role", "status", "uid"],
    headerDesc: "This is all your current users and roles",
    fieldsType: {
      "email": "member",
      "groups": "member",
      "role": "member",
      "status": "member",
      "uid": "member"
    },
    entries: [
      {
        name: "email",
        displayText: "Email",
        type: "member"
      },
      {
        name: "groups",
        displayText: "Groups",
        type: "member"
      },
      {
        name: "role",
        displayText: "Role",
        type: "member"
      },
      {
        name: "status",
        displayText: "Status",
        type: "member"
      },
    ],
  },
  {
    name: "reports",
    displayText: "Reports",
    table: [
      "layout_name",
      "created",
      "collection",
      "templateid"
    ],
    fieldsType: {},
    entries: [
      {
        name: "layout_name",
        displayText: "Layout name",
        type: "text",
      },
      {
        name: "created",
        displayText: "Created at",
        type: "date",
      },
      {
        name: "collection",
        displayText: "Collection",
        type: "text",
      },
      {
        name: "templateid",
        displayText: "Template ID",
        type: "text",
      },
    ],
  },
  {
    name: "bulkEdit",
    displayText: "Bulk Edit",
    table: [],
    fieldsType: {},
    entries: [],
  },
  {
    name: "importUsers",
    displayText: "Import Users",
    table: [],
    fieldsType: {},
    entries: [],
  },
  {
    name: "importConfig",
    displayText: "Project Configuration",
    table: [],
    fieldsType: {},
    entries: [],
  }

]

const customCollectionsName = customCollections.map(item =>{
  return item.name
})

const userStore = {
  namespaced: true,
  state() {
    return {
      authToken: null,
      email: null,
      uid: null,
      role: null,
      pageState: null, // {selectedPage, collection, filters}
      loadingPageState: true,
      userList: [],
      groups:[],
    }
  },
  mutations: {
    SAVE_USER_INFO(state, payload) {
      state.authToken = payload.token
      state.email = payload.email
      state.uid = payload.uid
    },
    CLEAR_USER_INFO(state) {
      state.email = null
      state.uid = null
      state.authToken = null
      state.role = null
      state.groups = []
    },
    DELETE_PAGE_STATE(state) {
      state.pageState = null
    },
    SAVE_PAGE_STATE(state, pageData){
      state.pageState = pageData
    },
    SAVE_USER_LIST(state, userList){
      state.userList = userList
    },
    CLEAR_USER_LIST(state){
      state.userList = []
    },
    CLEAR_USER_GROUP(state){
      state.groups = []
    },
    SAVE_USER_ROLE(state, payload) {
      state.role = payload
    },
    SAVE_USER_GROUP(state, payload) {
      state.groups = payload
    },
    SET_PAGE_LOADING_STATE(state, payload) {
      state.loadingPageState = payload
    }
  },   
  actions:{
    logout({commit}) {
      commit('CLEAR_USER_LIST')
      commit('CLEAR_USER_INFO')
      commit('config/RESET_CONFIG', null, {root:true})
      commit('DELETE_PAGE_STATE')
      router.push({ name: 'login' })
    }
  },
  getters: {
    /**
     * Get user email by uid from userList state
     * @param {*} state 
     * @returns {String} email
     * "user@mail.com"
     */
    getEmailByUid: state => uid => {
      const user = state.userList.find(user => user.uid == uid)
      if(user){
        return user.email
      }
      return null
    },
    /**
     * Get user uid by email from userList state
     * @param {*} state
     * @param {String} email
     * "user@adxasia.co.id"
     * @returns {String}
     * "uid62776"
     */
    getUidByEmail: state => email => {
      const user = state.userList.find(user => user.email.toLowerCase() == email.toLowerCase()) 
      if(user){
        return user.uid
      }
      return null
    },
    /**
     * Get user role by uid from userList state
     * @param {*} state
     * @param {String} uid
     * "uid62776"
     * @returns {String}
     * "userrold ex: admin"
     */
    getGroup: state => uid => {
      const user = state.userList.find(item => item.uid == uid)
      if(user){
        if(user.groups.includes('admin')){
          return 'admin'
        } else {
          return null
        }
      }
      return null
    },
    /**
     * check if user is admin
     * @param {*} state
     * @returns {Boolean}
     * true if user is admin
     * false if user is not admin
     */
    isAdminGroup: state => {
      if(state.groups.includes('admin')) {
        return true
      } 
      return false
    },
    isPageLoading: state => {
      return state.loadingPageState
    }
  }
}

const configStore = {
  namespaced: true,
  state() {
    return {
      projectName: null,
      projectDisplay: null,
      collections: [],
      currentCollection: null,
      dashboardUrls: null
    }
  },
  mutations: {
    SAVE_CURRENT_COLLECTION(state, data) {
      state.currentCollection = data
    },
    SAVE_CONFIG(state, data) {
      state.collections = data.collections
      state.currentCollection = data.collections[0].name
      state.projectName = data.projectName
      state.projectDisplay = data.projectDisplay
      state.dashboardUrls = data.dashboardUrls
    },
    RESET_CONFIG(state){
      state.projectName = null,
      state.projectDisplay = null,
      state.collections = [],
      state.currentCollection = null
      state.dashboardUrls = null
    },
    SAVE_FIELDS_TYPE_TO_COLLECTION(state, payload) {
      state.collections.forEach(collection => {
        if(payload.collection == collection.name){
          collection['fieldsType'] = payload.fieldsType
        }
      })
    }
  },
  getters: {
    /**
     * Get custom collection by collection key
     * @param {*} state 
     * @param {String} collectionKey
     * "queue", "members", "reports", "bulkedit", "importusers", "importconfig"
     * @returns 
     * {
     *  name: "queue",
     *  displayText: "Queue",
     *  headerDesc: "This is all your queue reports",
     *  table: ["name","created at", "status"],
     *  fieldsType: {
     *    "name": "text",
     *    "created at": "datetime",
     *    "status": "text"
     *  },
     *  entries: [
     *  {
     *    name: "name",
     *    displayText: "Name",
     *    type: "text",
     *  },
     *  {
     *    name: "created at",
     *    displayText: "Created at",
     *    type: "date",
     *  },
     *  {
     *    name: "status",
     *    displayText: "Status",
     *    type: "text",
     *  },],
     * }
     * 
     */
    getCustomCollection: state => collectionKey => {
      switch(collectionKey.toLowerCase()){
        case 'members':
          return customCollections[1]
        case  'queue':
          return customCollections[0]
        case  'reports':
          return customCollections[2]
        case 'bulkedit':
          return customCollections[3]
        case  'importusers':
          return customCollections[4]
        case  'importconfig':
          return customCollections[5]
      }
      return null
    },
    /**
     * Get table columns by collection key
     * @param {*} state 
     * @param {String} collectionKey
     * "collection_name"
     * @returns list of table columns
     * ["field_name1", "field_name2", "field_name3"]
     */
    getTableColumns: state => collectionKey => {
      const collection = state.collections.find( item => item.name.toLowerCase() == collectionKey.toLowerCase())
      if(collection){
        const table = JSON.parse(JSON.stringify(collection.table)) 
        return table
      }
      return null
    },
    /**
     * Get field status based on collection key and selected collection
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @returns {String | null} field name
     * "status"
     */
    getFieldStatus: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if (collection) {
        const field = collection.entries.find(entry => entry.name.toLowerCase() == 'status')
        if (field) {
          return field.name
        }
      }
      return null
    },
    /**
     * Get field assignee based on collection key and selected collection
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @returns {String | null} field name
     * "assignee"
     */
    getFieldAssignee: (state, getters)=> (collectionKey) => {
      let collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        const assigneeField = collection.entries.find(entry => entry.name.toLowerCase() == 'assignee' )
        if (assigneeField) {
          return assigneeField.name
        }
      }
      return null
    },
    /**
     * Get collection by collection key on list of collections in vuex
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @returns {Object | null} collection
     * object of collection you can see in sample config
     */
    getCollection: state => collectionKey => {
      const collection = state.collections.find( item => item.name.toLowerCase() == collectionKey.toLowerCase())
      if(collection){
        return collection
      }
      return null
    },
    /**
     * Get selected collection by collection key
     * check if collection is custom or not and return the collection
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @returns {Object | null} collection
     * object of collection you can see in sample config
     */
    getSelectedCollection: (state, getters) => collectionKey => {
      if(customCollectionsName.includes(collectionKey)){
        return getters.getCustomCollection(collectionKey)
      } else {
        return getters.getCollection(collectionKey)
      }
    },
    /**
     * Get collection entries by collection key
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @returns {Array} entries
     * array of entries you can see in sample config
     */
    getCollectionEntries: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection) {
        const entries = collection.entries.filter(item => item.type.toLowerCase() != 'subitems')
        return entries
      }
      return []   
    },
    /**
     * Get transition status based on collection key and current status
     * @param {*} state
     * @param {String} collectionKey
     * "collection_name"
     * @param {String} currentStatus
     * "status_name"
     * @returns {Array} transitionList
     * array of transition status you can see in sample config
     */
    getTransitionStatus: (state, getters) => (collectionKey, currentStatus) => {
      const collection = getters.getSelectedCollection(collectionKey)
      let transitionList = []
      if(collection) {
        if(collection.transitions) {
          collection.transitions.forEach(transition => {
            if(transition.from.toLowerCase() == currentStatus.toLowerCase()){
              transitionList.push(transition)
            }
          })
          return transitionList
        }
      }
      return []
    },
    getCollectionFieldsType: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection) {
        return collection.fieldsType
      }
      return null
    },
    getDataType: (state, getters) => (fieldName, collectionKey) => {
      let type = 'text'
      const fieldsType = getters.getCollectionFieldsType(collectionKey)
      const entries = getters.getCollectionEntries(collectionKey)
      const field = entries.find(item => item.name.toLowerCase() == fieldName.toLowerCase())
      if(fieldsType[fieldName]){
        type = fieldsType[fieldName]
      } else {
        if(field){
          if(field.type.toLowerCase() == 'file'){
            type = 'attachment'
          } else if(field.type.toLowerCase() == 'number'){
            type = 'long'
          } else if(field.type.toLowerCase() == 'date'){
            type = 'date'
          } else if(field.type.toLowerCase() == 'datetime'){
            type = 'datetime'
          } else if(field.type.toLowerCase() == 'geopoint'){
            type = 'geopoint'
          }
        }
      }
      return type
    },
    getEditPermission: (state, getters) => (collectionKey, currentStatus) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.editPermission){
          if(collection.editOnStatus && collection.editOnStatus.length !=0){
            const availableStatus = collection.editOnStatus.find(status => status.toLowerCase() == currentStatus.toLowerCase())
            if(availableStatus){
              return true
            } else {
              return false
            }
          }
          return collection.editPermission
        } else {
          return false
        }
      }
      return false
    },
    getAssignPermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.assignPermission){
          return collection.assignPermission
        } else {
          return false
        }
      }
      return false
    },
    getExcludedFields: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.excludeFields){
          return collection.entries.filter(field => {
            return collection.excludeFields.includes(field.name)
          })
        }
      }

      return []
    },
    getStatuses: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.statuses){
          return collection.statuses
        }
      }
      return []
    },
    getIsShowZeroValue:(state, getters) => (fieldName, collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        const field = collection.entries.find(item => item.name.toLowerCase() == fieldName.toLowerCase())
        if(field){
          if(field.type == 'number' && field.showZeroValue){
            return field.showZeroValue
          }
        }
      }
      return false
    },
    getDeletePermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.deletePermission){
          return collection.deletePermission
        } else {
          return false
        }
      }
      return false
    },
    getCreatePermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.createPermission){
          return collection.createPermission
        } else {
          return false
        }
      }
      return false
    },
    getImportPermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.importPermission){
          return collection.importPermission
        } else {
          return false
        }
      }
      return false
    },
    getExportPermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.exportPermission){
          return collection.exportPermission
        } else {
          return false
        }
      }
      return false
    },
    getBastPermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.bastPermission){
          return collection.bastPermission
        } else {
          return false
        }
      }
      return false
    },
    getDownloadAttachmentPermission: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.downloadAttachmentPermission){
          return collection.downloadAttachmentPermission
        } else {
          return false
        }
      }
      return false
    },
    getInitialStatus: (state, getters) => collectionKey => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.initialStatus){
          return collection.initialStatus
        }
      }
      return 'open'
    },
    getDisplayText: (state, getters) => (fieldName, collectionKey) => {
      const entries = getters.getCollectionEntries(collectionKey)
      if(entries.length != 0){
        const entry = entries.find(item => item.name.toLowerCase() == fieldName.toLowerCase())
        if(entry){
          return entry.displayText
        }
      }
      return ''
    },
    getSubItem: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        if(collection.entries.length != 0){
          const subItems = collection.entries.filter(item => item.type.toLowerCase() == 'subitems')
          return subItems
        }
      }
      return []
    },
    getDefaultFilters: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        return collection.filters
      }
      return []
    },
    getCollectionSummary: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        return collection.summary
      }
      return ''
    },
    getAttachmentNamePermission: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        return collection.displayAttachmentName
      }
      return false
    },
    getOnCreateEntries: (state, getters) =>(collectionKey) =>{
      const collection = getters.getSelectedCollection(collectionKey)
      const entries = getters.getCollectionEntries(collectionKey)

      if(collection.showOnCreate.length != 0){
        let onCreateEntries = []
        onCreateEntries = entries.filter(field => {
          return collection.showOnCreate.includes(field.name)
        })
        return onCreateEntries
      } 
      return entries
    },
    getAutoAssign: (state, getters) => (collectionKey) => {
      const collection = getters.getSelectedCollection(collectionKey)
      if(collection){
        return collection.autoAssign
      }
      return false
    }
  }
}

const store = new createStore({
  modules: {
    user: userStore,
    config: configStore
  },
  plugins: [createPersistedState()],
})

export default store

