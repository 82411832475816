import axios from 'axios'
import store from '@/store'
import {config as settings} from '@/lib/config'

const instance = axios.create({
  baseURL: settings.baseURL,
  headers: {
    'Content-Type': 'application/json',
  }
})

/**
 * Get the authToken if needed
 * 
 * @param {Boolean} needToken 
 * @returns headers: { 'Authorization': `Bearer ${authToken}` } if true
 * @returns {} if false
 */

const getHeadersAuth = (needToken) => {
  if (needToken) {
    const authToken = store.state.user.authToken
    return {
      headers: { 'Authorization': `Bearer ${authToken}` }
    }
  }
  return {}
}

/**
 * Get the config to hit url with header
 * 
 * @param {Object} config 
 * {
      url: ApiUrl,
      method: httpMethod,
      data : data
    }
 * @param {Object} options 
    {needToken: true}
 * @returns {Object} config, {Object} headers
 */
const setConfig = (config, options) => {
  const headersAuth = getHeadersAuth(options.needToken)
  return { ...config, ...headersAuth }
}


/**
 * Hit a request using instance function that uses axios
 * 
 * @param {Object} config 
 * {
      url: ApiUrl,
      method: httpMethod,
      data : data
    }
 * @param {Object} options 
    {needToken: true}
 * @returns {Response} // {Status, Response}
 */
export const doRequest = async (config, options={}) => {
  const reqConfig = setConfig(config, options)
  let response = null
  try {
    response = await instance(reqConfig)
  } catch (error) {
    response = error.response
     // this logic to handle expired token
     if(response.status){
        if(response.status == 401)store.dispatch('user/logout')
     }
  }
  return response
}

export const errorMessage = {
  400: `Bad Request`,
  401: `You're Not Authorized`,
  403: `This action is forbidden`,
  404: `Not Found`,
  500: `Internal Server Error`,
  503: `No Internet Connection`
}


export default {
  doRequest,
  errorMessage
} 
