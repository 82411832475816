import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import VueKonva from 'vue-konva';

// Materialize framework
import 'materialize-css/dist/css/materialize.min.css'

// Custom css
import './styles/base.scss'

import router from './router'
import store from './store'

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const emitter = mitt()// Initialize mitt
const app = createApp(App)

app.directive("click-outside", clickOutside)
app.use(router)
app.use(store)
app.use(VueKonva);

app.config.globalProperties.emitter = emitter
app.mount('#app')