<template>
   <div class="progress" :style="{'width': width}">
      <div class="indeterminate"></div>
  </div>
</template>

<script>

export default {
  name: 'oba-loading',
  props:{
    width:{
      type: String,
      required: false,
      default: "100%"
    }
  }
  
}
</script>

<style lang='scss'>
.progress {
    height: 5px;
    background-color: #d8d8d8;
}

.progress {
  .indeterminate {
    background-color: #0085ff;
  } 
}
</style>
