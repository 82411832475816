<template>
  <div class="wrapper">
      <iframe v-if="Array.isArray(dashboardUrls)" class="iframe" :src="dashboardUrls[index].url" frameborder="0" >
      </iframe>
      <iframe v-else class="iframe" :src="dashboardUrls" frameborder="0" >
      </iframe>
  </div>
</template>


<script>
import "firebase/auth"
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapStateConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-dashboard',
  data() {
    return {
      index: 0
    }
  },
  computed: {
    ...mapStateConfig([
      'dashboardUrls'
    ]),
  },
  beforeMount() {
    this.index = this.$route.query.index
  },
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style> 
