<template>
  <oba-modal 
    class="modal-delete" 
    id="modal-bulk-delete" 
    title="Delete Ticket"
    :show="visible" 
    @close="closeBulkDelete">
    <template v-slot:modal-content>
      <div v-if="deleteAble" class="mid-content">
        <span >Are you sure want to delete {{totalTickets}} tickets from this list?</span>
        <span class="warning">Warning : This operation cannot be undone</span>
      </div>
      <div v-else class="restricted">You dont have permission to delete tickets</div>
    </template>
    <template v-if="deleteAble" v-slot:modal-footer>
        <div class="buttons">
          <oba-button 
            class="btn-cancel" 
            category="secondary" 
            label="Cancel" 
            @onClick="closeBulkDelete">
          </oba-button>
          <oba-button 
            category="primary" 
            label="Delete" 
            :isDisabled="isRequesting" 
            :isLoading="isRequesting" 
            @onClick="deleteData">
          </oba-button>
        </div>
    </template>
  </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import modal from '@/components/modal'
import ObaButton from '@/components/button'

import { doRequest } from '@/lib/request'
import { apiUrls } from '@/lib/request/urls'
import { showNotification } from '@/lib/utils'

const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser} = createNamespacedHelpers('user')

export default {
  name: 'bulk-delete',
  emits: ['closeDelete','onBulkDelete'],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true
    },
    documents: {
      type: Array,
      required: false,
      default: []
    },
    isSelectedAll: {
      type: Boolean,
      required: true,
      default: false
    },
    collection: {
      type: String,
      required: true
    },
    totalData: {
      type: Number,
      required: true
    },
  },
  components: {
    'oba-modal': modal,
    'oba-button': ObaButton
  },
  setup(){
    const bulkDelete = async (projectName, collectionKey, data) => {
      const config = {
        url: apiUrls.bulkDelete(projectName, collectionKey),
        method: 'delete',
        data : data
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return { bulkDelete }
  },
  data(){
    return {
      fieldList: [],
      isRequesting: false,
    }
  },
  computed:{
    ...mapStateUser([
      'pageState',
    ]),
    ...mapStateConfig([
      'projectName'
    ]),
    ...mapGettersConfig([
      'getDeletePermission',
    ]),
    totalTickets(){
      if(this.isSelectedAll){
        return this.totalData
      }
      return this.documents.length
    },
    deleteAble(){
      return this.getDeletePermission(this.collection)
    },
  },
  methods: {
    async deleteData(){
      let data = {}
      this.setLoadingAndDisable(true)

      if(this.isSelectedAll){
        data = {
          filters: this.pageState.filters.length != 0 ? this.pageState.filters: [],
        }
      } else {
        data = {
          ids: this.documents
        }
      }
      const response = await this.bulkDelete(this.projectName, this.collection, data)
      if(response.status >= 200 && response.status <= 299){
        showNotification('success', `Success, tickets has been deleted`)
        this.$emit('onBulkDelete')
        this.setLoadingAndDisable(false)
        this.closeBulkDelete()
      } else {
        showNotification('error', `Error, tickets failed to delete`)
        this.setLoadingAndDisable(false)
      }
    },
    closeBulkDelete(){
      this.$emit('closeDelete')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>