<template>
  <table>
    <thead>
      <th v-for="item in header" :key="item">
        {{item}}
      </th>
      <th v-if="actionSection"> {{actionSectionHeader}} </th>
    </thead>
    <tbody>
      <tr v-for="(row, index) in body" :key="row.docId" @click.stop="selectRow(row.docId, row.columns)">
        <td v-for="(item,  idx) in row.columns" :key="`${index}-${idx}`" class="item">
          <span v-if="item.fieldName != 'name' ">{{item.value}}</span>
          <div v-if="item.fieldName == 'name'">
            <span>{{item.value.split("\n")[0]}}</span>
            <br/>
            <span>{{item.value.split("\n")[1]}}</span>
          </div>
        </td>
        <td v-if="actionSection">  
          <slot name="action" :item="row" :index="index"></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Label from '@/components/label'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-table',
  components: {
    'oba-label': Label,
  },
  props: {
    collection: {
      type: String,
      required: true
    },
    header:{
      type: Array,
      required : false,
      default: []
      /** Sample for header table structure
       *  data = [ 
            {label : "Label Name" , width: "15%"}, 
            {label : "Label Name 2" , width: "15%"}
          ]
      * */
    },
    body: {
      type:Array,
      required : false,
      default: []
      /** Sample for body table structure
       * data = [
       *  {
            docId: 'WQJENWMMDMsss',
            columns: [ 
              {fieldName: "ID", value: "installation-OBA-100",  type: "text"},
              {fieldName: "Name", value: "Done",  type: "label"},  
       *    ]
          }
        ]
      * */
    },
    actionSection: {
      type: Boolean,
      required: false,
      default: false
    },
    actionSectionHeader: {
      type: String,
      required: false,
      default: "Actions"
    },
  },
  computed: {
    ...mapGettersConfig([
      'getStatuses'
    ])
  },
  methods:{
    selectRow(id, column){
      let rowData = {}
       column.forEach((item) => {
          rowData[item.fieldName] = item.value
      })
      rowData['docId'] = id
      this.$emit('selectRow', rowData)
    }, 
    getColorStatus(value){
      /**
       * get hex color status from config
       */
      const listStatus = this.getStatuses(this.collection)
      let color = null
      listStatus.forEach( item =>{
        if(item.name.toLowerCase() == value.toLowerCase()) color = item.bgColor
      })
      return color
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
