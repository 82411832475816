<template>
<div :class="['wrapper-right', navActive ? 'sidenav-open' : 'sidenav-closed']">
  <i @click="sidenavTrigger" class="material-icons">{{triggerIcon}}</i>
  <div class="wrapper-top">
    <div class="header">
      <div class="breadcrumb">
        <oba-breadcrumb :collection="currentCollection" :projectName="projectDisplayName"/>
      </div>
      <div class="title">{{pageHeader}}</div>
    </div>
    <div class="feature" v-if="currentCollection == 'reports' && !layoutReportEmpty">
      <oba-button category="primary" label="New Layout" icon="add_circle" @onClick="toggleNewLayout(true)" class="create-layout" />
    </div>
  </div>
  <div class="wrapper-bottom">
    <div class="reports" v-if="currentCollection == 'reports'">
      <div class="empty-reports" v-if="layoutReportEmpty && !isLoading">
          <img src="@/assets/emptyState/file.png">
          <div class="message">You dont have any layout</div>
          <p>Click button bellow to create a new one</p>
          <div class="add-layout">
            <oba-button category="primary" label="New Layout" icon="add_circle" @onClick="toggleNewLayout(true)"/>
          </div>
      </div>
      <div class="table-reports" v-if="!layoutReportEmpty">
        <oba-table-action
          :collection="currentCollection"
          :header="customHeader"
          :body="tableBody"
          :actionSection="true"
          actionSectionHeader=""
        >
          <template #action="actionProps">
            <div class="action">
              <oba-dot-more
                v-if="currentCollection == 'reports'"
                :menu="moreMenu"
                @selected="selectMenu($event, actionProps.item, actionProps.index)"
              />
            </div>
          </template>
        </oba-table-action>
      </div>
    </div>
    <div class="empty-state" v-if="isEmpty">
      <oba-empty-state />
    </div>
    <div class="loading" v-if="isLoading">
      <oba-loading width="480px" />
    </div>
  </div>
  <new-layout
    class="new-layout"
    id="newLayout"
    :visible="displayNewLayout"
    type='full'
    @onCreate="loadReports"
    @close="toggleNewLayout(false)">
  </new-layout>
  <edit-layout
    class="edit-layout"
    id="editLayout"
    :data="tempData"
    :visible="displayEditLayout"
    type="full"
    @onEdit="loadReports"
    @close="toggleEditLayout(false)">
  </edit-layout>
  <delete-layout
    class="delete-layout"
    id="deleteLayout"
    :data="tempData"
    :visible="displayDeleteLayout"
    type="medium"
    @onDelete="loadReports"
    @close="toggleDeleteLayout(false)">
  </delete-layout>
</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { doRequest } from "@/lib/request/index.js"
import apiUrls from "@/lib/request/urls.js"
import { transformDataTable, transformUpperCase } from "@/lib/transform.js"
import { showNotification } from '@/lib/utils'

import button from "@/components/button"
import breadCrumb from '../../components/breadCrumb'
import tableAction from "@/components/tableAction"
import dotMore from "@/components/dotMore"
import emptyState from "@/components/emptyState"
import loading from "@/components/loading"
import newLayout from "@/views/addOns/newLayout"
import deleteLayout from "@/views/addOns/deleteLayout"
import editLayout from "@/views/addOns/editLayout"

const { mapState: mapStateConfig, mapMutations: mapMutationsConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers("config")
const { mapMutations: mapMutationsUser } = createNamespacedHelpers('user')

export default {
  name: "stacks-addons",
  setup(){
    async function getAddons(projectName) {
      const config = {
        url: apiUrls.getAddons(projectName),
        method: "get",
      };
      const response = await doRequest(config, { needToken: true });
      return response
    }

    async function getListBastConfig(projectName) {
      const config = {
        url: apiUrls.getListBastConfig(projectName),
        method: "get",
      };
      const response = await doRequest(config, { needToken: true });
      return response
    }

    return {
      getAddons,
      getListBastConfig,
    }

  },
  components: {
    "oba-button": button,
    "oba-loading": loading,
    "oba-breadcrumb": breadCrumb,
    "oba-table-action": tableAction,
    "oba-dot-more": dotMore,
    "oba-empty-state": emptyState,
    "oba-loading": loading,
    "new-layout": newLayout,
    "delete-layout": deleteLayout,
    "edit-layout": editLayout,
  },
  props: {
    navActive: { type: Boolean },
  },
  emits: ['sidenavTrigger','selected'],
  data(){
    return {
      isEmpty: false,
      isLoading: false,
      layoutReportEmpty: false,
      isDisabled: false,
      isRequesting: false,
      tableHeader: [],
      tableBody: [],
      listAddon: [],
      displayNewLayout: false,
      displayEditLayout: false,
      displayDeleteLayout: false,
      pageHeader: '',
      collectionNames: ["reports"],
      moreMenu: [
        {
          label: "Edit",
          value: "edit"
        },
        {
          label: "Delete",
          value: "delete"
        },
      ],
      tempData: [],
    }
  },
  watch:{
    'currentCollection': function (newValue, oldValue){
      if(newValue && this.collectionNames.includes(newValue)){
        this.onSelectedCollection(newValue)
      }
    }
  },
  beforeMount() {
    this.onSelectedCollection(this.currentCollection)
  },
  computed: {
    ...mapStateConfig([
      'projectName',
      'projectDisplay',
      'currentCollection',
    ]),
    ...mapGettersConfig([
      'getCustomCollection',
      'getDisplayText',
    ]),
    projectDisplayName(){
      return this.projectDisplay ? this.projectDisplay : this.projectName
    },
    triggerIcon() {
      if(this.navActive){
        return 'chevron_left'
      } else {
        return 'chevron_right'
      }
    },
    customHeader(){
      let headers = this.tableHeader.map(item => {
        const displayText  = this.getDisplayText(item, this.currentCollection)
        item = displayText ? displayText : item
        return transformUpperCase(item)
      })
      return headers
    },
  },
  methods: {
    ...mapMutationsConfig({
      'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION'
    }),
    ...mapMutationsUser([
      'SET_PAGE_LOADING_STATE'
    ]),
    selectMenu(menu, item, index){
      switch (menu.value) {
        case 'edit':
          this.tempData = item.columns
          this.toggleEditLayout(true)
          break;
        case 'delete':
          this.tempData = item.columns
          this.toggleDeleteLayout(true)
          break;

        default:
          break;
      }
    },
    toggleNewLayout(value){
      this.displayNewLayout = value
    },
    toggleEditLayout(value){
      this.displayEditLayout = value
    },
    toggleDeleteLayout(value){
      this.displayDeleteLayout = value
    },
    setButton(status){
      if(status){
        return 'Open'
      } else {
        return 'Add'
      }
    },
    sidenavTrigger() {
      this.$emit('sidenavTrigger')
    },
    onSelectedCollection(selectedCollection){
      this.isEmpty = false
      let collection = this.getCustomCollection(selectedCollection)
      this.pageHeader = collection.displayText
      this.saveCurrentCollection(selectedCollection)
      
      if(selectedCollection == 'reports'){
        this.loadReports()
      }
    },
    async loadReports(){
      this.isLoading = true
      this.layoutReportEmpty = true
      const response = await this.getListBastConfig(this.projectName)
      this.tableHeader = this.getCustomCollection(this.currentCollection).table
      if(response.status >= 200 && response.status <= 299){
        if(response.data.length != 0){
          this.tableBody = transformDataTable(this.tableHeader, response.data, "reports")
          this.layoutReportEmpty = false
        } else {
          this.layoutReportEmpty = true
        }
      } else {
        this.layoutReportEmpty = true
      }
      this.isLoading = false
      this.SET_PAGE_LOADING_STATE(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>