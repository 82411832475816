<template>
  <div :class="['separator-grouping', formWidthClass]">
    <p class="tfield">
      <span :for="customId">{{ customLabel }}</span>
    </p>
    <span class='description'>{{ extra.description }}</span>
  </div>
</template>
<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-separator',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
}
</script>
<style lang='scss' scoped>
@import './index.scss';
</style>