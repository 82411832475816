<template>
  <ul class="pagination">
    <li :class="prevDisabled ? 'disabled' : 'waves-effect'" @click="toFirstPage"><a :class="prevDisabled ? 'disabled' : ''"><i class="material-icons">keyboard_double_arrow_left</i></a></li>
    <li :class="prevDisabled ? 'disabled' : 'waves-effect'" @click="prevAndNextPage(-1)"><a :class="prevDisabled ? 'disabled' : ''"><i class="material-icons">chevron_left</i></a></li>
    <li v-for="page, index in displayPage" :key="index" 
      :class="page == currentPage ? 'active' : 'waves-effect'"
      @click="changePage(page)">
      <a :class="page == currentPage ? 'active' : ''">{{page}}</a>
    </li>
    <li :class="nextDisabled ? 'disabled' : 'waves-effect'" @click="prevAndNextPage(1)"><a :class="nextDisabled ? 'disabled' : ''"><i class="material-icons">chevron_right</i></a></li>
    <li :class="nextDisabled ? 'disabled' : 'waves-effect'" @click="toLastPage"><a :class="nextDisabled ? 'disabled' : ''"><i class="material-icons">keyboard_double_arrow_right</i></a></li>
  </ul>
</template>

<script>
export default {
  name: 'oba-pagination',
  props: {
    initPage: {
      type: Number,
      required: false,
      default: 1
    },
    maxPage: {
      type: Number,
      required: true
    },
  },
  emits: ["changePage"],
  data() {
    return {
      currentPage: this.initPage,
    }
  },
  mounted () {
    
  },
  computed: {
    displayPage() {
      let result = [];
      if (this.maxPage <= 5) {
        for (let i = 1; i <= this.maxPage; i++) {
          result.push(i)
        }
      } else if (this.currentPage < 3) {
        result = [1,2,3,4,5]
      } else if (this.currentPage > this.maxPage - 2) {
        for (let i = 4; i >= 0; i--) {
          result.push(this.maxPage - i)
        }
      } else {
        for (let i = -2; i <= 2; i++) {
          result.push(this.currentPage + i)
        }
      }
      return result
    },
    prevDisabled() {
      return this.currentPage == 1
    },
    nextDisabled() {
      return this.currentPage == this.maxPage
    }
  },
  methods: {
    changePage(selectedPage) {
      this.currentPage = selectedPage
      this.emitToParent()
    },
    prevAndNextPage(value) {
      this.currentPage += value
      this.emitToParent()
    },
    toFirstPage(){
      this.currentPage = 1
      this.emitToParent()
    },
    toLastPage(){
      this.currentPage = this.maxPage
      this.emitToParent()
    },
    emitToParent() {
      this.$emit("changePage", this.currentPage);
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>