<template>
  <div :class="['wrapper-right', navActive ? 'sidenav-open' : 'sidenav-closed']">
    <i @click="sidenavTrigger" class="material-icons">{{triggerIcon}}</i>
    <div class="menu-wrapper">
      <div class="header">
        <div class="breadcrumb">
          <oba-breadcrumb :collection="currentCollection" :projectName="projectDisplayName"/>
        </div>
        <h4>{{ pageHeader }}</h4>
        <p v-if="currentCollection != 'queue'">{{ headerDesc }}</p> 
      </div>
      <div class="feature" v-if="pageHeader == 'Member Management'">
          <oba-button oba-button category="primary" label="+ New Member" @onClick="triggerAddUser(true)"/>
      </div>
    </div>
      <div class="left-buttons" v-if="currentCollection == 'members'">
        <div class="top" v-if="!isLoading">
          <div class="filter" v-if="!isLoading">
            <button type="button" :class="['btn', 'secondary', filters.length == 0 ? '' : 'selected']" @click.stop="triggerModalFilter(true)">
              <i v-if="filters.length == 0" class="material-icons left">tune</i>
              <span v-else class="amount">{{filters.length}}</span>
              Filter
            </button>
            <oba-query-builder 
              :displayFilter="displayFilter" 
              @close="triggerModalFilter(false)" 
              :fieldOptions="sortedFieldsType" 
              @applyQuery="onFilterApplied" 
              :initQueries="filters"> 
            </oba-query-builder>
          </div>
        </div>
        <div class="bottom" v-if="!isLoading">
          <div v-if="filters.length > 0" class="filter">
            <span>Filter: </span> 
            <div v-for="filter, i in filters" :key="i" class="info">
              <span class="label">{{`${filter.field} ${filter.operator} ${filter.value != null ? filter.value : '' }`}}</span>
              <i class="material-icons" @click.stop="removeQueryFilter(i)">close</i>
            </div>
          </div>
        </div>     
      </div>
    <div class="total-data">
      <div class="detail-total-data" v-if="!isEmpty && !isLoading">
        {{ startData }} - {{ endData }} of total {{ totalData }}
      </div>
    </div>
    <div class="table-wrapper" v-if="!isEmpty && !isLoading">
      <div class="table" id="table">
        <oba-table 
          :collection="currentCollection"
          :header="customHeader"
          :body="tableBody"
          :actionSection="true"
          actionSectionHeader=""
          @selectRow="onSelectedRow"
        >
          <template #action="actionProps">
            <div class="action">
              <oba-button 
                v-if="currentCollection == 'queue' && isComplete(actionProps.item)"
                category="secondary" 
                label="Download" 
                size="small"
                icon="file_download"
                :isDisabled="isDisDown" 
                class="download-btn"
                @onClick="triggerDownloadQueue(actionProps.item.docId)"
              />
              <div class="dot-more">
                <i :class="['icon', isShowMenu && selectedDocId == actionProps.item.docId ? 'clicked': ''] " @click.stop="showFloatingMenu(actionProps.item)" :id="'btn-'+actionProps.item.docId">more_horiz</i>
              </div>
            </div>
          </template>
        </oba-table>
      </div>
    </div>
    <div class="empty-state" v-if="isEmpty">
      <oba-empty-state />
    </div>
    <div class="loading" v-if="isLoading">
      <oba-loading width="480px" />
    </div>
    <div class="pagination" v-if="!isEmpty && !isLoading">
      <oba-pagination
        :initPage="selectedPage"
        :maxPage="maxPage"
        @changePage="onPageChanged"
      />
    </div>
    <oba-info-modal 
      class="modal-info" 
      id="modalInfo"
      :data="dataInfo"
      :visible="showInfo" 
      :collection="currentCollection"
      type="medium-form"
      @close="triggerModalInfo(false)">
    </oba-info-modal>
  </div>
  <oba-modal-delete
    class="modal-delete" 
    id="modalDelete" 
    :visible="showModalDelete" 
    type="medium"
    :data="tempData"
    @submit='onDeleteUser'
    @close="triggerModalDelete(false)">
  </oba-modal-delete>
  <oba-modal-deactive 
    class="modal-deactive" 
    id="modalDeactive" 
    :visible="showModalDeactive" 
    type="medium"
    @close="triggerModalDeactive(false)">
  </oba-modal-deactive>
  <oba-modal-edit
    class="modal-edit"
    id="modalEdit"
    :visible="displayEditModal"
    :data="tempData"
    type='medium-form'
    @submit="onEditedUser"
    @close="triggertEdit(false)">
  </oba-modal-edit>
  <oba-modal-add
    class="modal-add"
    id="modalAdd"
    :visible="displayAddModal"
    type='medium-form'
    @close="triggerAddUser(false)"
    @submit="onAddMember">
  </oba-modal-add>
  <oba-floating-menu @selected="selectMenu($event, index)" :isShowMenu="isShowMenu" :menu="moreMenu" v-click-outside="() => {isShowMenu = false}" />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { transformDataTable, transformUpperCase, transformDateTime } from "@/lib/transform.js"
import { doRequest } from "@/lib/request/index.js"
import apiUrls from "@/lib/request/urls.js"

import pagination from "@/components/pagination"
import button from "@/components/button"
import tableAction from "@/components/tableAction"
import tooltip from "@/components/tooltip"
import FormBuilder from '@/components/formBuilder'
import modalDelete from "@/views/management/deleteModal"
import modalDeactive from "@/views/management/deactiveModal"
import modalEdit from "@/views/management/editModal"
import modalAdd from "@/views/management/inviteModal"
import infoModal from "@/views/management/infoModal"
import emptyState from "@/components/emptyState"
import loading from "@/components/loading"
import breadCrumb from '../../components/breadCrumb'
import queryBuilder from '@/views/queryBuilder'
import floatingMenu from '@/components/floatingMenu'
import { toRaw } from "vue"

const { mapMutations: mapMutationsUser } = createNamespacedHelpers('user')


const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers("config")

export default {
  setup() {
    async function getUserList(projectName) {
      const config = {
        url: apiUrls.getUsers(projectName),
        method: "get",
        params: {
          page: this.selectedPage
        }
      };
      const response = await doRequest(config, { needToken: true });
      return response
    }

    async function getListQueue(projectName){
      const config = {
        url: apiUrls.listQueue(projectName),
        method: 'get',
        params: {
          page: this.selectedPage,
        }
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    async function getListMember(projectName, collectionName){
      this.isLoading = true
      this.isEmpty = false
      const filters = []
      this.filters.forEach(item => {
        let value = item.value

        //not add value , when filter is empty and not empty
        if((item.operator == "isEmpty") || (item.operator == "isNotEmpty")){
          filters.push({
            field: item.field,
            operator: item.operator
          })
        }else {
          filters.push({
            field: item.field,
            operator: item.operator,
            value: value
          })
        }
      })
      
      const response = await this.getUserListPost(projectName);
      if (response.status == 200) {
        this.tableBody = transformDataTable(this.tableHeader, response.data.data, this.currentCollection)
        this.maxPage = response.data.lastPage
        this.pageSize =  response.data.pageSize
        this.totalData = response.data.totalHits
        this.SAVE_PAGE_STATE({ 
          selectedPage: this.selectedPage,
          collection: this.currentCollection, 
          filters: this.filters,
          headers: this.tableHeader
        })
        if(response.data.data.length == 0){
          this.isEmpty = true
        }
        this.isLoading = false
      } else {
        this.isLoading = false
        this.isEmpty = true
      }
    }

    return {
      getListQueue,
      getUserList,
      getListMember,
    };

  },
  components: {
    "oba-button": button,
    "oba-table": tableAction,
    "oba-pagination": pagination,
    "oba-tooltip": tooltip,
    'oba-form-builder': FormBuilder,
    'oba-modal-delete': modalDelete,
    'oba-modal-deactive': modalDeactive,
    'oba-modal-edit': modalEdit,
    'oba-modal-add': modalAdd,
    "oba-info-modal": infoModal,
    "oba-empty-state": emptyState,
    "oba-loading": loading,
    "oba-breadcrumb": breadCrumb,
    'oba-query-builder': queryBuilder,
    'oba-floating-menu': floatingMenu,
  },
  props: {
    navActive: { type: Boolean },
  },
  emits: ['sidenavTrigger'],
  data() {
    
    return {
      displayFilter: false,
      tableBody: [],
      tableHeader: [],
      isEmpty: false,
      isLoading: false,
      maxPage: 1,
      selectedPage: 1,
      filters: [],
      filtersShallow: [],
      defaultHeader: [],
      pageSize: 1,
      totalData: 0,
      renderComponent: true,
      isDisDown: false,
      moreMenu: [
        {
          label: "Edit",
          value: "edit"
        },
        {
          label: "Delete member",
          value: "delete member"
        },
      ],
      collectionNames: ["queue", "members"],
      pageHeader: '',
      headerDesc: '',
      displayAddModal: false,
      displayEditModal: false, 
      showInfo: false,
      showModalDelete: false,
      showModalDeactive: false,
      dataInfo: {},
      tempData: {},
      initialData: {},
      isShowMenu: false,
      selectedDocId: '',
    };
  },
  async beforeMount() {
    this.onSelectedCollection(this.currentCollection)
  },
  computed: {
    ...mapStateConfig([
      'projectName',
      'projectDisplay',
      'currentCollection',
    ]),
    ...mapGettersConfig([
      'getCustomCollection',
      'getCollectionEntries',
      'getDataType',
      'getDefaultFilters',
    ]),
    projectDisplayName(){
      return this.projectDisplay ? this.projectDisplay : this.projectName
    },
    triggerIcon() {
      if(this.navActive){
        return 'chevron_left'
      } else {
        return 'chevron_right'
      }
    },
    customHeader(){
      return this.tableHeader.map(header => {
        return transformUpperCase(header)
      })
    },
    customList(){
      return this.tableBody
    },
    startData() {
      return (this.selectedPage - 1) * this.pageSize + 1;
    },
    endData() {
      return this.selectedPage == this.maxPage
        ? this.totalData
        : this.selectedPage * this.pageSize
    },
    sortedFieldsType() {
      let sortedData = {}
      const entryList = this.getCollectionEntries(this.currentCollection)
      entryList.forEach(item => {
        sortedData[item.name] = this.getDataType(item.name, this.currentCollection)
      })
      return sortedData
    },
  },
  methods: {
    onSelectedCollection(selectedCollection){
      this.isEmpty = false
      // if (selectedCollection != 'queue' && selectedCollection != 'users') {
      //   selectedCollection = 'queue'
      // }
      let selected = selectedCollection
      if(selectedCollection == 'queue'){
        selected = 'queue'
      }
      let collection = this.getCustomCollection(selected)
      this.pageHeader = collection.displayText
      this.headerDesc = collection.headerDesc
      this.tableHeader = collection.table
      
      if(selected == 'queue'){
        this.loadQueue()
      } else {
        this.loadListUser()
      }
    },
    ...mapMutationsUser([
      'SAVE_PAGE_STATE',
      'SET_PAGE_LOADING_STATE',
    ]),
    sidenavTrigger() {
      this.$emit('sidenavTrigger')
    },
    triggerAddUser(value){
      this.displayAddModal = value
    },
    triggertEdit(value){
      this.displayEditModal = value
    },
    triggerModalFilter(value){
      this.displayFilter = value
    },
    onFilterApplied(filters){
      this.selectedPage = 1
      this.filters = filters
      this.addTableHeader()
      this.getListMember(this.projectName, this.currentCollection)
      this.triggerModalFilter(false)
    },
    addTableHeader(){
      let exists = this.tableHeader.map(( value ) => { return value});
      let addition = this.filters.map(( value ) => { return value.field});
      let difference = addition.filter(x => !exists.includes(x));
      for (let header of difference) {
        this.tableHeader.push(header)
      }
    },
    async removeQueryFilter(index) {
      this.selectedPage = 1
      this.filters.splice(index, 1)
      this.getListMember(this.projectName, this.currentCollection)
    },
    async getUserListPost(projectName) {
      const config = {
        url: apiUrls.getUsers(projectName),
        method: "post",
        data: {
          page: this.selectedPage,
          filters: toRaw(this.filters)
        }
      };
      const response = await doRequest(config, { needToken: true });
      return response
    },
    onPageChanged(page) {
      this.selectedPage = page;
      if(this.currentCollection == 'queue'){
        this.loadQueue()
      } else {
        this.loadListUser()
      }
    },
    onSelectedRow(selectedRow) {
      this.dataInfo = selectedRow
      this.triggerModalInfo(true)
    },
    selectMenu(menu, index){
      this.isShowMenu = false
      switch (menu.value) {
        case 'edit':
          this.triggertEdit(true)
          break;
        case 'deactive member':
          this.triggerModalDeactive(true)
          break;
        case 'delete member':
          this.triggerModalDelete(true)
          break;

        default:
          break;
      }
    },
    isComplete(item) {
      let status = item.columns.filter(value => value.fieldName == "status")
      if(status[0].value){
        if (status[0].value.toLowerCase() == "done") {
          return true
        }
      }
      return false
    },
    triggerModalInfo(value){
      this.showInfo = value
    },
    triggerModalDelete(value){
      this.showModalDelete = value
    },
    triggerModalDeactive(value){
      this.showModalDeactive = value
    },
    transformResponse(data){
      data.forEach(item => {
        if(item['created at']){
          item['created at'] = transformDateTime(item['created at'])
        }
        item['name'] = item['title']
        if (item['file']) {
          item['download'] = item['file']
        }
      })
      this.initialData = data
      return data
    },
    async loadQueue(){
      this.isLoading = true
      const response = await this.getListQueue(this.projectName)
      if(response.status == 201){
        this.selectedPage = response.data.page
        this.pageSize = response.data.pageSize
        this.totalData = response.data.totalHits
        this.maxPage = response.data.lastPage
        this.tableBody = transformDataTable(this.tableHeader, this.transformResponse(response.data.data), "queue")
        this.isLoading = false
        if(response.data.totalHits == 0){
          this.isEmpty = true
        }
      } else {
        this.isLoading = false
        this.isEmpty = true
      }
      this.SET_PAGE_LOADING_STATE(false)
    },
    async loadListUser(){
      this.isLoading = true
      const response = await this.getUserList(this.projectName)
      if(response.status == 200){
        this.selectedPage = response.data.page
        this.pageSize = response.data.pageSize
        this.totalData = response.data.totalHits
        this.maxPage = response.data.lastPage
        this.tableBody = transformDataTable(this.tableHeader, response.data.data, "members")
        this.isLoading = false
        if (response.data.totalHits == 0) {
          this.isEmpty = true
        }
      } else {
        this.isLoading = false
        this.isEmpty = true
      }
      this.SET_PAGE_LOADING_STATE(false)
    },
    onAddMember(){
      this.triggerAddUser(false)
      this.onSelectedCollection('members')
    },
    onEditedUser(){
      this.triggertEdit(false)
      this.onSelectedCollection('members')
    },
    onDeleteUser(){
      this.triggerModalDelete(false)
      this.onSelectedCollection('members')
    },
    async triggerDownloadQueue(docId) {
      let filterId = this.initialData.filter(x => x.id == docId)
      window.open(filterId[0].download, '_blank')
    },
    moveFloatingMenu(){
      let activeBtn = document.getElementById(`btn-${this.selectedDocId}`)
      let floatingMenu = document.getElementById('floatingMenu');
      let buttonRect = activeBtn.getBoundingClientRect();
      floatingMenu.style.left = `${buttonRect.left - 180}px`;
      floatingMenu.style.top = `${buttonRect.top + 30}px`;
    },
    showFloatingMenu(item){
      this.isShowMenu = !this.isShowMenu
      this.selectedDocId = item.docId
      this.tempData = item
      this.moveFloatingMenu()
    },
    handleScroll(event) {
      if (this.isShowMenu){
        this.moveFloatingMenu()
      }
    },
    handleResize(event){
      if (this.isShowMenu) {
        this.moveFloatingMenu()
      }
    },
  },
  mounted(){
    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.handleResize)
    let table = document.getElementById('table')
    if(table){
      table.removeEventListener('scroll', this.handleScroll)
    }
    let app_container = document.getElementById('app-container')
    if(app_container){
      app_container.removeEventListener('scroll', this.handleScroll)
    }
  },
  watch:{
    'currentCollection': async function (newValue, oldValue){
      if(newValue && this.collectionNames.includes(newValue)){
        this.onSelectedCollection(newValue)
        this.filters = []
        await this.getListMember(this.projectName, newValue)
      }
    },
    'isLoading': function (newValue, oldValue){
      if(!this.isLoading && !this.isEmpty){
        // get last render after table loaded vuejs
        this.$nextTick(() => {
          let table = document.getElementById('table')
          table.addEventListener('scroll', this.handleScroll)
          let app_container = document.getElementsByClassName('app-container')[0]
          app_container.addEventListener('scroll', this.handleScroll)
        })
      }
    }
  },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>
