// Base props
const BaseProps = {
    type: "",
    id: "",
    label: "",
    inputValue: "",
    dependency: null,
    validations: {},
    isDisabled: false,
    visible: true,
    class: [],
    formWidthClass: "",
    helperText: "",
    displayText: ""
  }

  const separatorProps = {
    ...BaseProps,
    extra: {
      description: ''
    }
  }
  
  const inputTextProps = {
    ...BaseProps,
    extra: {
      placeholder: "",
      inputType: "text",
      numberType: ""
    }
  }
  
  const inputDateProps = {
    ...BaseProps,
    extra: {
      format : 'dd mmm yyyy'
    }
  }

  const inputDateTimeProps = {
    ...BaseProps,
    extra: {
      format : ''
    }
  }
  
  const inputAutocompleteProps = {
    ...BaseProps,
    extra: {
      options: {}
    }
  }
  
  const inputCheckboxProps = {
    ...BaseProps,
    extra: {
      options: []
    }
  }
  
  const inputDropdownProps = {
    ...BaseProps,
    extra: {
      options: []
    }
  }
  
  const inputRadioProps = {
    ...BaseProps,
    extra: {
      options: []
    }
  }
  
  const inputTextareaProps = {
    ...BaseProps,
    extra: {
      placeholder: "", 
    }
  }

  const inputFileProps = {
    ...BaseProps,
    extra: {
      isMultiple: true,
      fileNames: [],
      accept: "image"
    }
  }
  
  const initialProps = {
    inputTextProps,
    inputDateProps,
    inputAutocompleteProps,
    inputCheckboxProps,
    inputDropdownProps,
    inputRadioProps,
    inputTextareaProps,
    inputFileProps,
    separatorProps,
    inputDateTimeProps
  }

  export default initialProps