<template>
  <span class="label" :style="{'background-color': color}"> {{label}} </span>  
</template>

<script>

export default {
  name: 'oba-label',
  props:{
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required:false,
      default : "#999999"
    }
  }

}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>