<template>
  <div class="custom-layout">
    <div class="canvas" id="canvas-layout">
      <div :id="`${stageID}`" class="page" v-for="(stage, stageID) in stages" :key="stageID">
        <v-stage 
          :ref="`${stageID}`"
          :config="stage.config"
          @mousedown="handleStageMouseDown"
          @touchstart="handleStageMouseDown"
        >
          <v-layer>
            <v-group
              v-for="(group, groupID) in stage.groups"
              :key="`${stageID}-${groupID}`"
              :ref="`${groupID}`"
              :config="group.config"
              @dragmove="selectedShape.className == 'Image' ? '' : groupDrag($event)"
            >
              <v-rect 
                :config="group.rect"
                :ref="`${groupID}-rect`"
                @transform="rectTrans"
                @transformend="rectTransEnd"
              />
              <v-image 
                :config="group.backImage"
              />
              <v-group 
                :config="group.child"
              >
                <v-image 
                  :config="group.image"
                />
              </v-group>
              <v-text
                :config="group.texts"
              />
              <v-image
                :config="group.emptyImageState"
              />
            </v-group>
            <v-rect 
              v-for="(rect, rectID) in stage.rects"
              :key="`${stageID}-${rectID}`"
              :config="rect"
            />
            <v-text 
              v-for="(text, textID) in stage.texts"
              :key="`${stageID}-${textID}`"
              :config="text"
              @transform="handleTransform"
              @transformend="handleTransformEnd"
              @dblclick="handleDblClick"
              @dragmove="textDrag"
            />
            <v-transformer :ref="`${stageID}-transformer`" />
          </v-layer>
        </v-stage>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import clonedeep from 'lodash.clonedeep'
import { showNotification } from '@/lib/utils'
import { config } from '@//lib/config.js'
import * as imageUrl from '@/assets/icon/image.png'

export default {
  name: 'oba-canvas',
  components: {
    
  },
  props: {
    loadedData: {
      type: Object,
      required: false,
      default: {},
    },
    rowData: {
      type: Array,
      required: false,
      default: [],
    },
    selectedCollection: {
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      widthPPT: 33.87,
      heightPPT: 19.05,
      defRectWidth: 280,
      defRectHeight:520,
      incrementStageID: 1,
      incrementID: {
        'text': 0,
        'group': 0,
        'rect': 0
      },
      selectedStageID: 'stage1',
      selectedShape: null,
      textHasChange: [],
      stages: {
        'stage1': {
          config: {
            id: 'stage1',
            width: 0,
            height: 0
          },
          groups: {
            // 'group1': {
              // config: {
              //   x: 50,
              //   y: 50,
              //   width: 250,
              //   height: 500,
              //   draggable: true,
              // },
              // child: {
              //   // x: 50,
              //   // y: 50,
              //   // draggable: true,
              //   clipFunc: function (ctx) {
              //     // Start only one path
              //     ctx.rect(0,0,250,500)
              //   }
              // },
              // rect: {
              //   // x: 50,
              //   // y: 50,
              //   width: 250,
              //   height: 500,
              //   fill: '#dedede',
              //   ratioType: 'portrait'
              // },
              // backImage: {
              //   // x:50,
              //   // y:50,
              //   width: 100,
              //   height: 100,
              //   image: null,
              //   draggable: true,
              //   visible: false,
              //   // scaleX: 0.5,
              //   // scaleY: 0.5
              // },
              // image: {
              //   // x:50,
              //   // y:50,
              //   width: 100,
              //   height: 100,
              //   image: null,
              //   draggable: true,
              //   // scaleX: 0.5,
              //   // scaleY: 0.5
              // }
            // },
          },
          texts: {
            // 'text1': {
            //   id: 'text1',
            //   text: 'Some text here',
            //   x: 380,
            //   y: 200,
            //   fontSize: 20,
            //   draggable: true,
            //   width: 200,
            // }
          },
          rects: {
            // 'rect1': {
            //   id: 'rect1',
            //   x: 50,
            //   y: 50,
            //   width: 250,
            //   height: 500,
            //   fill: '#dedede',
            // }
          },
          images: {
            // 'image1': {
            //   id: 'image1',
            //   x:50,
            //   y:50,
            //   image: null,
            //   draggable: true,
            //   // scaleX: 0.5,
            //   // scaleY: 0.5
            // }
          },
        },
      },
      configText: {
        text: 'Some text here',
        x: 380,
        y: 200,
        fontSize: 20,
        draggable: true,
      },
      configGroup: {
        config: {
          x: 50,
          y: 50,
          width: 250,
          height: 500,
          draggable: true,
        },
        child: {
          clipFunc: function (ctx) {
            // Start only one path
            ctx.rect(0,0,250,500)
          }
        },
        rect: {
          width: 250,
          height: 500,
          fill: '#dedede',
          ratioType: 'portrait'
        },
        backImage: {
          width: 100,
          height: 100,
          image: null,
          draggable: true,
          visible: false,
        },
        image: {
          width: 100,
          height: 100,
          image: null,
          draggable: true,
        },
        texts: {
          fontSize: 14,
          fill: "#878787",
          align: "center",
          draggable: false,
          listening: false,
        },
        emptyImageState: {
          width: 100,
          height: 100,
          image: null,
          draggable: false,
          listening: false,
        }
      },
      configRect: {
        x: 50,
        y: 50,
        width: 250,
        height: 500,
        fill: '#dedede',
        draggable: true
      },
      configImg: {
        x:50,
        y:50,
        image: null,
        draggable: true,
      },
      ratios: {
        landscape: {
          width: 500,
          height: 250
        },
        portrait: {
          width: 250,
          height: 500
        },
        square: {
          width: 250,
          height: 250
        },
      },
      initialText: 1,
      listSourceImage: [],
      cm: 37.7952755906,
    }
  },
  watch:{
    'selectedShape': function (newValue, oldValue){
      if(newValue){
        this.$emit('updateSelectedShape', newValue)
      }
    }
  },
  mounted() {
    this.stages['stage1'].config.width = Math.round(this.widthPPT * this.cm)
    this.stages['stage1'].config.height = Math.round(this.heightPPT * this.cm)
    if(Object.keys(this.loadedData).length != 0){
      this.convConfStages(this.loadedData)
    }
  },
  methods: {
    /**
     * Convert data from bast to canvas stage
     * @param {*} data 
     */
    async convConfStages(data) {
      const bast = data
      const exclude = ['created', 'layout_name']

      for (let [key, shape] of Object.entries(bast[this.selectedCollection])) {
        if(!exclude.includes(key.toLowerCase())){
          const KeyStage = 'stage' + (parseInt(shape.slide_index) + 1)
          if (!this.stages[KeyStage]) {
            this.stages[KeyStage] = {
              config: {
                id: KeyStage,
                width: Math.round(this.widthPPT * this.cm),
                height: Math.round(this.heightPPT * this.cm)
              },
              groups: {},
              rects: {},
              images: {},
              texts: {},
            }
            this.incrementStageID = Math.max(this.incrementStageID, parseInt(shape.slide_index) + 1)
          }
          const stage = this.stages[KeyStage]
          

          let shapeName;
          let newShape;
          let newKey;
        
          if (shape.type.toLowerCase() == 'image') {
            this.incrementID['group']++
            newKey = 'group' + this.incrementID['group']
            shapeName = 'groups'
            let newConf = clonedeep(this.configGroup);
            newConf.config['id'] = newKey
            newConf.child['id'] = newKey + '-child'
            newConf.rect['id'] = newKey + '-rect'
            newConf.rect['ratioType'] = shape.ratioType

            if (shape.url) {
              // config rect
              newConf.rect.width = (shape.position[2] * this.cm) / shape.scaleX
              newConf.rect.height = (shape.position[3] * this.cm) / shape.scaleY
              newConf.rect.scaleX = shape.scaleX
              newConf.rect.scaleY = shape.scaleY

              //config group
              newConf.config.x = shape.position[0] * this.cm
              newConf.config.y = shape.position[1] * this.cm
              const imgUrl = shape.url.replace(config.bastImgPrefix, config.imgPrefix)

              const image = new window.Image();
              image.src = imgUrl
              await image.decode();
              const shapeWidth = shape.position[2] * this.cm
              const shapeHeight = shape.position[3] * this.cm
              const widthRatio = shapeWidth / image.width
              const heightRatio = shapeHeight / image.height
              const scaleX = ((widthRatio + Number.EPSILON) * 100000) / 100000
              const scaleY = ((heightRatio + Number.EPSILON) * 100000) / 100000

              let scaleMul = 1
              if (shapeHeight < shapeWidth) {
                scaleMul = scaleX
              } else {
                scaleMul = scaleY
              }      

              newConf.image = {
                'id': `${newKey}-image`,
                'image': image,
                'width': shapeWidth / shape.scaleX,
                'height': shapeHeight / shape.scaleY,
                'scaleX': shape.scaleX,
                'scaleY': shape.scaleY,
              }
            
              const backWidth = (shapeWidth / shape.scaleX) * scaleMul * shape.scaleX
              const backHeight = (shapeHeight / shape.scaleY) * scaleMul * shape.scaleY

              const boundsRect = {
                'id': `${newKey}-backImage`,
                'image': image,
                'opacity': 0.2, 
                'stroke': 'black', 
                'strokeWidth': 1, 
                'draggable': false, 
                'dash': [2, 2], 
                'listening': false,
                'width': backWidth,
                'height': backHeight,
                'scaleX': 1,
                'scaleY': 1,
                'visible': false,
              }
              newConf.backImage = boundsRect
              // save image to list
              const itemImage = {
                id: newKey
              }
              itemImage[newKey] = {
                url: shape.url
              }
              this.listSourceImage.push(itemImage)
              // end of save image to list

            } else {
              newConf.config.x = shape.position[0] * this.cm
              newConf.config.y = shape.position[1] * this.cm
              newConf.rect.width = shape.position[2] * this.cm
              newConf.rect.height = shape.position[3] * this.cm
              newConf.rect.scaleX = shape.scaleX
              newConf.rect.scaleY = shape.scaleY
              if(shape.scaleX){
                newConf.rect.width = (shape.position[2] * this.cm) / shape.scaleX
                newConf.rect.height = (shape.position[3] * this.cm) / shape.scaleY
              }
            }
            if (!key.includes('_')) {
              newConf.rect['fieldName'] = key
              newConf.rect['fromField'] = true
            } else {
              newConf.rect['fromField'] = false
            }

            // check if fromfield, add fieldname to group
            if(shape.fromField){
              //set emptyImageState attribute
              newConf.emptyImageState.image = this.getEmptyStateImage()
              newConf.emptyImageState.y = (newConf.rect.height / 2) - ((newConf.emptyImageState.height)/2)
              newConf.emptyImageState.x = (newConf.rect.width / 2) - ((newConf.emptyImageState.width)/2)
              newConf.emptyImageState.width = newConf.emptyImageState.width * shape.scaleX
              newConf.emptyImageState.height = newConf.emptyImageState.height * shape.scaleY
              // set text attribute
              newConf.texts['text'] = `Src: ${key}`
              newConf.texts.y = (newConf.rect.height / 2) + (newConf.emptyImageState.height /2)
              newConf.texts.width = newConf.rect.width
              newConf.texts.fontSize = newConf.texts.fontSize * shape.scaleX
            }
            newShape = { ...newConf }
          } else {
            this.incrementID['text']++
            newKey = 'text' + this.incrementID['text']
            shapeName = 'texts'
            const newConf = {id:newKey, ...this.configText}
            newConf['text'] = shape.text || key
            newConf['fontSize'] = shape.font_size ? Math.floor(shape.font_size / 0.75) : 20
            newConf['fontStyle'] = shape.text_style ? shape.text_style : ''
            newConf['align'] = shape.text_align ? shape.text_align : ''
            newConf.x = shape.position[0] * this.cm
            newConf.y = shape.position[1] * this.cm
            newConf.width = shape.position[2] * this.cm
            if (!key.includes('_')) {
              newConf['fieldName'] = key
              newConf['fromField'] = true
            } else {
              newConf['fromField'] = false
            }
            newShape = { ...newConf }
          }
          stage[shapeName][newKey] = newShape
          await nextTick()
          const rectRef = this.$refs[`${newKey}-rect`]
          if(rectRef){
            const rect = rectRef[0].getNode()
            this.selectedShape = rect
            if(shape.ratioType){
              this.transRect(shape.ratioType)
            }
          }
        }
      }
    },
    /**
     * Convert data from canvas stage to bast
     * @param {*} listImage 
     * @param {*} projectName 
     * @param {*} detailData 
     */
    convConfBast(listImage, projectName, detailData){
      const formatedData = {
        // "version": 3,
        "project": projectName,
        "layout_name": detailData.field['layout name'],
        [this.selectedCollection]: {}
      }
      if(this.rowData.length != 0){
        let templateIdData = this.rowData.find(item => item.fieldName == 'templateid')
        if(templateIdData){
          formatedData['templateid'] = templateIdData.value
        }
      }

      let page = 0
      for (let [key, stage] of Object.entries(this.stages)) {
        const nodeStage = this.$refs[key][0].getStage()
        let incrementID = 0
        const shapes = ['Text', 'Image']
        for (const shapeName of shapes) {
          let shapeIDs = shapeName == 'Text' ? Object.keys(stage.texts) : 
                          Object.keys(stage.groups).map((data) => `${data}-rect`)
          for (let shapeID of shapeIDs) {
            incrementID++
            let selectedNode = nodeStage.findOne('#' + shapeID);
            const groupID = shapeID.split('-')[0]
            if (!selectedNode) {
              shapeID = groupID + '-rect'
              selectedNode = nodeStage.findOne('#' + shapeID);
            }
            // if (selectedNode) {
              const attrs = selectedNode.attrs

              let keyShape = attrs.fieldName ? attrs.fieldName : `_free${shapeName}${incrementID}`
              attrs.x = selectedNode.absolutePosition().x
              attrs.y = selectedNode.absolutePosition().y
              attrs.width = attrs.width ? attrs.width : selectedNode.width()
              attrs.height = attrs.height ? attrs.height : selectedNode.height()
              let shapeConf = {
                  "type": shapeName.toLowerCase(),
                  "position": [
                      Math.round((attrs.x / this.cm)* 1e3)/1e3, // x
                      Math.round((attrs.y / this.cm)* 1e3)/1e3, // y
                      Math.round((attrs.width / this.cm)* 1e3)/1e3, // width
                      Math.round((attrs.height / this.cm)* 1e3)/1e3 // height
                  ],
                  "slide_index": page
              }
              if(attrs.scaleX){
                shapeConf.position = [
                  Math.round((attrs.x / this.cm)* 1e3)/1e3, // x
                  Math.round((attrs.y / this.cm)* 1e3)/1e3, // y
                  (Math.round((attrs.width / this.cm)* 1e3)/1e3) * attrs.scaleX, // width
                  (Math.round((attrs.height / this.cm)* 1e3)/1e3) * attrs.scaleY // height
                ]
              }

              if (shapeName == 'Text') {
                shapeConf['text'] = attrs.text
                shapeConf['font_size'] = Math.floor(attrs.fontSize * 0.75)
                shapeConf['text_style'] = attrs.fontStyle ? attrs.fontStyle : ''
                shapeConf['text_align'] = attrs.align ? attrs.align : ''
                shapeConf['fromField'] = attrs.fromField ? attrs.fromField : false
              } else {
                if(!attrs.fromField){
                  if (listImage[groupID]){
                    shapeConf['url'] = `${config.bastImgPrefix}${listImage[groupID]}`
                  } else if(this.listSourceImage.length != 0){
                    const imageItem = this.listSourceImage.find(item => item.id == groupID)
                    if(imageItem){
                      shapeConf['url'] = imageItem[groupID].url
                    }
                  }
                }
                shapeConf['scaleX'] = attrs.scaleX ? attrs.scaleX : 1
                shapeConf['scaleY'] = attrs.scaleY ? attrs.scaleY : 1
                shapeConf['ratioType'] = attrs.ratioType ? attrs.ratioType : ''
                shapeConf['fromField'] = attrs.fromField ? attrs.fromField : false
              }
              
              formatedData[this.selectedCollection][keyShape] = shapeConf
            // }
          }
        }
        page++
      }

      return formatedData
    },
    /**
     * Handle drag text on canvas
     * @param {*} e 
     */
    textDrag(e) {
      const text = e.target
      const absPosText = text.absolutePosition()
      const stage = text.getStage()

      var posText = {
        left: absPosText.x, 
        top: absPosText.y, 
        width : text.width() * text.scaleX(), 
        height : text.height() * text.scaleY(),
        right: absPosText.x + (text.width() * text.scaleX()), 
        bottom: absPosText.y + (text.height() * text.scaleY())
      };
      const stageWidth = stage.width()
      const stageHeight = stage.height()

      let newX;
      newX = Math.max(posText.left, 0)
      newX = Math.min(newX, stageWidth - posText.width)

      let newY;
      newY = Math.max(posText.top, 0)
      newY = Math.min(newY, stageHeight - posText.height)

      text.absolutePosition({
        x: newX,
        y: newY
      })
    },
    /**
     * Handle drag group on canvas
     * @param {*} e 
     */
    groupDrag(e) {
      const group = e.target
      const absPosRect = group.absolutePosition()
      const stage = group.getStage()

      var posGroup = {
        left: absPosRect.x, 
        top: absPosRect.y, 
        width : group.width() * group.scaleX(), 
        height : group.height() * group.scaleY(),
        right: absPosRect.x + (group.width() * group.scaleX()), 
        bottom: absPosRect.y + (group.height() * group.scaleY())
      };
      const stageWidth = stage.width()
      const stageHeight = stage.height()

      let newX;
      newX = Math.max(posGroup.left, 0)
      newX = Math.min(newX, stageWidth - posGroup.width)

      let newY;
      newY = Math.max(posGroup.top, 0)
      newY = Math.min(newY, stageHeight - posGroup.height)

      group.absolutePosition({
        x: newX,
        y: newY
      })
    },
    handleTransformEnd(e) {
      const scaleX = Math.round((e.target.scaleX() + Number.EPSILON) * 1000) / 1000
      const scaleY = Math.round((e.target.scaleY() + Number.EPSILON) * 1000) / 1000
      if(scaleY > 1.000 || scaleY < 0.999) {
        e.target.fontSize(e.target.fontSize() * scaleX);
        if(this.textHasChange.includes(e.target.id())){
          e.target.width(e.target.width() * scaleX)
        }
      } 
      this.initialText = Math.round(this.initialText + (scaleX - 1))
      e.target.scaleX(1)
      e.target.scaleY(1)
    },
    handleTransform(e) {
      const scaleX = Math.round((e.target.scaleX() + Number.EPSILON) * 100000) / 100000
      const scaleY = Math.round((e.target.scaleY() + Number.EPSILON) * 100000) / 100000
      if(0.99999 < scaleY && scaleY <= 1.00000) {
        if(!this.textHasChange.includes(e.target.id())){
          this.textHasChange.push(e.target.id())
        }
        e.target.setAttrs({
          width: Math.max(e.target.width() * scaleX, 80),
        });
      } 
    },
    rectTransEnd(e) {
      const rect = e.target
      const image = rect.parent.children[2].children[0]
      const group = rect.parent.children[2]
      if (rect.attrs.ratioType == 'free' && !image.image()) {
        rect.width(rect.width() * rect.scaleX())
        rect.height(rect.height() * rect.scaleY())
        rect.scaleX(1)
        rect.scaleY(1)

        group.clipFunc((ctx) => {
          // Start only one path
          ctx.rect(0, 0, rect.width(), rect.height())
        })
        group.scaleX(1)
        group.scaleY(1)
      }
      const parentGroup = rect.parent
      const transform = rect.getTransform().copy();
      const attrs = transform.decompose();
      const backImage = rect.parent.children[1]
      parentGroup.absolutePosition(rect.absolutePosition())
      rect.x(0)
      rect.y(0)
      group.x(0)
      group.y(0)
      backImage.width(group.getClientRect().width)
      backImage.height(group.getClientRect().height)
      backImage.absolutePosition(image.absolutePosition())
      parentGroup.width(rect.width() * attrs.scaleX)
      parentGroup.height(rect.height() * attrs.scaleY)
    },
    rectTrans(e) {
      const rect = e.target
      const transform = rect.getTransform().copy();
      const attrs = transform.decompose();
      const childGroup = rect.parent.children[2]
      const emptyImageGroup = rect.parent.children[4]
      const rectWidth = rect.attrs.width * rect.attrs.scaleX
      const rectHeight = rect.attrs.height * rect.attrs.scaleY
      const emptyImageWidth = emptyImageGroup.attrs.width * rect.attrs.scaleX
      const emptyImageHeight = emptyImageGroup.attrs.height * rect.attrs.scaleY
      const fieldNameGroup = rect.parent.children[3]
      if(childGroup){
        let imageAttrs = JSON.parse(JSON.stringify(attrs))
        childGroup.setAttrs({id: childGroup.id(), ...imageAttrs})
      }
      if(emptyImageGroup){
        let imageFieldAttrs = JSON.parse(JSON.stringify(attrs))
        imageFieldAttrs.y = (rectHeight / 2) - (emptyImageHeight / 2)
        imageFieldAttrs.x = (rectWidth / 2) - (emptyImageWidth / 2)
        emptyImageGroup.setAttrs({id: emptyImageGroup.id(), ...imageFieldAttrs})
      }
      if(fieldNameGroup){
        let fieldNameAttrs = JSON.parse(JSON.stringify(attrs))
        fieldNameAttrs.y = (rectHeight / 2) + (emptyImageHeight / 2)
        fieldNameGroup.setAttrs({id: fieldNameGroup.id(), ...fieldNameAttrs})
      }
    },
    async addShape(value, fromField=false){
      if (!this.selectedStageID) {
        showNotification('error', `Please select the stages first`)
        return
      }

      let stage = this.stages[this.selectedStageID]
      const shapeName = value.toLowerCase() + 's'
      this.incrementID[value.toLowerCase()]++
      let newKey = value.toLowerCase() + this.incrementID[value.toLowerCase()]
      let newShape;
      
      if (value == 'Text'){
        newShape = {id:newKey, fromField:fromField, ...this.configText}
      } else if (value == 'Group'){
        const newConf = clonedeep(this.configGroup);
        newConf.config['id'] = newKey
        newConf.child['id'] = newKey + '-child'
        newConf.rect['id'] = newKey + '-rect'
        newConf.rect['fromField'] = fromField
        // this.configGroup.image['id'] = newKey + '-image'
        newShape = { ...newConf }
      } else {    
        newShape = {id:newKey, ...this.configRect}
      }
      
      stage[shapeName][newKey] = newShape
    },
    async removePage(){
      if (!this.selectedStageID) {
        return
      }
      const stagesLength = Object.keys(this.stages).length
      if(stagesLength == 1){
        showNotification('error', `Cannot delete page, required at least 1 page`)
      } else {
        delete this.stages[this.selectedStageID]
      }
    },
    remove(){
      if (!this.selectedShape) {
        return
      }


      let selectedNode = this.selectedShape
      let selectedShapeName = selectedNode.className.toLowerCase()

      if (selectedNode.parent.nodeType == 'Group') {
        selectedNode = selectedNode.parent
        selectedShapeName = 'group'
        

        // delete image from list
        if(this.listSourceImage.length != 0){
          this.listSourceImage.forEach((item,index) => {
            for (var key of Object.keys(item)){
              if(selectedNode.id() == key){
                this.listSourceImage.splice(index,1)
              }
            }
          })
        }
      }

      const stage = selectedNode.getStage()
      const transformerNode = stage.findOne('Transformer')
      transformerNode.nodes([]);

      const selectedShapeID = selectedNode.id()
      delete this.stages[stage.id()][selectedShapeName + 's'][selectedShapeID]
      this.selectedShape = null
    },
    async duplicatePage() {
      if (!this.selectedStageID) {
        return
      }
      
      let stage = this.stages[this.selectedStageID]
      let nodeStage = this.$refs[this.selectedStageID][0].getStage();
      this.incrementStageID++
      const newKey = 'stage' + this.incrementStageID
      const newStage = {
        config: {
          id: newKey,
          width: stage.config.width,
          height: stage.config.height,
        },
        groups: {},
        rects: {},
        images: {},
        texts: {},
      }

      for (const [key, value] of Object.entries(stage)) {
        if(key == 'config') {
          continue;
        }
        for(const shapeID of Object.keys(value)){
          let selectedNode = nodeStage.findOne('#' + shapeID);
          if (selectedNode) {
            this.selectedShape = selectedNode
            await this.duplicate(newStage, 0, 0)
          }
        }
      }

      this.stages[newKey] = { ... newStage }
      
      await nextTick()

      const el = this.$el.getElementsByClassName(`canvas`)[0];
      if (el) {
        let position = Object.keys(this.stages).length - 1
        const to = position * Math.round(this.widthPPT * this.cm) + (50 * (position - 1))
        el.scrollTo({
          left: to,
          behavior: 'smooth'
        })
      }
    },
    async duplicate(newStage=null, newX=20, newY=20) {
      if (!this.selectedShape) {
        return
      }

      let selectedNode = this.selectedShape
      if (selectedNode.parent.nodeType == 'Group') {
        selectedNode = selectedNode.parent
      }

      let stage;
      if (newStage){
        stage = newStage
      } else {
        stage = this.stages[this.selectedStageID]
      }

      const shapeName = selectedNode.id().slice(0, -1)

      this.incrementID[shapeName]++
      let newKey = shapeName + this.incrementID[shapeName]
      const clone = selectedNode.clone({
        id: newKey,
        x: parseInt(selectedNode.x() + newX),
        y: parseInt(selectedNode.y() + newY),
      })

      if (shapeName != 'group') {
        stage[shapeName + 's'][newKey] = { ...clone.attrs }
      } else {
        clone.children[0].id(newKey + '-rect')
        clone.children[1].id(newKey + '-backImage')
        clone.children[2].id(newKey + '-child')
        clone.children[2].children[0].id(newKey + '-image')
        stage[shapeName + 's'][newKey] = {
          config: { ...clone.attrs },
          rect: { ...clone.children[0].attrs},
          backImage: { ...clone.children[1].attrs },
          child: { ...clone.children[2].attrs },
          image: { ...clone.children[2].children[0].attrs},
        }
      }

      await nextTick()

      if (!newStage){
        const stageNode = selectedNode.getStage()
        const transformerNode = stageNode.findOne('Transformer')
        if (selectedNode.nodeType == 'Group') {
          newKey = newKey + '-rect'
        }
        let nodeTrans = stageNode.findOne('#' + newKey);
        transformerNode.nodes([nodeTrans])
      }
    },
    processFile(file){
      return function(e) { 
        // Use the .split I've included when calling this from uploadFile()
        var theBytes = e.target.result; //.split('base64,')[1]; 
        file.innerText = theBytes;
      }
    },
    async uploadImage(imageSource){
      if (!this.selectedShape) {
        return
      }

      if (this.selectedShape.className != 'Rect') {
        return
      }

      const stage = this.selectedShape.getStage()
      const transformerNode = stage.findOne('Transformer')
      const stageID = stage.id()
      const groupID = this.selectedShape.id().split('-')[0]
      
      // add image to list --> start
      let data = {}
      const shapeID = this.selectedShape.attrs.id
      const formatedShapeId = shapeID.substring(0,shapeID.indexOf("-"))
      
      data['id'] = formatedShapeId
      data[formatedShapeId] = imageSource

      if(this.listSourceImage.length !=0){
        // check image exist
        let savedImage = this.listSourceImage.find(item => item.id == formatedShapeId)
        if(savedImage){
          this.listSourceImage.forEach(item =>{
            if(item.id == formatedShapeId){
              item[formatedShapeId] = imageSource
            } 
          })
        } else {
          this.listSourceImage.push(data)
        }
      } else {
        this.listSourceImage.push(data)
      }
      // --> end

      const image = new window.Image();
      if (this.selectedShape.attrs.ratioType == 'landscape') {
        image.src = URL.createObjectURL(imageSource);
      } else if (this.selectedShape.attrs.ratioType == 'portrait') {
        image.src = URL.createObjectURL(imageSource);
      } else {
        image.src = URL.createObjectURL(imageSource);
      }
      await image.decode();
      
      const shapeWidth = this.selectedShape.width()
      const shapeHeight = this.selectedShape.height()
      const widthRatio = shapeWidth / image.width
      const heightRatio = shapeHeight / image.height
      const scaleX = Math.round((widthRatio + Number.EPSILON) * 100000) / 100000
      const scaleY = Math.round((heightRatio + Number.EPSILON) * 100000) / 100000
      
      let scaleMul = 1
      if (shapeHeight < shapeWidth) {
        scaleMul = scaleX
      } else {
        scaleMul = scaleY
      }

      let group = this.stages[stageID].groups[groupID]
      
      group.image = {
        'id': `${groupID}-image`,
        'image': image,
        'width': shapeWidth,
        'height': shapeHeight,
        'scaleX': 1,
        'scaleY': 1,
      }

      const groupNode = this.selectedShape.parent.children[2]
      const backWidth = shapeWidth * scaleMul * groupNode.scaleX()
      const backHeight = shapeHeight * scaleMul * groupNode.scaleY()
      const boundsRect = {
        'id': `${groupID}-backImage`,
        'image': image,
        'opacity': 0.2, 
        'stroke': 'black', 
        'strokeWidth': 1, 
        'draggable': false, 
        'dash': [2, 2], 
        'listening': false,
        'width': backWidth,
        'height': backHeight,
        'scaleX': 1,
        'scaleY': 1,
        'visible': false,
      }
      group.backImage = boundsRect

      await nextTick()
      
      transformerNode.nodes([])
    },
    transRect(value){
      if (!this.selectedShape) {
        return
      }
      
      if (this.selectedShape.className != 'Rect') {
        return
      }

      const rect = this.selectedShape
      rect.attrs.ratioType = value
      const stage = rect.getStage()
      const transformerNode = stage.findOne('Transformer')

      if (value != 'free') {
        rect.width(this.ratios[value].width)
        rect.height(this.ratios[value].height)
        const group = rect.parent.children[2]
        group.clipFunc((ctx) => {
          // Start only one path
          ctx.rect(0, 0, rect.width(), rect.height())
        })
        const parentGroup = rect.parent
        const transform = rect.getTransform().copy();
        const attrs = transform.decompose();
        parentGroup.absolutePosition(rect.absolutePosition())
        rect.x(0)
        rect.y(0)
        group.x(0)
        group.y(0)
        parentGroup.width(rect.width() * attrs.scaleX)
        parentGroup.height(rect.height() * attrs.scaleY)
        // start of settings empty image for image from field
        const emptyImageGroup = rect.parent.children[4]
        const scaleX = rect.attrs.scaleX ? rect.attrs.scaleX : 1
        const scaleY = rect.attrs.scaleY ? rect.attrs.scaleY : 1
        const rectWidth = rect.attrs.width * scaleX
        const rectHeight = rect.attrs.height * scaleY
        const emptyImageWidth = emptyImageGroup.attrs.width * scaleX
        const emptyImageHeight = emptyImageGroup.attrs.height * scaleY
        let emptyImageAttrs = {...attrs}
        emptyImageAttrs.y = (rectHeight / 2) - (emptyImageHeight / 2)
        emptyImageAttrs.x = (rectWidth / 2) - (emptyImageWidth / 2)
        emptyImageGroup.setAttrs({id: emptyImageGroup.id(), ...emptyImageAttrs})
        // end of settings empty image

        // start of settings text from field for image from field
        const fieldNameGroup = rect.parent.children[3]
        let fieldNameAttrs = {...attrs}
        fieldNameAttrs.width = rect.attrs.width
        fieldNameAttrs.y = (rectHeight / 2) + ((emptyImageHeight / 2))
        fieldNameGroup.setAttrs({id: fieldNameGroup.id(), ...fieldNameAttrs})
        // end of settings text from field

        // start of settings image for uploaded image
        const imageGroup = rect.parent.children[2].children[0]
        let imageGroupAttrs = imageGroup.getTransform().decompose()
        imageGroupAttrs.width = parentGroup.width() / imageGroupAttrs.scaleX
        imageGroupAttrs.height = parentGroup.height() / imageGroupAttrs.scaleY
        imageGroup.setAttrs({id: imageGroup.id(), ...imageGroupAttrs})
        // end of settings image for uploaded image
      } else {
        transformerNode.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right', 
                                        'middle-left', 'middle-right', 'top-center', 'bottom-center'])
      }

      transformerNode.forceUpdate();
    },
    bold(e) {
      if (!this.selectedShape) {
        return
      }

      if (this.selectedShape.className != 'Text') {
        return
      }

      if (this.selectedShape.fontStyle() == 'bold') {
        this.selectedShape.fontStyle('normal')
      } else {
        this.selectedShape.fontStyle('bold')
      }
    },
    fontsize(operator) {
      if (!this.selectedShape) {
        return
      }

      if (this.selectedShape.className != 'Text') {
        return
      }

      let size = eval(`${this.selectedShape.fontSize()} ${operator} 1`)
      size = Math.max(10, size)
      this.selectedShape.fontSize(size)
    },
    align(value) {
      if (!this.selectedShape) {
        return
      }

      if (this.selectedShape.className != 'Text') {
        return
      }

      this.selectedShape.align(value)
    },
    async addPage() {
      this.incrementStageID++
      const newKey = 'stage' + this.incrementStageID
      this.stages[newKey] = {
        config: {
          id: newKey,
          width: Math.round(this.widthPPT * this.cm),
          height: Math.round(this.heightPPT * this.cm)
        },
        groups: {},
        rects: {},
        images: {},
        texts: {},
      }

      await nextTick()

      const el = this.$el.getElementsByClassName(`canvas`)[0];
      if (el) {
        let position = Object.keys(this.stages).length - 1
        const to = position * Math.round(this.widthPPT * this.cm) + (50 * (position - 1))
        el.scrollTo({
          left: to,
          behavior: 'smooth'
        })
      }
      
    },
    handleStageMouseDown(e) {
      // filter native events
      if (!e.evt) {
        return;
      }
      const stage = e.target.getStage()
      this.selectedStageID = stage.id()
      let selectedShapeID = ''
      for (const otherStageID of Object.keys(this.stages)) {
        if (otherStageID != stage.id()) {
          let otherStage = this.$refs[otherStageID][0].getStage();
          this.updateTransformer(otherStage, '');
        }
      }

      // clicked on stage - clear selection
      if (e.target === stage) {
        this.$emit('updateSelectedShape', null)
        this.updateTransformer(stage, selectedShapeID);
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }
      
      selectedShapeID = e.target.id();
      if(!selectedShapeID){
        selectedShapeID = e.target.getParent().parent.children[0].id() 
      }
      this.updateTransformer(stage, selectedShapeID);
    },
    updateTransformer(stage, selectedShapeID) {
      // here we need to manually attach or detach Transformer node
      const transformerNode = stage.findOne('Transformer')      
      let selectedNode = selectedShapeID ? stage.findOne('#' + selectedShapeID) : null;

      if(this.selectedShape && this.selectedShape.id() != selectedShapeID && stage.id() == this.selectedStageID) {
          if (this.selectedShape.className == 'Image') {
            this.selectedShape.draggable(false)
            const backImage = this.selectedShape.parent.parent.children[1]
            backImage.visible(false)
          }
      }
      
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      } else if (selectedNode){
        // attach to another node
        this.selectedShape = selectedNode

        if(!selectedNode.draggable() && 
          selectedNode.parent.nodeType == 'Group' &&
          selectedNode.className != 'Rect') {
            selectedNode = selectedNode.parent.parent.children[0]
            this.selectedShape = selectedNode
            transformerNode.nodes([selectedNode]);
        } else {
          transformerNode.nodes([selectedNode]);
        }

        transformerNode.borderStroke('rgb(0, 161, 255)')
        transformerNode.anchorStroke('rgb(0, 161, 255)')
        transformerNode.padding(8)
        transformerNode.flipEnabled(false)

        if (selectedNode.className == 'Text') {
          transformerNode.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right', 'middle-left', 'middle-right'])
          transformerNode.keepRatio(true)
          transformerNode.boundBoxFunc((oldBox, newBox) => {
            const box = this.getClientRect(newBox);
            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stage.width() ||
              box.y + box.height > stage.height();

              // if new bounding box is out of visible viewport, let's just skip transforming
              // this logic can be improved by still allow some transforming if we have small available space
              if (isOut) {
                oldBox.width = Math.max(50, newBox.width);
                return oldBox;
              }
              newBox.width = Math.max(50, newBox.width);
              return newBox;
            }
          )
        } else if (selectedNode.attrs.ratioType == 'free') {
          transformerNode.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right', 
                                        'middle-left', 'middle-right', 'top-center', 'bottom-center'])
          transformerNode.boundBoxFunc((oldBox, newBox) => {
            const box = this.getClientRect(newBox);
            const isOut =
            box.x < 0 ||
            box.y < 0 ||
            box.x + box.width > stage.width() ||
            box.y + box.height > stage.height();

            // if new bounding box is out of visible viewport, let's just skip transforming
            // this logic can be improved by still allow some transforming if we have small available space
            if (isOut) {
              return oldBox;
            }
            return newBox;
          })
        } else {
          transformerNode.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right'])
          transformerNode.boundBoxFunc( (oldBox, newBox) => {
            const box = this.getClientRect(newBox);
            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stage.width() ||
              box.y + box.height > stage.height();

            // if new bounding box is out of visible viewport, let's just skip transforming
            // this logic can be improved by still allow some transforming if we have small available space
            if (isOut) {
              return oldBox;
            }
            return newBox;
          })
        }
      } else {
        // remove transformer
        this.selectedShape = null
        transformerNode.nodes([]);
      }
    },
    getClientRect(rotatedBox) {
      const { x, y, width, height } = rotatedBox;
      const rad = rotatedBox.rotation;

      const p1 = this.getCorner(x, y, 0, 0, rad);
      const p2 = this.getCorner(x, y, width, 0, rad);
      const p3 = this.getCorner(x, y, width, height, rad);
      const p4 = this.getCorner(x, y, 0, height, rad);

      const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
      const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
      const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
      const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
      };
    },
    getCorner(pivotX, pivotY, diffX, diffY, angle) {
      const distance = Math.sqrt(diffX * diffX + diffY * diffY);

      /// find angle from pivot to corner
      angle += Math.atan2(diffY, diffX);

      /// get new x and y and round it off to integer
      const x = pivotX + distance * Math.cos(angle);
      const y = pivotY + distance * Math.sin(angle);

      return { x: x, y: y };
    },
    handleDblClick(e) {
      const textNode = e.target
      const stage = e.target.getStage()
      const tr = stage.findOne('Transformer')
      textNode.hide();
      tr.hide();

      // create textarea over canvas with absolute position
      // first we need to find position for textarea
      // how to find it?

      // at first lets find position of text node relative to the stage:
      var textPosition = textNode.absolutePosition();

      // so position of textarea will be the sum of positions above:
      var areaPosition = {
        x: Math.round(stage.container().offsetLeft + textPosition.x),
        y: Math.round(stage.container().offsetTop + textPosition.y) - Math.round(1 * this.initialText),
      };

      // create textarea and style it
      var textarea = document.createElement('textarea');
      const collection = document.getElementById("canvas-layout");
      // document.body.appendChild(textarea);
      collection.appendChild(textarea);

      // apply many styles to match text on canvas as close as possible
      // remember that text rendering on canvas and on the textarea can be different
      // and sometimes it is hard to make it 100% the same. But we will try...
      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosition.y + 'px';
      textarea.style.left = areaPosition.x + 'px';
      textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
      textarea.style.height = textNode.height() - textNode.padding() * 2 + 5 + 'px';
      textarea.style.fontSize = textNode.fontSize() + 'px';
      textarea.style.border = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.lineHeight = textNode.lineHeight();
      textarea.style.fontFamily = textNode.fontFamily();
      textarea.style.transformOrigin = 'left top';
      textarea.style.textAlign = textNode.align();
      textarea.style.color = textNode.fill();
      const rotation = textNode.rotation();
      var transform = '';
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }

      var px = 0;
      // also we need to slightly move textarea on firefox
      // because it jumps a bit
      var isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        px += 2 + Math.round(textNode.fontSize() / 20);
      }
      transform += 'translateY(-' + px + 'px)';

      textarea.style.transform = transform;

      // reset height
      textarea.style.height = 'auto';
      // after browsers resized it we can set actual value
      textarea.style.height = textarea.scrollHeight + 3 + 'px';

      textarea.focus();

      function removeTextarea() {
        textarea.parentNode.removeChild(textarea);
        collection.removeEventListener('click', handleOutsideClick);
        textNode.show();
        tr.show();
        tr.forceUpdate();
      }

      function setTextareaWidth(newWidth) {
        if (!newWidth) {
          // set width for placeholder
          newWidth = textNode.placeholder.length * textNode.fontSize();
        }
        // some extra fixes on different browsers
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isSafari || isFirefox) {
          newWidth = Math.ceil(newWidth);
        }

        var isEdge =
          document.documentMode || /Edge/.test(navigator.userAgent);
        if (isEdge) {
          newWidth += 1;
        }
        textarea.style.width = newWidth + 'px';
      }

      textarea.addEventListener('keydown', function (e) {
        // hide on enter
        // but don't hide on shift + enter

        if (e.key === 'Enter' && !e.shiftKey) {
          textNode.text(textarea.value);
          removeTextarea();
        }
        // on esc do not set value back to node
        if (e.key === 'Escape') {
          removeTextarea();
        }
      });

      textarea.addEventListener('keydown', function (e) {
        const scale = textNode.getAbsoluteScale().x;
        setTextareaWidth(textNode.width() * scale);
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + textNode.fontSize() + 'px';
      });

      function handleOutsideClick(e) {
        if (e.target !== textarea) {
          textNode.text(textarea.value);
          removeTextarea();
        }
      }
      setTimeout(() => {
        collection.addEventListener('click', handleOutsideClick);
      });
    },
    getListImage(){
      return this.listSourceImage
    },
    setImageFieldName(value) {
      this.selectedShape.attrs['fieldName'] = value
      const shapeGroup = this.selectedShape.attrs.id.split('-')[0]
      const groupText = this.selectedShape.parent.children[3]
      const stage = this.selectedShape.parent.parent.parent.attrs.id
      if(groupText){
        let configGroup = this.stages[stage].groups[shapeGroup]
        let configText = this.stages[stage].groups[shapeGroup].texts
        let configImageState = this.stages[stage].groups[shapeGroup].emptyImageState

        // set text attribute
        configText.text = `Src: ${value}`
        configText.width = this.selectedShape.attrs.width
        configText.y = (this.selectedShape.attrs.height / 2) + (configGroup.emptyImageState.height/2)

        //set emptyImageState attribute
        configImageState.image = this.getEmptyStateImage()
        configImageState.y = (this.selectedShape.attrs.height / 2) - (configGroup.emptyImageState.height/2)
        configImageState.x = (this.selectedShape.attrs.width / 2) - (configGroup.emptyImageState.width/2)
      }
    },
    setTextFieldName(value){
      this.selectedShape.attrs['fieldName'] = value
      this.selectedShape.attrs.text = value
      this.selectedShape.textArr[0].text = value
    },
    getEmptyStateImage(){
      const image = new Image()
      image.src = imageUrl
      image.decode()
      return image
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
