<template>
  <oba-modal class="modal-create-ticket" :id="customId" :show="visible" @close="closeModal">
    <template v-slot:modal-content>
      <div class="title-modal">
        <h2>Create Ticket</h2>
      </div>
      <div class="mid-content">
        <oba-form-builder :key="formKeyTicket" ref="formCreateTicket" :fields="fieldsCreateTicket" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="buttons">
        <oba-button 
          class="btn-cancel" 
          category="secondary" 
          label="Cancel" 
          @onClick="closeModal">
        </oba-button>
        <oba-button 
          category="primary" 
          label="Submit" 
          :isDisabled="isRequesting" 
          :isLoading="isRequesting" 
          @onClick="submit">
        </oba-button>
      </div>
      </template>
  </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ObaModal from '@/components/modal'
import ObaButton from '@/components/button'
import FormBuilder from '@/components/formBuilder'

import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'
import { showNotification, generateRandomId, generateSummary } from '@/lib/utils'
import { generateProps } from '@/lib/propsGenerator'
import { convertFromDataForm } from "@/lib/transform"


const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')

export default {
  name: 'oba-create-ticket',
  components: {
    'oba-modal': ObaModal,
    'oba-button': ObaButton,
    'oba-form-builder': FormBuilder,
  },
  setup(){
    async function addDocument(data){
      const config = {
        url: apiUrls.addTicket(this.projectName, this.collection),
        method: 'post',
        data : data
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    async function uploadFile(formData, docId){
      const config = {
        url: apiUrls.uploadFile(this.projectName, this.collection, docId),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data : formData
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return { addDocument, uploadFile }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    collection: {
      type: String,
      required: true
    },
    subItem: {
      type: Object,
      required: false,
      default: null
    }
  },
  data(){
    return {
      formKeyTicket: 0,
      fieldsCreateTicket: [],
      isRequesting: false,
      customId: '',
    }
  },
  beforeMount(){
    this.customId = generateRandomId()
    this.generateFieldsCreateTicket()
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateFieldsCreateTicket()
      }
    }
  },
  computed:{
    ...mapStateUser([
      'userList',
      'uid',
    ]),
    ...mapStateConfig([
      'projectName',
    ]),
    ...mapGettersConfig([
      'getDataType',
      'getCollectionEntries',
      'getInitialStatus',
      'getOnCreateEntries',
      'getAutoAssign',
    ]),
  },
  methods:{
    closeModal(){
      this.$emit('closeCreate')
    },
    cleanData(data){
      // exclude field status dan summary serta mengubah required true pada attachment
      let fieldList = []
      let copiedData = JSON.parse(JSON.stringify(data))
      const excludeFields = ["status","summary"]

      copiedData.forEach(item => { 
        if (!excludeFields.includes(item.name.toLowerCase())){
          item.validations = {}
          fieldList.push(item)
        }
      })
      return fieldList
    },
    getUserOptions(){
      let userList = JSON.parse(JSON.stringify(this.userList))
      return {
        options: userList.map((item, index) =>{
          return {
            "id" : index,
            "label" : item.email,
            "value": item.uid,
            "disabled": false
          }
        })
      } 
    },
    async generateFieldsCreateTicket(){
      const entries = this.cleanData(this.getOnCreateEntries(this.collection))

      let fieldsCreate = await generateProps(entries)
      // mengubah field assignee menjadi dropdown
      fieldsCreate.forEach(field => {
        if (field.label.toLowerCase() == 'assignee'){
          field.type = 'dropdown'
          field['extra'] = this.getUserOptions()
        }
        // mengosongkan default value untuk field Date dan date time
        if(field.type.toLowerCase() == 'date' || field.type.toLowerCase() == 'datetime'){
          field.inputValue = ''
        }
        // insert parentValue into childFIeld 
        if(field.label.toLowerCase() == this.getChildField()){
          if(!this.subItem.parentValue){
            field.inputValue = ''
            field.disabled = false
            field.helperText = `Field ${this.subItem.parentField} pada parent tidak boleh kosong`
            field.validations.required = true
          } else {
            field.inputValue = this.subItem.parentValue.toString()
            field.isDisabled = true
          }
        }
      })
      
      this.fieldsCreateTicket = fieldsCreate
      this.formKeyTicket++
    },
    getChildField(){
      if(this.subItem){
        return this.subItem.childField.toLowerCase()
      }
      return ''
    },
    collectData(){
      let data  = {}
      let fieldData =  {}
      let attachmentData = {}
      const form = this.$refs['formCreateTicket']
      const rawData = form.collectValue()
      const fieldStatus = this.getCollectionEntries(this.collection).find(field => field.name.toLowerCase() == 'status')
    
      if(!rawData) return null

      /**separate data that contains attachments and does not contain*/
      for (const [key, value] of Object.entries(rawData)){
        const type = this.getDataType(key,this.collection)
        if(type != "attachment"){
          fieldData[key]  =  value
        }else {
          attachmentData[key] = value
        }
      }

      // Check autoAssign
      if(this.getAutoAssign(this.collection)){
        fieldData['assignee'] = this.uid
      }
      
      // Check field status
      if(fieldStatus){
        fieldData['status'] = this.getInitialStatus(this.collection)
      }

      // Generate summary
      fieldData['summary'] = generateSummary(this.collection,fieldData)
      
      data["field"] = convertFromDataForm(fieldData, this.collection)
      data["attachment"] = attachmentData
      return data
    },
    async uploadAttachment(data, docId){
      for (const [key, value] of Object.entries(data)){
        if(value.length > 0){
          let formData = new FormData()
          for (const file of value){
            formData.append(key, file)
            await this.uploadFile(formData, docId)
          }
        }
      }
    },
    async submit(){
      this.isRequesting = true
      const data = this.collectData()
      if(data){
        if(this.subItem) {
          data.field[this.getChildField()] = this.subItem.parentValue.toString()
        }
        const response = await this.addDocument(data.field)
        if(response.status != 201){
          showNotification('error', `Error, ${response.data}`)
        } else {
          await this.uploadAttachment(data.attachment, response.data.id)
          showNotification('success', `Success, ticket has been created`)
          this.$emit('onCreate')
          this.closeModal()
        }
      }
      this.isRequesting = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>