<template>
    <oba-modal 
      class="modal-delete" 
      id="modaldelete" 
      :show="visible" 
      title="Delete Ticket"
      @close="closeModal">
      <template v-slot:modal-content>
          <div  class="mid-content">
            <span>Are you sure want to delete this ticket ?</span>
          </div>
      </template>
      <template v-slot:modal-footer>
          <div class="buttons">
            <oba-button 
              class="btn-cancel" 
              category="secondary" 
              label="Cancel" 
              @onClick="closeModal">
            </oba-button>
            <oba-button 
              category="primary" 
              label="Delete" 
              :isDisabled="isRequesting" 
              :isLoading="isRequesting" 
              @onClick="deleteDocument">
            </oba-button>
          </div>
      </template>
    </oba-modal>
</template>

<script>
import ObaModal from '@/components/modal'
import ObaButton from '@/components/button'

import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'
import { showNotification } from '@/lib/utils'

export default {
  name: 'delete-modal',
  components: {
    'oba-modal': ObaModal,
    'oba-button': ObaButton
  },
  setup(){
    const deleteDocument = async (projectName, collectionKey, docId) => {
      const config = {
        url: apiUrls.deleteDocument(projectName, collectionKey, docId),
        method: 'delete'
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }
    return {deleteDocument}
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
    projectName:{
      type: String,
      required: true,
    },
    collectionKey:{
      type: String,
      required: true,
    },
    docId: {
      type: String,
      required: true,
      default: ""
    },
  },
  data() {
    return {
      isRequesting: false
    }
  },

  methods:{
    closeModal(){
      this.$emit('closeDelete')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    /**
     * Delete document from collection on server
     */
    async deleteDocument(){
      this.setLoadingAndDisable(true)
      const response = await this.deleteDocument(this.projectName , this.collectionKey, this.docId)
      if(response.status == 204){
        this.setLoadingAndDisable(false)
        showNotification('success', `Success, ticket has been delete`)
        this.$emit('onDeletedDoc')
      } else{
        this.setLoadingAndDisable(false)
        this.closeModal()
        showNotification('error', `Error, ticket failed to delete`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">

</style>