<template>
  <div :class="['wrapper-right', navActive ? 'sidenav-open' : 'sidenav-closed']" >
    <i @click="sidenavTrigger" class="material-icons icon-nav">{{triggerIcon}}</i>
    <div class="menu-wrapper">
      <div class="left-menu">
        <oba-breadcrumb :collection="currentCollection" :projectName="projectDisplayName"/>
        <p>{{pageCollection}}</p>
      </div>
      <div>
      </div>
      <div class="feature">
        <oba-tooltip :text="`Download Attachment`">
          <template v-slot:content>
            <oba-button @onClick="downloadAttachment()" v-if="downloadAttachmentPermission" :isDisabled="isPageLoading" category="icon" icon="download_for_offline" class="download-bast" />
          </template>
        </oba-tooltip>
        <oba-tooltip :text="`Download report`" :position="`bottom`">
          <template v-slot:content>
            <oba-button :isDisabled="isPageLoading" v-if="bastPermission" category="icon" icon="download_for_offline" @onClick="triggerModalReport(true)" class="download-bast" />
          </template>
        </oba-tooltip>
        <oba-tooltip :text="`Import data from Excel`" :position="`bottom`">
          <template v-slot:content>
            <oba-button :isDisabled="isPageLoading" v-if="importAble" category="icon" icon="file_download" @onClick="triggerModalImport" class="import" />
          </template>
        </oba-tooltip>
        <oba-tooltip :text="`Export data to Excel`" :position="`bottom`">
          <template v-slot:content>
            <oba-button :isDisabled="isPageLoading" v-if="exportAble" category="icon" icon="file_upload" @onClick="exportExcel()" class="exportExcel" />
          </template>
        </oba-tooltip>
        <oba-tooltip :text="`Create Ticket`" :position="`bottom`">
          <template v-slot:content>
            <oba-button :isDisabled="isPageLoading" v-if="createAble" category="primary" label="New ticket" icon="add_circle" @onClick="toggleCreateTicket(true)" class="create-ticket" />
          </template>
        </oba-tooltip>
        <oba-modal id="oba-modal-2" :show="displayImport" @close="triggerModalImport">
          <template v-slot:modal-content>
            <oba-import-excel v-if="renderComponent" @cancelImport="triggerModalImport" @imported="reloadData"> </oba-import-excel>
          </template>
        </oba-modal>
        <!-- report modal -->
        <oba-modal-report
          :visible="displayReport"
          :filters="filters"
          type="bast"
          @submit="onDownloadReport"
          @close="triggerModalReport(false)">
        </oba-modal-report>
        <!-- report modal -->
      </div>    
    </div>
    <div v-if="!isLoading" class="menu-wrapper">
      <div class="left-buttons">
        <div class="top">
          <div class="filter">
            <!-- <oba-button category="secondary" icon="tune" label="Filter" @onClick="triggerModalFilter"/> -->
            <button type="button" :class="['btn', 'secondary', filters.length == 0 ? '' : 'selected']" @click.stop="triggerModalFilter(true)">
              <i v-if="filters.length == 0" class="material-icons left">tune</i>
              <span v-else class="amount">{{filters.length}}</span>
              Filter
            </button>
            <oba-query-builder 
              :displayFilter="displayFilter" 
              @close="triggerModalFilter(false)" 
              :fieldOptions="sortedFieldsType" 
              @applyQuery="onFilterApplied" 
              :initQueries="filters"> 
            </oba-query-builder>
          </div>
          <div class="sort">
            <button type="button" :class="['btn', 'secondary', sorts.length == 0 ? '' : 'selected']" @click.stop="triggerModalSort">
              <i v-if="sorts.length == 0" class="material-icons left">sort</i>
              <span v-else class="amount">{{sorts.length}}</span>
              Sort
            </button>
            <oba-sort-builder 
              :displaySort="displaySort" 
              @close="triggerModalSort" 
              :fieldOptions="sortableFields" 
              @applyQuery="onSortApplied" 
              :initQueries="sorts"> 
            </oba-sort-builder>
          </div>
        </div>
        <div class="bottom">
          <div v-if="filters.length > 0" class="filter">
            <span>Filter: </span> 
            <div v-for="filter, i in filters" :key="i" class="info">
              <span class="label">{{`${filter.field} ${filter.operator} ${filter.value != null ? filter.value : '' }`}}</span>
              <i class="material-icons" @click.stop="removeQueryFilter(i)">close</i>
            </div>
          </div>
          <div v-if="sorts.length > 0" class="sort">
            <span>Sort: </span> 
            <div v-for="sort, i in sorts" :key="i" class="info">
              <span class="label">{{`${sort.field} ${sort.value}`}}</span>
              <i class="material-icons" @click.stop="removeQuerySort(i)">close</i>
            </div>
          </div>
        </div>     
      </div>
    </div>
    <div class="total-data">
      <div v-if="selectedDocuments.length !=0" class="bulk-menu">
        <oba-tooltip :text="`Bulk Delete`" :position="`bottom`">
          <template v-slot:content>
            <oba-button category="icon" icon="delete" :isDisabled="!deleteAble" @onClick="triggerModalDelete(true)" class="delete" />
          </template>
        </oba-tooltip>
      </div>
      <div class="detail-total-data" v-if="!isEmpty && !isLoading && selectedDocuments.length == 0">
      {{startData}} - {{endData}} of total {{totalData}}
    </div>
    <div class="detail-total-data" v-if="!isEmpty && !isLoading && selectedDocuments.length != 0">
      Selected {{selectedData}} of total {{totalData}}
    </div>
    </div>
    <div class="table-wrapper" v-if="!isEmpty && !isLoading">
      <div class="table">
        <oba-table 
        :collection="currentCollection" 
        :header="customHeader" 
        :body="tableBody" 
        @selectRow="onSelectedRow"
        @selectDoc="onSelectedDocument" 
        @selectAllData="onSelectedAllData"
        />
      </div>
    </div>
    <div class="empty-state" v-if="isEmpty">
      <oba-empty-state />
    </div>
    <div class="loading" v-if="isLoading">
      <oba-loading width="480px" />
    </div>
    <div class="pagination" v-if="!isEmpty && !isLoading">
      <oba-pagination 
      :initPage="selectedPage" 
      :maxPage="maxPage" 
      @changePage="onPageChanged"/>
    </div>
    <oba-create-ticket
      :visible="displayCreate"
      :collection="currentCollection"
      @closeCreate="toggleCreateTicket(false)"
      @onCreate="onCreateDocument" >  
    </oba-create-ticket>
    <oba-bulk-delete 
      type="bulk-delete"
      :visible="displayBulkDelete" 
      :isSelectedAll="selectedAll" 
      :collection="currentCollection"
      :documents="selectedDocuments" 
      :totalData="totalData"
      @closeDelete="triggerModalDelete(false)"
      @onBulkDelete="onBulkDelete">
    </oba-bulk-delete>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import FileSaver from 'file-saver';

import { transformDataTable, transformUpperCase, underscoreRemover, transformToIsoString } from '@/lib/transform.js'
import { doRequest } from '@/lib/request/index.js'
import { showNotification } from '@/lib/utils'
import apiUrls from '@/lib/request/urls.js'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils';
import { config as envConfig } from '@//lib/config.js'

import pagination from '@/components/pagination'
import button from '@/components/button'
import table from '@/components/table'
import modal from '@/components/modal'
import modalReport from '@/views/ListTicket/downloadReport'
import emptyState from '@/components/emptyState'
import loading from '@/components/loading'
import tooltip from '@/components/tooltip'
import queryBuilder from '@/views/queryBuilder'
import sortBuilder from '@/views/ListTicket/sortBuilder'
import createTicket from '@/components/createTicket'
import importExcel from '@/views/importExcel'
import InputText from '../../components/form/inputText'
import breadCrumb from '../../components/breadCrumb'
import bulkDelete from '@/views/ListTicket/bulkDelete'
import axios from 'axios';

const { mapState: mapStateConfig, mapGetters: mapGettersConfig, mapMutations: mapMutationsConfig  } = createNamespacedHelpers('config')
const { mapState: mapStateUser, mapGetters: mapGettersUser, mapMutations: mapMutationsUser } = createNamespacedHelpers('user')

export default {
  setup() { //setup
    async function getUserList(projectName){
      const config = {
        url: apiUrls.getUsers(projectName),
        method: 'get',
        params: {
          page: this.selectedPage,
          size: 10000
        }
      }
      const response = await doRequest(config, {needToken: true})
      if(response.status == 200){
        this.SAVE_USER_LIST(response.data.data)
      }
    }

    async function getSortableFields(projectName, collectionName){
      const config = {
        url: apiUrls.getSortableFields(projectName, collectionName),
        method: 'get',
      }
      const response = await doRequest(config, {needToken: true})
      if(response.status == 200){
        this.sortableFields = response.data
      }
    }

    async function getListTickets(projectName, collectionName){
      this.isLoading = true
      this.isEmpty = false
      this.selectedDocuments = []
      const filters = []
      const sorts = []
      const defaultFilters = this.getDefaultFilters(collectionName)
      if(defaultFilters.length != 0){
        defaultFilters.forEach(item => {
          filters.push(item)
        })
      }
      this.filters.forEach(item => {
        let value = item.value

        if(item.field.toLowerCase() == 'assignee' && value){
          value = this.getUidByEmail(value)
        }

        // check, if the fieldtype is datetime then parse it into ISOString
        if(this.getDataType(item.field, collectionName) == 'datetime'){
          value = transformToIsoString(item.value)
        }

        //not add value , when filter is empty and not empty
        if((item.operator == "isEmpty") || (item.operator == "isNotEmpty")){
          filters.push({
            field: item.field,
            operator: item.operator
          })
        }else {
          filters.push({
            field: item.field,
            operator: item.operator,
            value: value
        })
        }
      })

      this.sorts.forEach(item => {
        let value = item.value
        sorts.push({
          [item.field]: value,
        })
      })
      
      const response = await doRequest(
        { 
          method: 'post', 
          url: apiUrls.listDocument(projectName, collectionName),
          data: {
            page: this.selectedPage,
            filters: filters,
            sorts: sorts
          }
        },{ needToken: true }
      )
      // cek response status
      if( response.status == 201){
        this.tableBody = transformDataTable(this.tableHeader, response.data.data, this.currentCollection)
        this.maxPage = response.data.lastPage
        this.pageSize =  response.data.pageSize
        this.totalData = response.data.totalHits
        this.SAVE_PAGE_STATE({ 
          selectedPage: this.selectedPage,
          collection: this.currentCollection, 
          filters: this.filters,
          sorts: this.sorts,
          headers: this.tableHeader
        })
        if(response.data.data.length == 0){
          this.isEmpty = true
        }
        this.isLoading = false
      } else {
        this.isLoading = false
        this.isEmpty = true
      }
      this.SET_PAGE_LOADING_STATE(false)
    }

    const requestFieldsType = async (projectName, collection) => {
      const config = {
        url: apiUrls.fieldType(projectName, collection),
        method: 'get'
      }
      return await doRequest(config, { needToken: true })
    }
    
    return {
      getListTickets,
      getUserList,
      getSortableFields,
      requestFieldsType
    }
  },
  components: {
    'oba-button': button,
    'oba-table': table,
    'oba-pagination': pagination,
    'oba-modal': modal,
    'oba-modal-report': modalReport,
    'oba-query-builder': queryBuilder,
    'oba-sort-builder': sortBuilder,
    'oba-empty-state': emptyState,
    'oba-loading': loading,
    'oba-create-ticket': createTicket,
    'oba-import-excel': importExcel,
    'oba-tooltip': tooltip,
    'oba-input-text': InputText, //report template modal component
    'oba-breadcrumb': breadCrumb,
    'oba-bulk-delete': bulkDelete
  },
  props: {
    navActive: { type: Boolean },
  },
  emits: ['sidenavTrigger'],
  data(){
    return {
      tableBody: [],
      tableHeader: [],
      isEmpty: false,
      isLoading: true,
      maxPage: 1,
      selectedPage: 1,
      filters: [],
      filtersShallow: [],
      sorts: [],
      sortShallow: [],
      displayFilter: false,
      displaySort: false,
      displayCreate: false,
      defaultHeader: [],
      pageSize : 1,
      totalData: 0,
      displayImport: false,
      displayReport: false, //report template status
      renderComponent: true,
      pageCollection: "",
      selectedDocuments: [],
      selectedAll: false,
      displayBulkDelete: false,
      bulkDeleteIncrement: 0,
      sortableFields: [],
    }
  },
  async beforeMount(){
    this.SET_PAGE_LOADING_STATE(true)
    let collection = this.getSelectedCollection(this.currentCollection)
    if(collection){
      this.pageCollection = collection.displayText ? collection.displayText : collection.name
    }
    this.getFieldsType(this.projectName, this.currentCollection)
    
    if(this.pageState){
      this.selectedPage = this.pageState.selectedPage
      this.filters = this.pageState.filters
      this.sorts = this.pageState.sorts
      this.tableHeader = this.pageState.headers
      this.defaultHeader = this.getTableColumns(this.currentCollection)
      await this.getSortableFields(this.projectName, this.currentCollection)
      await this.getListTickets(this.projectName, this.currentCollection)
    } else {
      if(this.isAdminGroup){
        await this.getUserList(this.projectName) 
      }
      if(this.collections.length != 0){
        this.tableHeader = this.getTableColumns(this.currentCollection)
        this.defaultHeader = this.getTableColumns(this.currentCollection)
        await this.getSortableFields(this.projectName, this.currentCollection)
        await this.getListTickets(this.projectName, this.currentCollection)
      } else {
        // apabila tidak ada collection maka hilangkan loading dan tampilkan empty state
        this.isLoading = false
        this.isEmpty = true
      }
    }
  },
  computed: {
    ...mapGettersUser([
      'getUidByEmail',
      'isAdminGroup',
      'isPageLoading'
    ]),
    ...mapGettersConfig([
      'getTableColumns',
      'getCollectionEntries',
      'getDataType',
      'getAssignPermission',
      'getCreatePermission',
      'getDisplayText',
      'getImportPermission',
      'getExportPermission',
      'getBastPermission',
      'getSelectedCollection',
      'getDownloadAttachmentPermission',
      'getDefaultFilters',
      'getDeletePermission',
    ]),
    ...mapStateConfig([
      'projectName',
      'currentCollection',
      'collections',
      'projectDisplay'
    ]),
    ...mapStateUser([
      'pageState',
      'userList',
    ]),
    triggerIcon() {
      if(this.navActive){
        return 'chevron_left'
      } else {
        return 'chevron_right'
      }
    },
    projectDisplayName(){
      return this.projectDisplay ? this.projectDisplay : this.projectName
    },
    customHeader(){
      let headers = this.tableHeader.map(item => {
        const displayText  = this.getDisplayText(item, this.currentCollection)
        item = displayText ? displayText : item
        return transformUpperCase(item)
      })
      return headers
    },
    startData(){
      return ((this.selectedPage - 1) * this.pageSize ) + 1
    },
    endData(){
      return this.selectedPage == this.maxPage ?
         this.totalData :  (this.selectedPage * this.pageSize)
    },
    sortedFieldsType() {
      let sortedData = {}
      const entryList = this.getCollectionEntries(this.currentCollection)
      entryList.forEach(item => {
        sortedData[item.name] = this.getDataType(item.name, this.currentCollection)
      })

      return sortedData
    },
    deleteAble(){
      return this.getDeletePermission(this.currentCollection)
    },
    createAble(){
      return this.getCreatePermission(this.currentCollection)
    },
    importAble(){
      return this.getImportPermission(this.currentCollection)
    },
    exportAble() {
      return this.getExportPermission(this.currentCollection)
    },
    bastPermission() {
      return this.getBastPermission(this.currentCollection)
    },
    downloadAttachmentPermission() {
      return this.getDownloadAttachmentPermission(this.currentCollection)
    },
    selectedData(){
      let totalSelected = this.selectedDocuments.length
      if(this.selectedAll){
        totalSelected = this.totalData
      }
      return totalSelected
    },
  },
  methods: {
    ...mapMutationsConfig({
      'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION',
      'saveFieldsType': 'SAVE_FIELDS_TYPE_TO_COLLECTION',
    }),
    componentRerender() {
        // Remove component importExcel from the DOM
        this.renderComponent = false;

        this.$nextTick(() => {
          // Add the component back in
          this.renderComponent = true;
        });
      },
    ...mapMutationsUser([
      'SAVE_PAGE_STATE',
      'SAVE_USER_LIST',
      'SET_PAGE_LOADING_STATE'
    ]),
    removeQueryFilter(index) {
      this.selectedPage = 1
      this.removeTableHeader(this.filters[index].field)
      this.filters.splice(index, 1)
      this.getListTickets(this.projectName, this.currentCollection)
    },
    removeQuerySort(index) {
      this.selectedPage = 1
      this.removeTableHeader(this.sorts[index].field)
      this.sorts.splice(index, 1)
      this.getListTickets(this.projectName, this.currentCollection)
    },
    removeTableHeader(fieldName) {
      let defaultLabel = this.defaultHeader.map(( value ) => { return value });
      if (!defaultLabel.includes(fieldName)) {
        this.tableHeader = this.tableHeader.filter( value => value != fieldName );
      }
    },
    reloadData(){
      this.triggerModalImport()
      this.getListTickets(this.projectName, this.currentCollection, this.filters, this.selectedPage)
    },
    onFilterApplied(filters){
      this.selectedPage = 1
      this.filters = filters
      this.addTableHeader()
      this.getListTickets(this.projectName, this.currentCollection)
      this.triggerModalFilter(false)
    },
    addTableHeader(){
      let exists = this.tableHeader.map(( value ) => { return value});
      let addition = this.filters.map(( value ) => { return value.field});
      let difference = addition.filter(x => !exists.includes(x));
      for (let header of difference) {
        this.tableHeader.push(header)
      }
    },
    onSortApplied(sort){
      this.selectedPage = 1
      this.sorts = sort
      this.addTableHeaderSort()
      this.getListTickets(this.projectName, this.currentCollection)
      this.triggerModalSort()
    },
    addTableHeaderSort(){
      let exists = this.tableHeader.map(( value ) => { return value});
      let addition = this.sorts.map(( value ) => { return value.field});
      let difference = addition.filter(x => !exists.includes(x));
      for (let header of difference) {
        this.tableHeader.push(header)
      }
    },
    onPageChanged(page){
      this.selectedPage = page
      this.getListTickets(this.projectName, this.currentCollection)
    },
    onSelectedRow(selectedRow){
      this.$router.push({
        name: "detailpage", 
        params: { collection: this.currentCollection, docId: selectedRow.docId}
        })
    },
    sidenavTrigger(){
      this.$emit('sidenavTrigger')
    },
    triggerModalFilter(value){
      this.displayFilter = value
    },
    triggerModalSort(){
      this.displaySort = !this.displaySort
    },
    triggerModalImport(){
      this.componentRerender()
      this.displayImport = !this.displayImport
    },
    triggerModalReport(value){ // open report modal function
      this.displayReport = value
    },
    onDownloadReport() {
      this.triggerModalReport(false)
      this.saveCurrentCollection('queue')
      this.$router.push({
        name: 'management'
      })
    },
    async downloadAttachment() {
      this.isLoading = true
      const filters = []
      this.filters.forEach(item => {
        let value = item.value
        if (item.field.toLowerCase() == 'assignee') {
          value = this.getUidByEmail(value)
        }
        filters.push({
          field: item.field,
          operator: item.operator,
          value: value
        })
      })
      const config = {
        url: apiUrls.listAttachments(this.projectName, this.currentCollection),
        method: 'post',
        data: {
          page: this.selectedPage,
          filters: filters,
        }
      }
      const zip = new JSZip()
      const rootFolder = zip.folder(this.currentCollection)
      const response = await doRequest(config, { needToken: true });
      const zipName = `${this.projectName}-${this.currentCollection}_attachments.zip`
      if (response.status == 201) {
        const docs = response.data.data
        if (docs.length == 0) {
          showNotification('failed', `No attachment found`)
        } else {
          let total_imgs = docs.map(doc => {
            return Object.keys(doc)
              .filter(key => key !== 'id')
              .reduce((count, key) => {
                if (Array.isArray(doc[key])) {
                  return count + doc[key].length;
                }
                return count;
              }, 0);
          }).reduce((a, b) => a + b, 0);
          const imgPrefix = envConfig.imgPrefix
          let img_counter = 0;
          docs.map(doc => {
            const id = doc.id
            const attachments = Object.keys(doc).filter(key => key != 'id')
            const folder = rootFolder.folder(id)
            attachments.map(attachment => {
              const attachmentUrls = doc[attachment]
              attachmentUrls.map(url => {
                const filename = `${attachment}-${url.split('/').pop()}`
                const imgUrl = imgPrefix + url
                JSZipUtils.getBinaryContent(imgUrl, async (err, data) => {
                  if (err) {
                    throw err
                  }
                  folder.file(filename, data, { binary: true });
                  img_counter++
                  if (img_counter == total_imgs) {
                    const fileZip = await zip.generateAsync({ type: 'blob' })
                    FileSaver.saveAs(fileZip, zipName)
                    this.isLoading = false; // Set isLoading to false when done
                  }
                })
              })
            })
          })
        }
      } else {
        showNotification('failed', `it looks like you don't have access to do this process.`)
      }
    },
    toggleCreateTicket(value){
      this.displayCreate = value
    },
    async onCreateDocument(){
      if (this.totalData <= 1) {
        this.getSortableFields(this.projectName, this.currentCollection)
      }
      await this.getListTickets(this.projectName, this.currentCollection)
    },
    async exportExcel(){
      const filters = []
      this.filters.forEach(item => {
        let value = item.value
        if(item.field.toLowerCase() == 'assignee'){
          value = this.getUidByEmail(value)
        }
        const fieldType = this.getDataType(item.field.toLowerCase(), this.currentCollection);
        let filterValue = value;

        if (fieldType === 'datetime') {
          const ISOTime = new Date(value);
          filterValue = ISOTime.toISOString();
        }

        filters.push({
          field: item.field,
          operator: item.operator,
          value: filterValue
        })
      })
      const fileName = this.projectName + "_" + this.currentCollection + ".xlsx"
      const config = {
        url: apiUrls.exportExcel(this.projectName, this.currentCollection),
        method: 'post',
        responseType: 'arraybuffer',
        headers: { "Content-Type": "multipart/form-data" },
        data : {
          filters : filters
        }
      }

      const response = await doRequest(config, {needToken: true}).then(response => {
        if (response.status == 200){
          let blob = new Blob([response.data], { type: 'application/ptestdf' })
          FileSaver.saveAs(blob, fileName)
        } else{
          showNotification('failed', `it looks like you don't have access to do this process.`)
        }
      })
    },
    // Bulk Operations
    onSelectedDocument(selectedDoc){
      this.selectedAll = false
      this.selectedDocuments = selectedDoc
    },
    onSelectedAllData(value){
      this.selectedAll = value
    },
    triggerModalDelete(value){
      this.displayBulkDelete = value
    },
    onBulkDelete(){
      this.getListTickets(this.projectName, this.currentCollection)
    },
    // End of Bulk Operations

    async getFieldsType(projectName, collection){
      const selectedCollection = this.getSelectedCollection(this.currentCollection)
      if(!selectedCollection.fieldsType){
        const fieldsType = await this.requestFieldsType(projectName, collection)
      
        if (fieldsType.status != 200) {
          if(fieldsType.data.error.reason){
            showNotification('error', `${fieldsType.data.error.reason} (${fieldsType.status})`)
          }
          return
        }
        const cleanedFieldsType = underscoreRemover(fieldsType.data)
        const data = {
          collection: collection,
          fieldsType: cleanedFieldsType
        }
        this.saveFieldsType(data)
      }
    }
  },
  watch:{
    'currentCollection': async function (newValue, oldValue){
      let names = this.collections.map(x => x.name)
      if(newValue && names.includes(newValue)){
        this.isEmpty = false
        this.isLoading = true
        this.selectedPage = 1
        this.filters = []
        this.sorts = []
        this.getFieldsType(this.projectName, newValue)
        this.tableHeader = this.getTableColumns(newValue)
        this.defaultHeader = [...this.getTableColumns(newValue)]
        this.pageCollection = this.getSelectedCollection(newValue).displayText
        await this.getSortableFields(this.projectName, newValue)
        await this.getListTickets(this.projectName, newValue)
      }
    }
  },
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
