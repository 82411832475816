<template>
  <oba-modal id="oba-modal-sort" :show="displaySort" @close="triggerModalsort">
    <template v-slot:modal-content>
      <div class="title">
        Sort Query
      </div>
      <draggable
        :list="modelQueries"
        class="list-group"
        handle=".handle"
        item-key="name"
        ghost-class="ghost"
      >
        <template #item="{ element, index }">
          <div class="list-group-item row">
            <div class="col s5">
              <oba-dropdown-search
                :key="incrementDropdown" 
                :id="field.id" 
                :ref="field.id" 
                :label="field.label" 
                v-model:inputValue="element[field.id]" 
                :validations="field.validations" 
                :extra="field.extra"
              />
            </div>
            <div class="col s5">
              <oba-dropdown-search 
                :key="incrementDropdown" 
                :id="value.id+index" 
                :ref="value.id+index" 
                :label="value.label" 
                v-model:inputValue="element[value.id]"
                :dependencyOptionsId="field.id+index" 
                :validations="field.validations"
                :extra="element[field.id] ? getValueOptions(element[field.id]): {}" 
                :isDisabled="element[field.id] == null" 
              />
            </div>
            <div class="col s1">
              <i class="material-icons handle">drag_handle</i>
            </div>
            <div class="col s1">
              <oba-button category="icon" icon="delete" @onClick="removeQuery(index)" :isDisabled="modelQueries.length==1"></oba-button>
            </div>
          </div>
        </template>
      </draggable>
      <div>
        <a href="#" @click.prevent="addMoreQuery">+ New query</a>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="apply">
        <button class="btn waves-effect waves-light secondary" @click="triggerModalsort" name="cancel">
          Cancel
        </button>
        <button class="btn waves-effect waves-light primary" @click="submitQuery" name="apply">
          Apply
        </button>
      </div>
    </template>
  </oba-modal>
</template>

<script>
import ObaDropdownSearch from '@/components/form/selectSearch'
import InputText from '@/components/form/inputText'
import ObaButton from '@/components/button'
import modal from '@/components/modal'
import draggable from 'vuedraggable'

// third party lib
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'sort-builder',
  components: {
    ObaDropdownSearch,
    InputText,
    ObaButton,
    'oba-modal': modal,
    draggable
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  props: {
    fieldOptions: {
      type: Object,
      required: true,
      default() {
        return { }
      }
    },
    initQueries: {
      type: Array,
      required: true,
      default() {
        return [ ]
      }
    },
    displaySort: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["applyQuery", "close"],
  beforeMount() {
    let i = 1
    for (let key of Object.keys(this.fieldOptions)){
      this.field.extra.options.push({
        id: i,
        label : this.capitalizeFirstLetter(key),
        value: key,
        disabled: false
      })
      i++
    }
  },
  data() {
    return  {
      modelQueries: this.initQueries.length > 0 ? [...this.initQueries] : [{field: null, value: null}],
      field: {
        label: 'Field',
        id: 'field',
        validations: {
          required: true
        },
        extra: {
          options : []
        },
      },
      value: {
        label: 'Sort',
        id: 'value',
        validations: {
          required: true
        },
      },
      fields: [{
        label: 'Select Field',
        class: 's5',
        id: 'field',
        validations: {
          required: true
        },
        extra: {
          options : [
            {
              id : 1,
              label : "Text",
              value: "text",
              disabled: false
            },
            {
              id : 2,
              label : "Number",
              value: "number",
              disabled: false
            },
            {
              id : 3,
              label : "Keyword",
              value: "keyword",
              disabled: false
            },
            {
              id : 4,
              label : "Date",
              value: "date",
              disabled: false
            },
            {
              id : 5,
              label : "Boolean",
              value: "boolean",
              disabled: false
            }
          ]
        },
      },
      {
        label: 'Value',
        class: 's4',
        id: 'value',
        validations: {
          required: true
        },
      }],
      incrementDropdown: 1,
    }
  },
  watch: {
    modelQueries: {
      handler: function (newValue, oldValue) {
        this.incrementDropdown++
        const fields = newValue.map((value) => { 
          if (value['field']) return value['field']
        })
        this.field.extra.options.forEach(option => {
          if (fields.includes(option['value'])){
            option['disabled'] = true
          } else {
            option['disabled'] = false
          }
        })
      },
      deep: true
    },
    initQueries: {
      handler: function () {
        this.modelQueries = [...this.initQueries]
      },
      deep: true
    }
  },
  methods: {
    getValueOptions(fieldName) {
      let selectedOptions = {options: []}

      if (this.fieldOptions[fieldName] == 'text' ) {
        selectedOptions['options'] = [
          { id:1, label:'A to Z', value: 'asc', disabled: false }, 
          { id:2, label:'Z to A', value:'desc', disabled:false}
        ]
      } else {
        selectedOptions['options'] = [
          { id:1, label:'Small to large', value: 'asc', disabled: false }, 
          { id:2, label:'Large to small', value:'desc', disabled:false}
        ]
      }
      
      return selectedOptions
    },
    addMoreQuery() {
      this.modelQueries.push({field: null, value: null})
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    submitQuery() {
      this.v$.$touch()
      if (!this.v$.$error) {
        this.$emit("applyQuery", [...this.modelQueries]);
      }
    },
    triggerModalsort() {
      this.$emit("close");
    },
    removeQuery(index) {
      this.modelQueries.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>