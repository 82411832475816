<template>
    <oba-modal
      class="modal-add"
      id="modalAdd" 
      :show= "visible"
      title="Invite New Member" 
      @close="closeModal">
      <template v-slot:modal-content>
        <div class="input-modal-add">
          <oba-form-builder ref="formFieldAddtModal" :fields="fieldAddModal" :key='formKey'/>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="button-modal-add">
          <oba-button category="secondary" label="Cancel" class="cancel" @click="closeModal"/>
          <oba-button 
            category="primary"
            label="Invite"
            :isDisabled="isRequesting" 
            :isLoading="isRequesting" 
            @click="submit" />
        </div>
      </template>
    </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import FormBuilder from '@/components/formBuilder'
import button from "@/components/button"
import modal from "@/components/modal"

import { generateProps } from '@/lib/propsGenerator'
import { showNotification } from '@/lib/utils'
import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'

const {mapState: mapStateConfig } = createNamespacedHelpers('config')

export default {
  name: 'add-modal',
  emits: ['submit','close'],
  setup(){
    async function addUser(projectName,data) {
      const config = {
        url: apiUrls.addUser(projectName),
        method: "post",
        data: {
          email: data['email'],
          group: data['group'],
          role: data['role']
        }
      };
      const response = await doRequest(config, { needToken: true });
      return response
    }

    const getRole = async (projectName) => {
      const config = {
        url: apiUrls.getRoles(projectName),
        methods: 'get',
      }
      const response = await doRequest(config, { needToken: true })
      return response
    }

    return {
      addUser,
      getRole
    }
  },
  components: {
    'oba-modal': modal,
    'oba-button': button,
    'oba-form-builder': FormBuilder
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
  },
  data(){
    return {
      isRequesting: false,
      addFieldsUser: [
      {
        "name": "email",
        "type": "text",
        "validations": {
          "required": true,
          "email": true
        },
        "inputType": "email"
      },
      {
        "name": "group",
        "type": "dropdown",
        "validations": {
          "required": true,
        },
        "choices": [
          "admin",
          "regular",
          "staff"
        ]
      },
      {
        "name": "role",
        "type": "dropdown",
        "validations": {
          "required": true,
        },
        "choices": []
      }
    ],
    fieldAddModal: [],
    formKey: 0

    }
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateField()
      }
    },
  },
  beforeMount() {
    this.loadRoles()
    this.generateField()
  },
  computed:{
    ...mapStateConfig([
      'projectName'
    ]),
  },
  methods:{
    generateField(){
      this.fieldAddModal = generateProps(this.addFieldsUser)
      this.formKey++
    },
    closeModal(){
      this.$emit('close')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    collectData(){
      const form = this.$refs['formFieldAddtModal']
      const formData = form.collectValue()
    
      if(!formData) return null

      return formData
    },
    async submit(){
      this.setLoadingAndDisable(true)
      const data = this.collectData()
      if(data){
        const response = await this.addUser(this.projectName,data)
        if(response.status == 201){
          showNotification('success', `Success, User invited`)
          this.setLoadingAndDisable(false)
          this.$emit('submit')
        } else {
          showNotification('error', `Error, Failed to invite user`)
          this.setLoadingAndDisable(false)
          this.$emit('close')
        }
      }
      this.setLoadingAndDisable(false)
    },

    async loadRoles() {
      const response = await this.getRole(this.projectName)
      this.addFieldsUser[2].choices = response.data
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
