export const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  environtment: process.env.NODE_ENV,
  baseURL: process.env.VUE_APP_BASE_URL,
  imgPrefix: process.env.VUE_APP_IMG_PREFIX,
  metaURL: process.env.VUE_APP_META_BASE_URL,
  projectPageSize: process.env.VUE_APP_PROJECT_PAGE_SIZE,
  bastImgPrefix: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BAST_IMG_PREFIX : process.env.VUE_APP_IMG_PREFIX,
}