<template>
  <div v-if="isVisible" :class="formWidthClass">
    <span :class="['title_field', errClass]">{{customLabel}}</span>
    <p v-for="option in extra.options" :key="option.id">
      <label v-if="option.value.toLowerCase() != 'other'">
        <input class="filled-in" :name="customId" type="checkbox" 
        :checked="checkValue(option.value)" 
        v-model="v$.value.$model"
        :value="option.value"
        :disabled="isDisabled"
        @change="updateData"
        />
        <span>{{option.value}}</span>
      </label>
      <label v-if="option.value.toLowerCase() == 'other'">
        <input class="filled-in other" type="checkbox"
        :id="`other_checkbox_${customId}`" 
        :checked="checkValue(option.value)"
        :value="currentOtherValue"
        :disabled="isDisabled"
        @change="updateData"
        />
        <span class="span-other">
          other :<input type="text" :disabled="otherDisabled" :value="currentOtherValue" @input="updateInputOther">
        </span>
      </label>
    </p>
    <span 
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <div class="helper-wrapper">
      <span v-if="helperText" class="helper-text">{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'


export default {
  name: 'oba-checkbox',
  setup(props) {
    const computedValue = props.inputValue ? props.inputValue : []
    const v$ = setValidations(props.validations, computedValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    inputValue: {
      type: Array,
      required: false,
      default: []
    },
    extra: {
      type: Object,
      required: false,
      default(){
        return {
          // Options bentuknya array of object
          // Berisi id dan value
          options: []
        }
      } 
    }
  },
  data() {
    return {
      otherDisabled: true,
      currentOtherValue: null,
    }
  },
  beforeMount(){
    const other = this.extra.options.find(item => item.value.toLowerCase() == 'other')
    if(other){
      const options = this.getOptionsWithoutOther()
      const otherValue = this.v$.value.$model.find(item => !options.includes(item.toLowerCase()))
      if(otherValue != undefined || otherValue != null){
        this.currentOtherValue = otherValue
      }
    }
  },
  mounted(){
    if(this.currentOtherValue){
      this.toggleDisableOther(false)
    }else {
      this.toggleDisableOther(true)
    }
  },
  methods: {
    getOptionsWithoutOther(){
      const options = this.extra.options.filter(item => item.value.toLowerCase() != 'other').map(option => option.value.toLowerCase())
      return options
    },
    updateData(event){
      const optionsWithoutOther = this.getOptionsWithoutOther()
      if(!optionsWithoutOther.includes(event.target.value.toLowerCase())){
        let otherChecked = document.getElementById(`other_checkbox_${this.customId}`).checked
        if(otherChecked){
          this.v$.value.$model.push('')
          this.toggleDisableOther(false)
        } else {
          const options = this.getOptionsWithoutOther()
          this.v$.value.$model.forEach(item => {
            if(!options.includes(item.toLowerCase())){
              let index = this.v$.value.$model.indexOf(item)
              this.v$.value.$model.splice(index, 1)
            }
          })
          this.currentOtherValue = ''
          this.toggleDisableOther(true)
        }
      }
      this.validateData(this.v$.value.$model)
      this.publishValue(this.v$.value.$model, "checkbox")
    },
    updateInputOther(event){
      let options = this.getOptionsWithoutOther()
      let newValues = this.v$.value.$model.map(item => {
        if(!options.includes(item.toLowerCase())){
          this.currentOtherValue = event.target.value
          return event.target.value
        }
        return item
      })
      this.v$.value.$model = newValues
      this.validateData(this.v$.value.$model)
      this.publishValue(this.v$.value.$model, "checkbox")
    },
    checkValue(value){
      if(value.toLowerCase() == 'other'){
        if(this.currentOtherValue != undefined || this.currentOtherValue != null ){
          return true
        } else {
          return false
        }
      }
      return this.inputValue.includes(value)
    },
    resetData(){
      this.v$.value.$model = []
      this.validateData(this.v$.value.$model)
    },
    toggleDisableOther(value){
      this.otherDisabled = value
    },
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
