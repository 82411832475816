export const apiUrls = {
  listDocument: (projectName, collectionName) => `/data/${projectName}/${collectionName}/listDocuments`,
  listAttachments: (projectName, collectionName) => `/data/${projectName}/${collectionName}/listAttachments`,
  fieldsType: (projectName, collectionName) => `/data/${projectName}/${collectionName}/mapping`,
  downloadConfig: args => `/project/${args}/config/`,
  downloadConfigYaml: args => `/project/${args}/config/yaml/`,
  detailDocument: (project_name, collection, id) => `/data/${project_name}/${collection}/${id}`,
  fieldType:  (project_name, collection) =>`/data/${project_name}/${collection}/mapping`,
  getUsers: args => `/project/${args}/list-users`,
  editTicket: (project_name, collection, id) => `/data/${project_name}/${collection}/${id}`,
  uploadFile: (project_name, collection, id)=> `/data/${project_name}/${collection}/${id}/file`,
  deleteAttachment: (project_name, collection, id, field_name, attachment_url) => `/data/${project_name}/${collection}/${id}/file/${field_name}/${attachment_url}`,
  importExcel: (project_name, collection)=> `/data/${project_name}/${collection}/import`,
  deleteDocument: (projectName, collection, id) => `/data/${projectName}/${collection}/${id}`,
  addTicket: (project_name, collection) => `/data/${project_name}/${collection}`,
  exportExcel: (project_name, collection) => `/data/${project_name}/${collection}/export`,
  createReport: (project_name, collection) => `/extensions/report/${project_name}/${collection}/create`,
  listQueue: (projectName) => `/project/${projectName}/_queue/list`,
  addUser: (projectName) => `/project/${projectName}/inviteUser`,
  getRoles: (projectName) => `/project/${projectName}/roles`,
  getUserById: (projectName, docId) => `/project/${projectName}/user/${docId}`,
  acceptInvitation: (projectName, docId) => `/project/${projectName}/acceptInvitation?docId=${docId}`,
  editUser: (projectName, docId) => `/project/${projectName}/member/${docId}`,
  deleteUser: (projectName, docId) => `/project/${projectName}/member/${docId}`,
  bulkDelete: (projectName, collection) => `/data/${projectName}/${collection}/bulk/delete`,
  getSortableFields: (projectName, collectionName) => `/data/${projectName}/${collectionName}/sortableFields`,
  getAddons: (projectName) => `/project/${projectName}/addons`,
  addAddons: (projectName) => `/project/${projectName}/addons/add`,
  getListBastConfig: (projectName) => `/project/${projectName}/bastconfig/list`,
  addBastConfig: (projectName) => `/project/${projectName}/bastconfig`,
  editBastConfig: (projectName) => `/project/${projectName}/bastconfig`,
  uploadImage: (projectName, collection) => `/data/${projectName}/${collection}/storage/files`,
  getBastByTemplateID: (projectName, templateId) => `/project/${projectName}/bastconfig/${templateId}`,
  deleteConfigLayout: (projectName, templateId) => `/project/${projectName}/bastconfig/${templateId}`,
  importUsers: (projectName) => `/importUsers/${projectName}`,
  bulkEdit: (projectName, collectionName) => `/internal/${projectName}/${collectionName}/bulk/edit`,
  importConfig: (projectName) => `/project/${projectName}/config/excel`,
}

export default apiUrls