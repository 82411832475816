<template>
  <div v-if="isVisible" :class="['input-field', formWidthClass]">
    <label class="active" :for="customId">{{ customLabel }}</label>
    <input 
      :type="extra.inputType" 
      :id="customId"
      :class="[styleClass, errClass, inputIconClass]"
      v-model="v$.value.$model"
      :disabled="isDisabled"
      @input="updateData"
      :placeholder="extra.placeholder"
      autocomplete="off"
    />
    <div v-if="extra.icon">
      <span :class="iconClass">
        <span class="material-icons" @click.stop="clickIcon">{{extra.icon.name}}</span>
      </span>
    </div>
    <span
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <span v-if="helperText" class="helper-text">{{ helperText }}</span>
  </div>
</template>
<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-input-text',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default() {
        return {
          placeholder: "",
          inputType: "text"
        }
      }
    }
  },
  computed: {
    inputIconClass(){
      // adjust width dan cursor ketika ada icon
      let classIcon = ""
      if(this.extra.icon){
        classIcon = classIcon + `adjust-width ${this.extra.icon.position}`
      }
      return classIcon
    },
    iconClass() {
      let classIcon = ""
      if (this.extra.icon) {
        classIcon = `field-icon ${this.extra.icon.position}`
        if(this.extra.icon.function){
          classIcon = classIcon + ' pointer'
        } else {
          classIcon = classIcon + ' default'
        }
      }
      return classIcon
    }
  },
  watch: {
    inputValue: {
      handler: function () {
        this.v$.value.$model = this.inputValue
        this.v$.$reset()
      },
      immediate: true
    }
  },
  methods: {
    updateData(event) {
      this.validateData(event.target.value)
    },
    clickIcon() {
      if (this.extra.icon.function) {
        this.extra.icon.function(this.v$.value.$model, this)
      }
    }
  }
}
    
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>