<template>
   <div class="preloader-wrapper active">
    <div class="spinner-layer" :style="{'border-color': color}">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'oba-circle-loading',
  props:{
    color:{
      type: String,
      required: false,
      default: "#999999"
    }
  }
  
}
</script>

