<template>
  <div class="container-invitation">
    <template v-if="!isLoadingCheck">
      <div v-if="!expired">
        <h4>Accept Invitation</h4>
        <div class="invitation-body">
          <p>
            You have been invited by 
            <span>{{senderEmail}}</span> to join
            <span>{{invitation.projectName}}</span>. 
            You can access the project by clicking “Open Project” button bellow.
          </p>
        </div>
        <div class="invitation-footer">
          <oba-button class="open-project" category="primary" label="Open Project" :isDisabled="isDisabled" :isLoading="isLoading" @onClick="accept"/>
        </div>
          <span class="error-msg">{{ errMsg }}</span>
      </div>
      <div v-else class="invitation-expired">
        <img src="@/assets/emptyState/expired.png" />
        <h4>Invitation has expired</h4>
        <div class="invitation-body">
          <p>
            Oops, this invitation has expired. Please ask the project admin to re-invite you.
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <oba-loading width="480px" />
      </div>
    </template>
  </div>
</template>

<script>
import button from '@/components/button'
import loading from "@/components/loading"

import { doRequest, errorMessage } from "@/lib/request"
import { apiUrls } from "@/lib/request/urls"
import { saveConfig, underscoreRemover } from "@/lib/transform"

import { createNamespacedHelpers } from 'vuex'
const { mapMutations: mapMutationConfig } = createNamespacedHelpers('config')

export default{
  components: {
    "oba-button": button,
    "oba-loading": loading,
  },
  setup() {
    //setup
    /**
     * Get email by docId and projectName from server
     * @param {*} projectName 
     * @param {*} docId 
     */
    async function getEmail(projectName, docId){
      console.log("getEmail",projectName, docId)
      const config = {
        url: apiUrls.getUserById(projectName, docId),
        method: 'get',
        data: {
          docId: docId
        }
      }
      const response = await doRequest(config)
      return response
    }

    /**
     * Accept invitation by docId and projectName from server
     * @param {*} projectName 
     * @param {*} docId 
     */
    async function acceptInvitation(){
      const config = {
        url: apiUrls.acceptInvitation(this.invitation.projectName, this.invitation.docId),
        method: 'put',
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    /**
     * Get config config from server
     * @param {*} projectName 
     * @returns {JSON} config
     */
    const requestConfig = async (projectName) => {
      const config = {
        url: apiUrls.downloadConfigYaml(projectName),
        method: "get",
      }
      return await doRequest(config, { needToken: true })
    }

    /**
     * Get fields type from server
     * @param {*} projectName 
     * @param {*} collections 
     * @returns {JSON} fields type
     */
    const requestFieldsType = async (projectName, collections) => {
      const allRequest = []
      let response = { data: {}, status: 200 }
      collections.forEach(collection => {
        const config = {
          url: apiUrls.fieldType(projectName, collection),
          method: 'get'
        }

        allRequest.push(doRequest(config, { needToken: true }))
      })

      const allResponse = await Promise.all(allRequest)
      const failedRequest = allResponse.find(response => response.status != 200)
      if (failedRequest) {
        response.data = failedRequest.data
        response.status = failedRequest.status
        return response
      }

      collections.forEach((collection, i) => {
        const data = underscoreRemover(allResponse[i].data)
        response.data[collection] = data
      })

      return response
    }

    return {
      getEmail,
      acceptInvitation,
      requestConfig,
      requestFieldsType
    };
  },
  data(){
    return {
      isLoadingCheck: false,
      isLoading: false,
      isDisabled: false,
      errMsg: "",
      senderEmail: '',
      expired: false,
      invitation: {},
      defaultQueries: ["docId", "projectName"]
    }
  },
  async beforeMount() {
    const query = this.$route.query
    for (let key of this.defaultQueries) {
      if(query[key] == undefined) this.$router.push({name: "login"})
      let keyName = key
      this.invitation[keyName] = query[key]
    }
    this.check()
  },
  methods: {
    ...mapMutationConfig({
      'saveFieldsType': 'SAVE_FIELDS_TYPE_TO_COLLECTION'
    }),
    setLoadingAndDisable(state) {
      this.isLoading = state
      this.isDisabled = state
    },
    async check() {
      this.isLoadingCheck = true
      const response = await this.getEmail(this.invitation.projectName, this.invitation.docId)
      if(response.status == 200){
        this.senderEmail = response.data.invitedBy
        this.isLoadingCheck = false
      } else {
        this.isLoadingCheck = false
      }
    },
    async accept() {
      this.setLoadingAndDisable(true)
      const response = await this.acceptInvitation()
      if(response.status == 200){
        await this.downloadConfig()
        if(this.errMsg == null){
          this.$router.push({ name: "tickets" })
          this.setLoadingAndDisable(false)
        }
      } else {
        if (response.data.includes('expired')) {
          this.expired = true
        } else {
          this.errMsg = response.data
        }
        this.setLoadingAndDisable(false)
      }
    },
    async downloadConfig() {
      this.errMsg = null
      const projectName = this.invitation.projectName
      const response = await this.requestConfig(projectName)
      if (response.status == 200) {
        const collections = response.data.collections.map(collection => collection.name)
        saveConfig(response.data, projectName)
        const fieldsType = await this.requestFieldsType(projectName, collections)
        
        if (fieldsType.status != 200) {
          this.setLoadingAndDisable(false)
          if(fieldsType.data.error.reason){
            this.errMsg = fieldsType.data.error.reason
          }
          return
        }
        this.saveFieldsType(fieldsType.data)
        this.setLoadingAndDisable(false)
      } else {
        this.setLoadingAndDisable(false)
        this.errMsg = errorMessage[response.status]
      }
    },
  }
}
</script>


<style lang='scss' scoped>
@import './index.scss';
</style> 