<template>
  <!-- :key="$route.fullPath" is used to force replacement of an element/component 
  with same route but have different params -->
  <div>
    <div class="wrapper-left">
      <oba-side-nav
      :collectionList="collections"
      :isActive="navActive"
      :initial="currentCollection"
      @selected="onSelectedCollection"
      />
    </div>
    <router-view :navActive="navActive" @sidenavTrigger="sidenavTrigger"></router-view>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import sideNav from '@/components/sideNav'

const { mapState: mapStateConfig, mapMutations: mapMutationsConfig } = createNamespacedHelpers('config')
const { mapMutations: mapMutationsUser } = createNamespacedHelpers('user')

export default {
  name: 'oba-home',
  components: {
    'oba-side-nav': sideNav,
  },
  data(){
    return {
      navActive: true,
    }
  },
  mounted() {
    
  },
  computed: {
    ...mapStateConfig([
      'currentCollection',
      'collections'
    ]),
  },
  methods: {
    ...mapMutationsConfig({
      'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION'
    }),
    ...mapMutationsUser({
      'deletePageState': 'DELETE_PAGE_STATE'
    }),
    onSelectedCollection(value) {
      this.saveCurrentCollection(value)
      this.deletePageState()
    },
    sidenavTrigger(){
      this.navActive = !this.navActive
    },
  }
}
</script>