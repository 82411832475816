<template>
  <div class="import-config-wrapper">
    <div class="input-container">
      <div class="title">
        Import Config from Excel File
      </div>
      <div class="input-file">
        <input-file :id="containerFieldId" ref="file" :label="field.label" v-model:inputValue="fileExcel"
          :extra="field.extra" :validations="field.validations" :helperText="helperText" />
      </div>
      <div class="submit">
        <oba-button category="primary" @onClick="submit" label="Submit" :isDisabled="isRequesting"
          :isLoading="isRequesting" />
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/form/inputFile'
import button from '@/components/button'

import { doRequest } from '@/lib/request/index.js'
import apiUrls from '@/lib/request/urls.js'
import { showNotification , generateRandomId} from '@/lib/utils.js'
import { createNamespacedHelpers } from 'vuex'
import { useVuelidate } from '@vuelidate/core'

const { mapState: mapStateConfig } = createNamespacedHelpers('config')

export default {
  name: 'import-config',
  components: {
    InputFile,
    'oba-button': button
  },
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  data() {
    return {
      data: null,
      fileExcel: null,
      isRequesting: false,
      field: {
        label: 'Select File',
        id: 'file',
        validations: {
          required: true
        },
        extra: {
          isMultiple: false,
          fileNames: [],
          accept: "file",
        },
      },
      helperText: '',
      containerFieldId: null,
    }
  },
  beforeMount() {
    this.generateFieldId()
  },
  computed: {
    ...mapStateConfig([
      'projectName',
    ]),
  },
  methods: {
    /**
     * Submit form and import excel file
     * @returns {Promise}
     */
    async submit() {
      this.isRequesting = true
      this.v$.$touch()
      if (!this.v$.$error) {
        const { data, status } = await this.importExcel()
        if (status == 201) {
          showNotification('success', `Successfuly import configuration template! Please change project`)
        } else {
          showNotification('error', `Failed! ${data}`)
        }
      }
      this.isRequesting = false
    },
    /**
     * Import excel file from formdata
     * @returns {Promise}
     */
    async importExcel() {
      this.isRequesting = true
      let fileFormData = new FormData();
      fileFormData.append('file', this.fileExcel[0])
      const config = {
        url: apiUrls.importConfig(this.projectName),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data: fileFormData
      }
      const response = await doRequest(config, { needToken: true })
      this.isRequesting = false
      return response
    },
    /**
     * Generate random id for input file
     */
    generateFieldId() {
      const newId = generateRandomId()
      this.containerFieldId = `excel-${newId}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>