<template>
  <div :class="['wrapper-right', navActive ? 'sidenav-open' : 'sidenav-closed']">
    <i @click="sidenavTrigger" class="material-icons">{{triggerIcon}}</i>
    <div class="wrapper-top">
      <div class="header">
        <div class="breadcrumb">
          <oba-breadcrumb :collection="currentCollection" :projectName="projectDisplayName"/>
        </div>
        <div class="title">{{pageHeader}}</div>
          <router-view/>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { createNamespacedHelpers } from "vuex"
  import breadCrumb from '@/components/breadCrumb'
  
  const { mapState: mapStateConfig, mapMutations: mapMutationsConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers("config")
  
  export default {
    name: "stacks-project-management",
    components: {
      "oba-breadcrumb": breadCrumb,
    },
    props: {
      navActive: { type: Boolean },
    },
    emits: ['sidenavTrigger','selected'],
    data(){
      return {
        pageHeader: '',
        collectionNames: ["bulkEdit", "importConfig", "importUsers"],
      }
    },
    watch:{
      'currentCollection': function (newValue, oldValue){
        if(newValue && this.collectionNames.includes(newValue)){
          this.onSelectedCollection(newValue)
        }
      }
    },
    beforeMount() {
      this.onSelectedCollection(this.currentCollection)
    },
    computed: {
      ...mapStateConfig([
        'projectName',
        'projectDisplay',
        'currentCollection',
      ]),
      ...mapGettersConfig([
        'getCustomCollection',
        'getDisplayText',
      ]),
      projectDisplayName(){
        return this.projectDisplay ? this.projectDisplay : this.projectName
      },
      triggerIcon() {
        if(this.navActive){
          return 'chevron_left'
        } else {
          return 'chevron_right'
        }
      },
    },
    methods: {
      ...mapMutationsConfig({
        'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION'
      }),
      sidenavTrigger() {
        this.$emit('sidenavTrigger')
      },
      onSelectedCollection(selectedCollection){
        let collection = this.getCustomCollection(selectedCollection)
        this.pageHeader = collection.displayText
        this.saveCurrentCollection(selectedCollection)
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import "./index.scss";
  </style>