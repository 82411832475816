<template>
  <oba-modal id="oba-modal-filter" :show="displayFilter" @close="triggerModalFilter">
    <template v-slot:modal-content>
      <div class="title">
        Filter Query
      </div>
      <div class="row" v-for="model, i in modelQueries" :key="i">
        <div class="col s4">
          <oba-dropdown-search :key="incrementDropdown" :id="field.id" :ref="field.id" :label="field.label" v-model:inputValue="model[field.id]" :validations="field.validations" 
          :extra="field.extra"/>
        </div>
        <div class="col s3">
          <oba-dropdown-search 
          :key="incrementDropdown" 
          :id="operator.id+i" 
          :ref="operator.id+i" 
          :label="operator.label" 
          v-model:inputValue="model[operator.id]" 
          :dependencyOptionsId="field.id+i" 
          :validations="field.validations" 
          :extra="model[field.id] ? getOperatorOptions(model[field.id]): {}" 
          :isDisabled="model[field.id] == null" />
        </div>
        <div class="col s4">
          <input-text 
            :id="value.id+i" 
            :ref="value.id+i" 
            :label="value.label" 
            :extra="{placeholder:'Input value'}"
            v-model:inputValue="model[value.id]"
            v-if="isContainDefaultOpt(model[operator.id]) && isNotSpecialType(model[field.id])"
            :validations="field.validations"
          />
          <oba-dropdown-search 
            :key="incrementDropdown" 
            :id="value.id+i" 
            :ref="value.id+i" 
            :label="value.label" 
            v-model:inputValue="model[value.id]" 
            :dependencyOptionsId="field.id+i" 
            v-if="isContainDefaultOpt(model[operator.id]) && isFieldStatus(model[field.id])"
            :validations="field.validations" 
            :extra="model[field.id] ? getStatusOptions(): {}" 
            :isDisabled="model[field.id] == null" />
          <date-picker
            :key="incrementDropdown" 
            :id="value.id+i" 
            :ref="value.id+i" 
            :label="value.label"
            :extra="{placeholder:''}"
            v-model:inputValue="model[value.id]" 
            :dependencyOptionsId="field.id+i" 
            v-if="isContainDefaultOpt(model[operator.id]) && isFieldDate(model[field.id])"
            :validations="field.validations"  
            :isDisabled="model[field.id] == null"
          />
          <date-time
            :key="incrementDropdown" 
            :id="value.id+i" 
            :ref="value.id+i" 
            :label="value.label"
            v-model:inputValue="model[value.id]" 
            :dependencyOptionsId="field.id+i" 
            v-if="isContainDefaultOpt(model[operator.id]) && isFieldDateTime(model[field.id])"
            :validations="field.validations"  
            :isDisabled="model[field.id] == null"
          />
        </div>
        <div class="col s1">
          <oba-button category="icon" icon="delete" @onClick="removeQuery(i)" :isDisabled="modelQueries.length==1"></oba-button>
          <!-- <button class="btn-floating secondary" @click="removeQuery(i)" name="remove">
            <i class="material-icons left">delete</i>
          </button> -->
        </div>
      </div>
      <div>
        <a href="#" @click.prevent="addMoreQuery">+ New query</a>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="search">
        <button class="btn waves-effect waves-light primary" @click="submitQuery" name="search">
          Search
        </button>
      </div>
    </template>
  </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import obaDropdownSearch from '../../components/form/selectSearch'
import InputText from '../../components/form/inputText'
import DatePicker from '../../components/form/datePicker'
import DateTime from '../../components/form/dateTime'
import ObaButton from '@/components/button'
import modal from '@/components/modal'
import { transformFromIsoString, transformToIsoString } from "@/lib/transform"

// third party lib
import { useVuelidate } from '@vuelidate/core'

const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'query-builder',
  components: {
    obaDropdownSearch,
    InputText,
    ObaButton,
    'date-picker': DatePicker,
    'date-time': DateTime,
    'oba-modal': modal,
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  beforeMount() {
    this.generateViewableDatetimeFields();
    let i = 1
    for (let key of Object.keys(this.fieldOptions)){
      this.field.extra.options.push({
        id: i,
        label : this.capitalizeFirstLetter(key),
        value: key,
        disabled: false
      })
      i++
    }
  },
  beforeUnmount() {
    this.generateDatetimeFields();
  },
  props: {
    fieldOptions: {
      type: Object,
      required: true,
      default() {
        return { }
      }
    },
    initQueries: {
      type: Array,
      required: true,
      default() {
        return [ ]
      }
    },
    displayFilter: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["applyQuery", "close"],
  data() {
    return  {
      modelQueries: this.initQueries.length > 0 ? [...this.initQueries] : [{field: null, operator: null, value: null}],
      operatorOptions: {
        member: {
          options : [
            {
              id : 1,
              label : "exact",
              value: "exact",
              disabled: false
            },
            {
              id : 2,
              label : "not contains",
              value : "notContains",
              disabled: false
            }
          ]
        },
        text: { 
          options : [
            {
              id : 1,
              label : "contains",
              value: "contains",
              disabled: false
            },
            {
              id : 2,
              label : "exact",
              value: "exact",
              disabled: false
            },
            {
              id : 3,
              label : "not contains",
              value : "notContains",
              disabled: false
            }
          ]
        },
        label: { 
          options : [
            {
              id : 1,
              label : "exact",
              value: "exact",
              disabled: false
            },
            {
              id : 2,
              label : "not contains",
              value : "notContains",
              disabled: false
            }
          ]
        },
        long: { 
          options : [
            {
              id : 1,
              label : "greater than",
              value: "gt",
              disabled: false
            },
            {
              id : 2,
              label : "greater than equal",
              value: "gte",
              disabled: false
            },
            {
              id : 3,
              label : "lower than",
              value: "lt",
              disabled: false
            },
            {
              id : 4,
              label : "lower than equal",
              value: "lte",
              disabled: false
            },
            {
              id : 5,
              label : "equal",
              value: "equal",
              disabled: false
            },
          ]
        },
        float: { 
          options : [
            {
              id : 1,
              label : "greater than",
              value: "gt",
              disabled: false
            },
            {
              id : 2,
              label : "greater than equal",
              value: "gte",
              disabled: false
            },
            {
              id : 3,
              label : "lower than",
              value: "lt",
              disabled: false
            },
            {
              id : 4,
              label : "lower than equal",
              value: "lte",
              disabled: false
            },
            {
              id : 5,
              label : "equal",
              value: "equal",
              disabled: false
            },
          ]
        },
        date: { 
          options : [
            {
              id : 1,
              label : "greater than equal",
              value: "gte",
              disabled: false
            },
            {
              id : 2,
              label : "lower than equal",
              value: "lte",
              disabled: false
            },
            {
              id : 3,
              label : "equal",
              value: "equal",
              disabled: false
            },
          ]
        },
        datetime: { 
          options : [
            {
              id : 1,
              label : "greater than equal",
              value: "gte",
              disabled: false
            },
            {
              id : 2,
              label : "lower than equal",
              value: "lte",
              disabled: false
            },
            {
              id : 3,
              label : "equal",
              value: "equal",
              disabled: false
            },
          ]
        },
        boolean: { 
          options : [
            {
              id : 1,
              label : "equal",
              value: "equal",
              disabled: false
            },
          ]
        },
        attachment: {
          options: []
        }
      },
      field: {
        label: 'Select Field',
        id: 'field',
        validations: {
          required: true
        },
        extra: {
          options : []
        },
      },
      operator: {
        label: 'Operator',
        id: 'operator',
        validations: {
          required: true
        }
      },
      value: {
        label: 'Value',
        id: 'value',
        validations: {
          required: true
        },
      },
      fields: [{
        label: 'Select Field',
        class: 's5',
        id: 'field',
        validations: {
          required: true
        },
        extra: {
          options : [
            {
              id : 1,
              label : "Text",
              value: "text",
              disabled: false
            },
            {
              id : 2,
              label : "Number",
              value: "number",
              disabled: false
            },
            {
              id : 3,
              label : "Keyword",
              value: "keyword",
              disabled: false
            },
            {
              id : 4,
              label : "Date",
              value: "date",
              disabled: false
            },
            {
              id : 5,
              label : "Boolean",
              value: "boolean",
              disabled: false
            }
          ]
        },
      },
      {
        label: 'Operator',
        class: 's3',
        id: 'operator',
        validations: {
          required: true
        },
        extra : {
          options : [
            {
              id : 1,
              label : "Is",
              value: "=",
              disabled: false
            },
            {
              id : 2,
              label : "Is Not",
              value: "!=",
              disabled: false
            },
            {
              id : 3,
              label : "In",
              value: "include",
              disabled: false
            },
            {
              id : 4,
              label : "Not In",
              value: "exclude",
              disabled: false
            },
            {
              id : 5,
              label : "Greater than",
              value: ">",
              disabled: false
            },
            {
              id : 6,
              label : "Lower Than",
              value: "<",
              disabled: false
            }
          ]
        },
      },
      {
        label: 'Value',
        class: 's4',
        id: 'value',
        validations: {
          required: true
        },
      }],
      incrementDropdown: 1,
      defaultOpt: [
        {
          id : 100,
          label : "is empty",
          value: "isEmpty",
          disabled: false
        },
        {
          id : 101,
          label : "is not empty",
          value: "isNotEmpty",
          disabled: false
        }
      ]
    }
  },
  watch: {
    modelQueries: {
      handler: function () {
        this.incrementDropdown++
      },
      deep: true
    },
    initQueries: {
      handler: function () {
        this.modelQueries = [...this.initQueries]
      },
      deep: true
    }
  },
  computed: {
    ...mapGettersConfig([
      'getStatuses'
    ]),
    ...mapStateConfig([
      'currentCollection',
    ]),
  },
  methods: {
    generateViewableDatetimeFields(){
      this.modelQueries.forEach(field =>{
        if(this.fieldOptions[field['field']] == 'datetime'){
          field['value'] = transformFromIsoString(field['value'])
        }
      })
    },
    generateDatetimeFields() {
      this.modelQueries.forEach(field => {
        if(this.fieldOptions[field['field']] == 'datetime'){
          field['value'] = transformToIsoString(field['value'])
        }
      })
    },
    getOperatorOptions(fieldName) {
      // make sure assignee field using exact operator.
      // field assignee use email as value.
      // because limition from backend, where text after @ will be read as another word.
      let selectedOptions = {}
      if(fieldName.toLowerCase() == 'assignee'){
        selectedOptions = this.operatorOptions['label']
      }else{
        selectedOptions = this.operatorOptions[this.fieldOptions[fieldName]]
      }

      if(selectedOptions) {
        // add json stringify because duplicated value from refrence variable
        selectedOptions = JSON.parse(JSON.stringify(selectedOptions))
        this.defaultOpt.forEach( el => {
          selectedOptions.options.push(el);
        });
      } else {
        selectedOptions = {}
      }
      
      return selectedOptions
    },
    getStatusOptions(){
      let selectedStatus = {
        options: [] || null
      }

      if(this.currentCollection !== null){
        const field = this.getStatuses(this.currentCollection)
        let i = 1;
        if(field !== null) {
          field.forEach( status => {
            selectedStatus.options.push({
              id: i,
              label: status.name,
              value: status.name,
              disabled: false
            });
            i++;
          })
        }
      }
      return selectedStatus
    },
    addMoreQuery() {
      this.modelQueries.push({field: null, operator: null, value: null})
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    submitQuery() {
      this.v$.$touch()
      if (!this.v$.$error) {
        this.modelQueries.map(({field, operator, value}, index) => {
          // check the value is a date or datetime
          if(this.fieldOptions[field] == 'date' || this.fieldOptions[field] == 'datetime') {
            if(this.fieldOptions[field] == 'datetime') {
              const ISOTime = new Date(value)
              this.modelQueries[index].value = ISOTime.toISOString()
            } else if (this.fieldOptions[field] == 'date') {
              const date = new Date(value)
              const ISOTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
              this.modelQueries[index].value = ISOTime
            }
          }
        })
        this.$emit("applyQuery", [...this.modelQueries]);
      }
    },
    triggerModalFilter() {
      this.$emit("close");
    },
    removeQuery(index) {
      this.modelQueries.splice(index, 1)
    },
    isContainDefaultOpt(operator){
      return (operator != "isNotEmpty") && (operator != "isEmpty")
    },
    isFieldStatus(field){
      return field == 'status'
    },
    isFieldDate(field) {
      return this.fieldOptions[field] == 'date'
    },
    isFieldDateTime(field) {
      return this.fieldOptions[field] == 'datetime'
    },
    isNotSpecialType(field) {
      return this.fieldOptions[field] != 'datetime' && this.fieldOptions[field] != 'date' && field != 'status'
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>