<template>
  <div v-if="isVisible" :class="['file-field', formWidthClass]">
    <label class="label" :for="customId">{{ customLabel }}</label>
    <div class="file-path-wrapper">  
      <div :class="['btn', v$.$errors.length != 0 || errorField ? 'required' : '']">
        <span>File</span>
        <input 
          type="file" 
          :multiple="extra.isMultiple"
          :accept="acceptFormat" 
          :id="customId" 
          :class="[styleClass]"
          :disabled="isDisabled"
          @change="updateData"
        >
      </div>
      <input 
        type="text"  
        v-model="v$.value.$model"
        :class="['file-path', errClass, errorField]" 
        :placeholder="extra.placeholder"
        @change="fileChanged">
    </div>
    <div class="notes-wrapper">
      <span 
        v-for="error of v$.$errors" :key="error.$uid" class="error-text">
        {{error.$message}}
      </span>
      <span v-if="helperText" class="helper-text">{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-input-file',
  setup(props) {
    const computedValue = props.extra.fileNames.join(", ")
    const v$ = setValidations(props.validations, computedValue, props.label)
    return {v$}
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: true,
      default() {
        return { 
          isMultiple: false,
          fileNames: [],
          accept: "image",
        }
      }
    },
    inputValue: {
      type: Object,
      required: false,
      default: {}
    },
    errorField: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    acceptFormat() {
      // return format value for attribute accept.
      // based on value from props
      let format = ""
      switch (this.extra.accept) {
        case "image":
          format = "image/*"
          break
        case  "file":
          format =  "file_extension"
          break
        default:
          format = "image/*"
      }

      return format
    }
  },
  methods: {
    updateData(event) {
      const files = event.target.files
      /** Populating file name after pick file  
       * for assign to v-model **/
      if(files.length >  0) {  
        let fileNameList = Object.values(files).map( file => { 
          return file.name
        })
        this.v$.value.$model = this.extra.fileNames.concat(fileNameList).join(", ")
      }
      this.validateData(files)
    },
    fileChanged(event){
      /** Overwrite file name value when handler 
       * "@change" triggered from File Input Path
       * in default method  materialize **/
      event.target.value =  this.v$.value.$model
    },
    resetData(){
      this.v$.value.$model = ""
      this.validateData(null)
   }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
