import { reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers, email, minLength, maxLength,
         alphaNum, numeric, integer,} from '@vuelidate/validators'


function generateRules(validations, fieldLabel){
  let rules = { value : {} }

  for (const [key, value] of Object.entries(validations)){
    const errMsg =  getCustomErrMsg(fieldLabel, key, value)
    const validationKey = key.toLowerCase()
    let selectedRules = null

    if(validationKey == "required"){
      if(value) selectedRules = required
    }
    if(validationKey == "email"){
      if(value) selectedRules = email
    }
    if(validationKey == "alphanum"){
      if(value) selectedRules = alphaNum
    }
    if(validationKey == "numeric"){
      if(value) selectedRules = numeric
    }
    if(validationKey == "integer"){
      if(value) selectedRules = integer
    }
    if(validationKey == "minlength") selectedRules = minLength(value)
    if(validationKey == "maxlength") selectedRules = maxLength(value)
  
    if (selectedRules != null) 
      rules.value[key] = helpers.withMessage(errMsg, selectedRules)          
  }
  
  return rules
}

function getCustomErrMsg(field, type, value){
  let msg = ""
  const validationType = type.toLowerCase()
  switch (validationType){
    case "required":
      msg = `This ${field} cannot be empty`
      break
    case "minlength":
      msg = `This ${field} should be at least ${value} long`
      break
    case "maxlength":
      msg = `The maximum length allowed is ${value} `
      break
    default:
      msg = `This ${field} must be ${type}`
      break
  }
 return msg

}

function setValidations(validations, inputValue, fieldLabel){
  const state = reactive({ 
    value: inputValue
  })
  const rules = generateRules(validations, fieldLabel) 
  const v$ = useVuelidate(rules, state)

  return v$ 
 }

 export default setValidations
