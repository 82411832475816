<template>
    <oba-modal
      class="modal-edit"
      id="modalEdit" 
      :show="visible" 
      @close="closeModal()"
      title="Edit Member">
      <template v-slot:modal-content>
        <div class="input-modal-add">
          <oba-form-builder ref="formFieldEditModal" :fields="fieldEditModal" :key="formKey" />
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="button-modal-add">
          <oba-button category="secondary" label="Cancel" class="cancel" @click="closeModal"/>
          <oba-button category="primary" 
          label="Edit" 
          :isDisabled="isRequesting" 
          :isLoading="isRequesting"
          @click="submit"/>
        </div>
      </template>
    </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import FormBuilder from '@/components/formBuilder'
import modal from "@/components/modal";
import button from "@/components/button"

import { generateProps } from '@/lib/propsGenerator'
import { showNotification } from '@/lib/utils'
import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'

const {mapState: mapStateConfig } = createNamespacedHelpers('config')

export default {
  name: 'edit-modal',
  emits: ['submit','close'],
  setup(){
    async function editUser(userData){
      const config = {
        url: apiUrls.editUser(this.projectName, this.data.docId),
        method: 'put',
        data : userData
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    const getRole = async (projectName) => {
      const config = {
        url: apiUrls.getRoles(projectName),
        methods: 'get',
      }
      const response = await doRequest(config, { needToken: true })
      return response
    }

    return {
      editUser,
      getRole
    }
  },
  components: {
    'oba-modal': modal,
    'oba-button': button,
    'oba-form-builder': FormBuilder
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
    data: {
      type: Object,
      required: true,
      default: {}
      // menerima Object berbentuk :
      // {
      //   docId: "asdasdadsadsa",
      //   columns: []
      // }
    },
  },
  data() {
    return {
      parentData: {},
      editFieldsUser: [
        {
          "name": "email",
          "type": "text",
          "isDisabled": true,
          "validations": {
            "required": true,
            "email": true
          },
          "inputType": "email",
        },
        {
          "name": "groups",
          "type": "dropdown",
          "validations": {
            "required": true,
          },
          "choices": [
            "admin",
            "regular",
            "staff"
          ]
        },
        {
          "name": "role",
          "type": "dropdown",
          "validations": {
            "required": true,
          },
          "choices": []
        }
      ],
      fieldEditModal: [],
      formKey: 0,
      isRequesting: false
    }
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateFields()
      }
    },
  },
  beforeMount(){
    this.loadRoles()
    this.generateFields()
  },
  computed:{
    ...mapStateConfig([
      'projectName'
    ]),
  },
  methods:{
    generateFields(){
      this.fieldEditModal = generateProps(this.editFieldsUser)
      this.parentData = this.data
      const parentFields = this.parentData.columns

      if(this.fieldEditModal.length != 0){
        this.fieldEditModal.forEach(field => {
          if(parentFields){
            const existing = this.parentData.columns.find(item => item.fieldName == field.label)
            field['inputValue'] = existing.value
          }
        })
      }
      this.formKey++
    },
    closeModal(){
      this.$emit('close')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    collectData(){
      const form = this.$refs['formFieldEditModal']
      let formData = form.collectValue()
      if(!formData) return null
      formData['groups'] = Array(formData['groups'])
      return formData
    },
    async submit(){
      this.setLoadingAndDisable(true)
      const data = this.collectData()
      if(data){
        const response = await this.editUser(data)
        if(response.status >= 200 && response.status <= 299){
          showNotification('success', `Success, Data Changed`)
          this.setLoadingAndDisable(false)
          this.$emit('submit')
        } else {
          showNotification('error', `Error, Failed change data`)
          this.setLoadingAndDisable(false)
          this.$emit('close')
        }
      }
      this.setLoadingAndDisable(false)
    },
    async loadRoles() {
      const response = await this.getRole(this.projectName)
      this.editFieldsUser[2].choices = response.data
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>