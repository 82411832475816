<template>
  <div class="row">
    <div :class="[formWidthClass, styleClass]">
      <template v-for="item in entryList" :key="item.id">
        <component
          :is="getCompName(item.type)"
          :id="item.id"
          :cssClass="item.class"
          :label="transformUpperCase(item.label)"
          :validations="item.validations"
          :type="item.type"
          :extra="item.extra"
          :helperText="item.helperText"
          :errMsg="item.errMsg"
          v-model:visible="item.visible"
          :isDisabled="item.isDisabled"
          v-model:inputValue="item.inputValue"
          :dependency="item.dependency"
          :ref="item.id"
          :displayText="item.displayText"
          class="margin-bottom">
        </component>
      </template>
    </div>
  </div>
</template>

<script>
// components
import Autocomplete from '../form/autocomplete'
import Checkbox from '../form/checkbox'
import DatePicker from '../form/datePicker'
import obaDropdownSearch from '../form/selectSearch'
import InputText from '../form/inputText'
import InputFile from '../form/inputFile'
import RadioButton from '../form/radiobutton'
import TextArea from '../form/textArea'
import Separator from '../form/separator'
import DateTime from '../form/dateTime'

// third party lib
import { useVuelidate } from '@vuelidate/core'
import { transformUpperCase, transformToWkt, transformFromWkt } from '@/lib/transform.js'


export default {
  name: 'oba-form-builder',
  components: {
    'oba-autocomplete': Autocomplete,
    'oba-checkbox': Checkbox,
    'oba-datepicker': DatePicker,
    obaDropdownSearch,
    'oba-input-text': InputText,
    'oba-input-file': InputFile,
    'oba-radio-button': RadioButton,
    'oba-textarea': TextArea,
    'oba-separator': Separator,
    'oba-datetime': DateTime,
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validationConfig: {
    $stopPropagation: true
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    cssClass: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    formWidthClass: {
      type: String,
      required: false,
      default: "col s12"
    },
  },
  mounted(){
    this.entryList.forEach(field => {
      // transform to string long,lat if type geopoint
      if(field.type == 'geopoint'){
        field.inputValue = field.inputValue ? transformFromWkt(field.inputValue, field.type) : field.inputValue
      }
      // display dependency field if inputvalue not empty
      if(field.dependency){
        const fieldComponent = this.$refs[field.dependency.entry][0]
        if(fieldComponent){
          fieldComponent.publishValue(fieldComponent.inputValue, fieldComponent.type)
        }
      }
    })
  },
  data() {
    return  {
      entryList: this.fields ? this.fields : [],
    }
  },
  computed: {
    styleClass() {
      return this.cssClass.join(" ")
    },
  },
  methods: {
    transformUpperCase,
    getCompName(type) {
      // return component name based on type
      // component name defined on component attribute
      // type define on config json
      let componentName = 'oba-input-text'
      switch (type) {
        case "autocomplete":
          componentName = 'oba-autocomplete'
          break
        case "checkbox":
          componentName = 'oba-checkbox'
          break
        case "date":
          componentName = 'oba-datepicker'
          break
        case "datetime":
          componentName = 'oba-datetime'
          break
        case "dropdown":
          componentName = 'oba-dropdown-search'
          break
        case "number":
        case "geopoint":
        case "password":
        case "text":
          componentName = 'oba-input-text'
          break
        case "file":
          componentName = 'oba-input-file'
          break
        case "radio":
          componentName = 'oba-radio-button'
          break
        case "textarea":
          componentName = 'oba-textarea'
          break
        case "separator":
          componentName = 'oba-separator'
          break
        default:
          componentName = 'oba-input-text'
      }
      return componentName
    },
    // Validate using Vuelidate
    validateInput() {
      this.v$.$touch()
      return this.v$.$error
    },
    // Get each value from fields
    collectData() {
      let isError = this.validateInput()
      if(!isError){
        return this.entryList.map(item => {
          return {name: item.label, value: item.inputValue}
        })
      } else {
        return []
      }
    },
    collectValue(){
      let isError = this.validateInput()
      let data = {}
      if(!isError){
        this.entryList.forEach(field => {
          /** only send field with contain value */
          if(field.visible){
            if(field.type.toLowerCase() == 'geopoint'){
              data[field.label] = transformToWkt(field.inputValue, field.type)
            } else if(field.type.toLowerCase() == 'number') {
              if(!field.extra.numberType) data[field.label] = parseFloat(field.inputValue)
              else if(field.extra.numberType.toLowerCase() == 'float') data[field.label] = parseFloat(field.inputValue)
              else if(field.extra.numberType.toLowerCase() == 'long') data[field.label] = parseInt(field.inputValue)
            }
            else {
              data[field.label] = field.inputValue
            }
          } 
        })
        return data
      } else {
        return null
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>