<template>
  <div>
    <div v-if="currentPage == 'login' || currentPage == 'inputproject' || currentPage == 'register'" class="header">
      <img src="@/assets/logo/stacks-logo@2x.png" />
    </div>
    <div v-if="currentPage == 'detailpage'" class="header2">
      <oba-button category="icon" icon="arrow_back" @onClick="back"></oba-button>
    </div>
    <div class="wrapper" @click="onWrapper" v-if="displayDropdown"/>
    <div v-if="header2Pages.includes(currentPage)" class="header2">
      <img class="logo" src="@/assets/logo/stacks-logo@2x.png"/>      
      <div class="dropdown">
        <div class="user-email">{{ email }}</div>
        <img class="account-icon" src="@/assets/icon/user-placeholder.png" @click="triggerDropdown"/>
        <div v-if="displayDropdown" class="dropdown-menu">
          <ul>
            <li @click="changeProject">Change Project</li>
            <li @click="logout">Logout</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObaButton from '../../components/button'
import firebase from "firebase/app"
import "firebase/auth"
import { createNamespacedHelpers } from 'vuex'
const { mapMutations: mapMutationsUser, mapActions: mapActionUser, mapState: mapStateUser } = createNamespacedHelpers('user')
const { mapMutations: mapMutationsConfig, mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-header',
  components: {
    'oba-button': ObaButton,
  },
  data() {
    return {
      displayAccount: false,
      displayDropdown: false,
      header2Pages : ["tickets", "management",  "addons", "importconfig", "importusers", "bulkedit"]
      
    }
  },
  computed: {
    ...mapStateUser([
      'authToken',
      'email'
    ]),
    ...mapStateConfig([
      'projectName'
    ]),
    currentPage() {
      return this.$router.currentRoute.value.name
    }
  },
  methods: {
    ...mapMutationsConfig({
      resetConfig: 'RESET_CONFIG'
    }),
    ...mapMutationsUser([
      'DELETE_PAGE_STATE',
      'CLEAR_USER_LIST',
      'CLEAR_USER_GROUP'
    ]),
    ...mapActionUser({
      resetUser: 'logout'
    }),
    select(collection){
      this.selected = collection.name
      this.$emit('selected', collection.name)
    },
    isSelected(collection){
      return this.selected == collection.name
    },
    changeProject(){
      this.CLEAR_USER_LIST()
      this.CLEAR_USER_GROUP()
      this.DELETE_PAGE_STATE()
      this.resetConfig()
      this.$router.push({name: "inputproject"})
    },
    logout(){
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        this.resetUser()
      }).catch((error) => {
        // An error happened.
        alert(error.message)
      });
    },
    back() {
      this.$router.back()
    },
    triggerDropdown() {
      this.displayDropdown = !this.displayDropdown
    },
    onWrapper() {
      this.displayDropdown = false
    }
  }
}
</script>


<style lang='scss' scoped>
@import './index.scss';
</style>