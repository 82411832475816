<template>
  <div class="bulk-edit-container">
    <div class="form-group">
      <div class="title">
        Import Excel for Bulk Edit
      </div>
      <div class="row">
        <div class="container-form">
          <oba-dropdown-search
            id="select-collection"
            label="Select Collection"
            v-model:inputValue="selectedCollection"
            :extra="listCollection"
            :validations="listCollection.validations" 
            class="dropdown-collection input"
            />
          <input-file 
            :id="containerFieldId" 
            ref="file" 
            :label="field.label" 
            v-model:inputValue="fileExcel" 
            :errorField="errorMessage ? 'invalid' : ''"
            :extra="field.extra" 
            class="input-excel input"
            :validations="field.validations" 
            :helperText="helperText"/>
          <div class="col error-msg input" v-if="errorMessage">{{errorMessage}}</div>
        </div>
      </div>
      <div class="search">
        <oba-button category="primary" @onClick="submit" label="Submit" :isDisabled="isRequesting" :isLoading="isRequesting"/>
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/form/inputFile'
import button from '@/components/button'
import obaDropdownSearch from '@/components/form/selectSearch'

// third party lib
import { doRequest } from '@/lib/request/index.js'
import { showNotification, generateRandomId } from '@/lib/utils.js'
import apiUrls from '@/lib/request/urls.js'
import { createNamespacedHelpers } from 'vuex'
// third party lib
import { useVuelidate } from '@vuelidate/core'
import Excel from 'exceljs'

const { mapState: mapStateConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')

export default {
  name: 'import-excel',
  components: {
    InputFile,
    'oba-button': button,
    obaDropdownSearch
  },
  setup() {
    const v$ = useVuelidate()

    /**
     * Call api request to bulk edit server
     * @param {*} projectName 
     * "projectName" is the name of the project that you want to bulk edit
     * @param {*} collection 
     * "collection" is the name of the collection that you want to bulk edit
     * @param {*} data 
     * "data" is the data that you want to bulk edit
     */
    const bulkEdit = async (projectName, collection, data) => {
      const config = {
        url: apiUrls.bulkEdit(projectName, collection),
        method: 'post',
        data : data
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return { v$, bulkEdit }
  },
  data() {
    return  {
      data: null,
      fileExcel: null,
      isRequesting: false,
      field: {
        label: 'Select File',
        id: 'file',
        validations: {
          required: true
        },
        extra: {
          isMultiple: false,
          fileNames: [],
          accept: "file",
        },
      },
      helperText: '',
      containerFieldId: null,
      errorMessage: '',
      selectedCollection: '',
      listCollection: {
        options: [],
        validations: {
          required: true
        }
      },
      dropdownIncrement: 0,
    }
  },
  beforeMount(){
    this.generateFieldId()
    this.generateCollections()
  },
  watch: {
    fileExcel: {
      handler: function () {
        this.getTotalRows()
        this.errorMessage = ''
      },
    }
  },
  computed: {
    ...mapStateConfig([
      'collections',
      'projectName',
      'currentCollection'
    ]),
    ...mapStateUser([
      'pageState'
    ]),
  },
  methods: {
    /**
     * Import excel file to bulk edit server
     * @returns {Promise} response
     */
    async importExcel() {
      this.isRequesting = true
      let fd = new FormData();
      fd.append('file',this.fileExcel[0])
      const response = await this.bulkEdit(this.projectName, this.selectedCollection, fd)
      this.isRequesting = false
      return response
    },
    /**
     * Submit the form to import excel file
     * @returns {Promise} response
     */
    async submit() {
      this.v$.$touch()
      if (!this.v$.$error) {
        let response = await this.importExcel()
        if(response.status == 200){
          showNotification('success', `Edit documents success`)
          this.currentCollection = this.selectedCollection
          this.pageState.collection = this.selectedCollection
          this.$router.push({ name: "tickets" })
        } else {
          this.errorMessage = response.data
          this.helperText = ''
        }
      }
    },
    /**
     * Get workbook from file
     * @param {*} file 
     * "file" is the excel file that you want to get the workbook
     * @returns {Promise} workbook
     */
    async getWorkbookFromFile(file) {
      const wb = new Excel.Workbook();
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const buffer = reader.result;
          const workbook = wb.xlsx.load(buffer)
          resolve(workbook);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },
    /**
     * Get total rows from excel file
     */
    async getTotalRows() {
      if (this.fileExcel) {
        const workbook = await this.getWorkbookFromFile(this.fileExcel[0])
        const worksheet = workbook.worksheets[0];
        let totalData = worksheet.rowCount - 1
        this.helperText = `Upload ${totalData} data from file`
      }
    },
    generateFieldId(){
      const newId = generateRandomId()
      this.containerFieldId = `excel-${newId}`
    },
    /**
     * Generate collections for dropdown
     * @returns {Array} options
     */
    generateCollections(){
      let counter = 1
      this.listCollection.options = this.collections.map(item => {
        const choice = {
          id: counter,
          label: item.displayText ? item.displayText : item.name,
          value: item.name,
          disabled: false,
        }
        counter++
        return choice
      })
      this.dropdownIncrement++
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>