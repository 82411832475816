<template>
  <oba-modal
      class="new-layout"
      id="newLayout" 
      :show="visible" 
      @close="closeModal()"
      :selectedScreen="selectedScreen"
      @changePage="changePage"
      title="New Layout">
      <template v-slot:modal-content>
        <div class="detail-info" v-if="selectedScreen == 'detail'">
          <oba-form-builder :key="formKeyLayout" ref="formCreateLayout" :fields="fieldsCreateLayout" />
        </div>
        <div class="customize-layout" v-if="selectedScreen == 'customize'">
          <div class="side-menu">
            <div :class="[selectedMenuImage, 'menu-image']" @click="selectMenu('images')">
              <i class="material-icons icon-menu-image">image</i>
              <span class="image-description">Images</span>
            </div>
            <div :class="[selectedMenuText, 'menu-text']" @click="selectMenu('text')">
              <i class="material-icons icon-menu-text">format_color_text</i>
              <span class="text-description">Text</span>
            </div>
            <div class="menu-help" @click="selectMenu('help')">
              <i class="material-icons icon-menu-help">help</i>
              <span class="text-description">Help</span>
            </div>
            <div class="dropdown-image-sub-menu">
              <div v-if="displaySubmenuImages" class="images-sub-menu">
                <ul>
                  <li @click="uploadImage">Upload images</li>
                  <li class="separator"></li>
                  <li @click="imageFromField">Image from field</li>
                </ul>
              </div>
            </div>
            <div class="dropdown-text-sub-menu">
              <div v-if="displaySubmenuText" class="text-sub-menu">
                <ul>
                  <li @click="addFreeText">Free text</li>
                  <li class="separator"></li>
                  <li @click="addTextFromFields">Text from field</li>
                </ul>
              </div>
            </div>
            <div :class="['dropdown-help-sub-menu', expandDesccription]">
              <div v-if="displaySubmenuHelp" class="help-sub-menu">
                <ul>
                  <li class="help-title">Help & Support</li>
                  <li>
                    <span>Add Images</span>
                    <span class="material-icons expand-images-desc" @click="showImageDesc">{{expandImage}}</span>
                  </li>
                  <li class="separator"></li>
                  <li class="image-description" v-if="imageDescriptionShow">
                    <p>You can add image using 2 method, the first one you can add from a <strong>Local file</strong> 
                    and the second one you can get <strong>images from field</strong> on your Stacks Project</p>
                  </li>
                  <li>
                    <span>Add Text</span>
                    <span class="material-icons expand-text-desc" @click="showTextDesc">{{expandText}}</span>
                  </li>
                  <li class="separator"></li>
                  <li class="text-description" v-if="textDescriptionShow">
                    <p>You can add Text using 2 method, the first one you can add <strong>free text</strong> 
                    and the second one you can get text <strong>from field</strong> on your Stacks Project</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="wrapper-right">
            <div class="page-properties" v-if="isPageSelected">
              <span>Properties</span>
              <span class="menu-duplicate">
                <oba-tooltip :text="`Duplicate Page`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="file_copy" @click="$refs.canvas.duplicatePage()"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="menu-delete-page">
                <oba-tooltip :text="`Delete Page`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="delete"  @click="$refs.canvas.removePage()"/>
                  </template>
                </oba-tooltip>
              </span>
            </div>
            <div class="shape-properties" v-if="isShapeSelected">
              <span>Properties</span>
              <span class="select-field" v-if="isImageFromField">
                <oba-dropdown-search
                  id="fieldList"
                  :key="attachmentListIncrement"
                  label=""
                  v-model:inputValue="selectFields"
                  @updated="setImageFieldName"
                  :extra="attachmentListDropdown.extra"
                  class="dropdown-search"/>
              </span>
              <span class="upload-image" v-if="isImageUpload">
                <oba-attachment
                  id="upload-image"
                  label=""
                  v-model:inputValue="selectImage"
                  :extra="imageExtra"
                  @updated="setImage"
                />
              </span>
              <span class="duplicate-shape">
                <oba-tooltip :text="`Duplicate Shape`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="file_copy" @click="$refs.canvas.duplicate(null)"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="delete-shape">
                <oba-tooltip :text="`Delete Shape`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="delete" @click="$refs.canvas.remove()"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="separator"></span>
              <span class="ratio-desc">Aspect Ratio</span>
              <span class="potrait">
                <oba-tooltip :text="`Potrait`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="crop_portrait" @click="$refs.canvas.transRect('portrait')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="landscape">
                <oba-tooltip :text="`Landscape`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="crop_16_9" @click="$refs.canvas.transRect('landscape')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="square">
                <oba-tooltip :text="`Square`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="crop_square" @click="$refs.canvas.transRect('square')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="free-crop">
                <oba-tooltip :text="`Free Crop`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="crop_free" @click="$refs.canvas.transRect('free')"/>
                  </template>
                </oba-tooltip>
              </span>
            </div>
            <div class="text-properties" v-if="isTextSelected">
              <span>Properties</span>
              <span class="select-field" v-if="isTextFromField">
                <oba-dropdown-search
                  id="fieldList"
                  :key="textListIncrement"
                  label=""
                  v-model:inputValue="selectFields"
                  @updated="setTextFieldName"
                  :extra="textListDropdown.extra"
                  class="dropdown-search"/>
              </span>
              <span class="duplicate-text">
                <oba-tooltip :text="`Duplicate Text`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="file_copy" @click="$refs.canvas.duplicate(null)"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="delete-text">
                <oba-tooltip :text="`Delete Text`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="delete" @click="$refs.canvas.remove()"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="separator"></span>
              <span>Text Style</span>
              <span class="text-style">
                <oba-tooltip :text="`Bold`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="format_bold" @click="$refs.canvas.bold()"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="text-increase">
                <oba-tooltip :text="`Text Increase`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="text_increase" @click="$refs.canvas.fontsize('+')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="text-decrease">
                <oba-tooltip :text="`Text Decrease`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="text_decrease" @click="$refs.canvas.fontsize('-')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="separator"></span>
              <span>Text Align</span>
              <span class="align-left">
                <oba-tooltip :text="`Align Left`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="format_align_left" @click="$refs.canvas.align('left')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="align-center">
                <oba-tooltip :text="`Align Center`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="format_align_center" @click="$refs.canvas.align('center')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="align-right">
                <oba-tooltip :text="`Align Right`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="format_align_right" @click="$refs.canvas.align('right')"/>
                  </template>
                </oba-tooltip>
              </span>
              <span class="align-justify">
                <oba-tooltip :text="`Justify`" :position="`bottom`">
                  <template v-slot:content>
                    <oba-button category="icon" icon="format_align_justify" @click="$refs.canvas.align('justify')"/>
                  </template>
                </oba-tooltip>
              </span>
            </div>
            <div class="canvas-wrapper">
              <oba-canvas 
                ref="canvas" 
                id="oba-canvas-1"
                :selectedCollection="selectedCollection"
                @updateSelectedShape="getSelectedShape" />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="buttons">
          <oba-button category="secondary" label="Cancel" class="cancel" @click="closeModal"/>
          <oba-button category="primary" label="Next" class="next" :isDisabled="isRequesting" :isLoading="isRequesting" v-if="selectedScreen == 'detail'" @click="next"/>
          <oba-button category="primary" label="Save" class="next" :isDisabled="isRequesting" :isLoading="isRequesting" v-if="selectedScreen == 'customize'" @click="submitConfig"/>
        </div>
      </template>
    </oba-modal>
  
</template>
<script>
import button from "@/components/button"
import { doRequest } from '@/lib/request'
import { apiUrls } from '@/lib/request/urls'
import { showNotification } from '@/lib/utils'
import { createNamespacedHelpers } from "vuex"
import modalLayout from "@/components/configLayout"
import { generateProps } from '@/lib/propsGenerator'

import tooltip from '@/components/tooltip'
import FormBuilder from '@/components/formBuilder'
import canvas from '@/views/canvas'
import attachment from '@/components/form/inputFileV2'
import obaDropdownSearch from '@/components/form/selectSearch'

const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers("config")

export default {
  name: 'stacks-new-layout',
  emits: ["onCreate","close"],
  setup(){
      async function addConfig(projectName,data) {
        const config = {
          url: apiUrls.addBastConfig(projectName),
          method: "post",
          data: data
        };
        const response = await doRequest(config, { needToken: true });
        return response
      }

      async function uploadImageToStorage(projectName, collection, formData){
        const config = {
          url: apiUrls.uploadImage(projectName, collection),
          method: 'post',
          headers: { "Content-Type": "multipart/form-data" },
          data : formData
        }
        const response = await doRequest(config, {needToken: true})
        return response
      }


    return {
      addConfig,
      uploadImageToStorage,
    }
  },
  components: {
    'oba-modal': modalLayout,
    'oba-button': button,
    'oba-form-builder': FormBuilder,
    'oba-tooltip': tooltip,
    'oba-canvas': canvas,
    'oba-attachment': attachment,
    obaDropdownSearch,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
  },
  data(){
    return {
      selectedCollection: '',
      isRequesting: false,
      selectedScreen: 'detail',
      fieldsCreateLayout: [],
      formKeyLayout: 0,
      isPageSelected: false,
      isShapeSelected: false,
      isTextSelected: false,
      attachmentListIncrement: 0,
      textListIncrement: 0,
      selectFields: "",
      attachmentListDropdown: {
        extra:{
          options:[]
        }
      },
      textListDropdown: {
        extra:{
          options:[]
        }
      },
      selectedCustomizeMenu: '',
      displaySubmenuImages: false,
      displaySubmenuText: false,
      displaySubmenuHelp: false,
      detailData: null,
      imageExtra: { 
        isMultiple: false,
        fileNames: [],
        accept: "image",
        placeholder: "Get image"
      },
      selectImage: {},
      isImageUpload: false,
      isImageFromField: false,
      isFreeText: false,
      isTextFromField: false,
      listSavedImages: {},
      incrementNewLayout: 0,
      expandImage: 'expand_more',
      expandText: 'expand_more',
      imageDescriptionShow: false,
      textDescriptionShow: false,
    }
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateFieldsCreateLayout()
        this.generateListAttachmentField()
        this.generateListTextField()
        this.selectedScreen = 'detail'
      }
    }
  },
  beforeMount(){
    this.generateFieldsCreateLayout()
    this.generateListAttachmentField()
    this.generateListTextField()
  },
  computed: {
    ...mapStateConfig([
      'projectName',
      'collections',
    ]),
    ...mapGettersConfig([
      'getCollectionEntries',
    ]),
    selectedMenuImage(){
      if(this.displaySubmenuImages){
        return 'selected-menu'
      }
      return ''
    },
    selectedMenuText(){
      if(this.displaySubmenuText){
        return 'selected-menu'
      }
      return ''
    },
    expandDesccription(){
      if(this.imageDescriptionShow && this.textDescriptionShow){
        return 'expand-two'
      } else if (this.imageDescriptionShow || this.textDescriptionShow) {
        return 'expand-one'
      }
      return ''
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    selectMenu(value){
      this.selectedCustomizeMenu = value
      if(value == 'images'){
        this.displaySubmenuImages = !this.displaySubmenuImages
        this.displaySubmenuText = false
        this.displaySubmenuHelp = false
      } else if (value == 'text'){
        this.displaySubmenuText = !this.displaySubmenuText
        this.displaySubmenuImages = false
        this.displaySubmenuHelp = false
      } else if(value == 'help'){
        this.displaySubmenuHelp = !this.displaySubmenuHelp
        this.displaySubmenuImages = false
        this.displaySubmenuText = false
      }
    },
    next(){
      this.detailData = this.collectData()
      if(this.detailData){
        this.selectedScreen = 'customize'
        this.selectedCollection = this.detailData.field['select collection']
        this.generateListAttachmentField()
        this.generateListTextField()
      }
    },
    changePage(value){
      if(this.detailData){
        this.selectedScreen = value
      }
    },
    async generateFieldsCreateLayout(){
      const field = [
        {
          name: 'layout name',
          type: 'text',
          validations: { required: true }
        },
        {
          name: 'select collection',
          type: 'dropdown',
          validations: { required: true},
          choices: this.collections.map(item => {
            return item.name
          })
        }
      ]
      let fieldsCreate = await generateProps(field)
      this.fieldsCreateLayout = fieldsCreate
      this.formKeyLayout++
    },
    generateListAttachmentField(){
      let options = []
      this.getCollectionEntries(this.selectedCollection).forEach((item,index) =>{
        if(item.type == 'file' || item.type == 'attachment'){
          options.push(
            {
              "id": index,
              "label": item.displayText ? item.displayText : item.name,
              "value": item.name,
              "disable": false
            }
          ) 
        }
      })
      this.attachmentListDropdown.extra.options = options
      this.attachmentListIncrement ++
    },
    generateListTextField(){
      let options = []
      this.getCollectionEntries(this.selectedCollection).forEach((item,index) =>{
        if(item.type != 'file' && item.type != 'attachment'){
          options.push(
            {
              "id": index,
              "label": item.displayText ? item.displayText : item.name,
              "value": item.name,
              "disable": false
            }
          ) 
        }
      })
      this.textListDropdown.extra.options = options
      this.attachmentListIncrement ++
    },
    collectData(){
      let data  = {}
      let fieldData =  {}
      const form = this.$refs['formCreateLayout']
      const rawData = form.collectValue()
    
      if(!rawData) return null

      /**separate data that contains attachments and does not contain*/
      for (const [key, value] of Object.entries(rawData)){
        fieldData[key]  =  value
      }
      
      data["field"] = fieldData
      return data
    },
    uploadImage(){
      this.displaySubmenuImages = false
      this.selectedCustomizeMenu = ''
      this.$refs.canvas.addShape('Group')
    },
    imageFromField(){
      this.displaySubmenuImages = false
      this.selectedCustomizeMenu = ''
      this.$refs.canvas.addShape('Group', true)
    },
    addFreeText(){
      this.displaySubmenuText = false
      this.selectedCustomizeMenu = ''
      this.$refs.canvas.addShape('Text')
    },
    addTextFromFields(){
      this.displaySubmenuText = false
      this.selectedCustomizeMenu = ''
      this.$refs.canvas.addShape('Text', true)
    },
    setImage(value){
      this.$refs.canvas.uploadImage(value)
    },
    setTextFieldName(value){
      this.$refs.canvas.setTextFieldName(value)
    },
    setImageFieldName(value){
      this.$refs.canvas.setImageFieldName(value)
    },
    async uploadImages(projectName, collection, images){
      for (var item of images){
        let formData = new FormData()
        formData.append(item.id,item[item.id])
        const response = await this.uploadImageToStorage(projectName,collection,formData)
        if(response.status >= 200 && response.status <= 299){
          this.listSavedImages[item.id] = response.data[item.id]
        }
      }

    },
    async submitConfig(){
      this.isRequesting = true
      let savedImage = this.$refs.canvas.getListImage()
      if(savedImage.length !=0){
        await this.uploadImages(this.projectName, this.selectedCollection, savedImage)
      }
      // get converted Data
      const data = this.$refs.canvas.convConfBast(this.listSavedImages, this.projectName, this.detailData)
      if(data){
        const response = await this.addConfig(this.projectName,data)
        if(response.status >= 200 && response.status <= 299){
          showNotification('success', `Success, config layout bast created`)
          this.isRequesting = false
          this.$emit("onCreate")
          this.closeModal()
        } else {
          showNotification('error', `Error, Failed to create config layout bast`)
        }
      }
      this.isRequesting = false
    },
    getSelectedShape(value){
      if(value){
        if(value.attrs.text){
          if(value.attrs.fromField){
            this.isTextFromField = true
            this.selectFields = value.attrs.fieldName
            this.textListIncrement++
          } else {
            this.isTextFromField = false
          }
          this.isTextSelected = true
          this.isPageSelected = false
          this.isShapeSelected = false
          this.isImageUpload = false
        } else if (value.attrs.ratioType){
          if(value.attrs.fromField){
            this.selectFields = value.attrs.fieldName
            this.isImageFromField = true
            this.isImageUpload = false
            this.attachmentListIncrement ++
          } else {
            this.isImageFromField = false
            this.isImageUpload = true
          }
          this.isShapeSelected = true
          this.isPageSelected = false
          this.isTextSelected = false
        }
      } else {
        this.isImageUpload = false
        this.isTextFromField = false
        this.isImageFromField = false
        this.isShapeSelected = false
        this.isTextSelected = false
        this.isPageSelected = true
      }
    },
    showImageDesc(){
      this.imageDescriptionShow = !this.imageDescriptionShow
      if(this.imageDescriptionShow){
        this.expandImage = 'expand_less'
      } else {
        this.expandImage = 'expand_more'
      }
    },
    showTextDesc(){
      this.textDescriptionShow = !this.textDescriptionShow
      if(this.textDescriptionShow){
        this.expandText = 'expand_less'
      } else {
        this.expandText = 'expand_more'
      }
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>