<template>
    <oba-modal 
      class="modal-info" 
      id="modalInfo" 
      :show="visible" 
      type="medium-form"
      title="Info"
      @close="closeModal">
      <template v-slot:modal-content>
        <div v-for="(field, index) in fields" :key="index" class="info">
          <div class="field">{{transformUpperCase(field.fieldName)}}</div>
          <div class="field value">{{field.fieldValue}}</div>
        </div>
      </template>
      <template v-slot:modal-footer>
      </template>
    </oba-modal>
</template>

<script>
import ObaModal from '@/components/modal'
import { transformUpperCase } from "@/lib/transform.js"

export default {
  name: 'delete-modal',
  components: {
    'oba-modal': ObaModal,
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
    data: {
      type: Object,
      required: true,
      default: {}
    },
    collection: {
      type: String,
      required: true,
      default: 'queue'
    },
  },
  data() {
    return {
      fields: []
    }
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateFieldsInfo()
      }
    }
  },
  methods:{
    transformUpperCase,
    generateFieldsInfo(){
      let tempData = []
      for (const [key, value] of Object.entries(this.data)){  
        const field = {
          fieldName: key,
          fieldValue: value
        }
        // remove docId for info Modal data
        if(key != "docId") tempData.push(field)
      }
      this.fields = tempData
    },
    closeModal(){
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>