<template>
  <!-- Modal Structure -->
  <div :id="id" class="modal custom-modal" @keydown.esc="close" >
    <div class="modal-wrapper" @click.stop="onWrapper">
      <div :class="['content-wrapper', modalType]">
        <div class="modal-header">
          <p>{{title}}</p>
          <span class="btn-close">
            <oba-button category="icon" icon="close" @onClick="close"></oba-button>
          </span>
        </div>
        <div class="modal-content">
          <div class="content">
            <slot name="modal-content"></slot>
          </div>
        </div>
        <div class="modal-footer">
          <div class="content">
            <slot name="modal-footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObaButton from '@/components/button'

export default {
  name: 'oba-modal',
  components: {
    'oba-button': ObaButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    contents: {
      type: Object,
      required: false,
      default: {}
    },
    type:{
      /**current type modal : full, medium**/
      type: String,
      required: false,
      default: 'full'
    },
    title: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      modalInstance: null
    }
  },
  watch: {
    modalInstance(val, oldValue) {
      if (val) {
        if (this.show) {
          this.modalInstance.open()
        }
      }
    },
    show(val, oldValue) {
      if (val) {
        this.modalInstance.open()
      } else {
        this.modalInstance.close()
      }
    }
  },
  computed:{
    // modal types
    modalType(){
      let classType = 'full-modal' 
      if(this.type == 'medium'){
        classType = 'medium-modal'
      } else if (this.type == 'medium-form'){
        classType = 'medium-form-modal'
      } else if (this.type == 'bast'){
        classType = 'bast-modal'
      } else if (this.type == 'bulk-delete'){
        classType = 'bulk-delete'
      }
      return classType
    }
  },
  beforeMount() {
    this.$nextTick(function() {
      const elm = document.querySelector(`#${this.id}`)
      const option = {
        opacity: 0.7,
        endingTop: '0',
      }
      this.modalInstance = M.Modal.init(elm, option)
    })
  },
  methods: {
    close() {
      // parent must update the value of show props to false
      // to close the modal
      this.$emit('close', false)
    },
    onWrapper(event){
      if((this.type == "medium" || this.type == "medium-form") && event.target.classList.contains("modal-wrapper")) this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>