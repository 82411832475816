<template>
  <div class="wrapper">
    <template v-if="!isLoadingEmail">
      <div class="form-container">
        <div v-if="invalidAuth" class="error">
          <img class="icon" src="@/assets/icon/adx-ic-warning.svg" alt="">
          <span class="text">{{invalidMessage}}</span>
        </div>
        <div class="form">
          <div class="label">Register</div>
          <oba-form-builder ref="formRegister" :fields="fields" :key="formKey"/>
          <div class="submit">
            <oba-button label="Register" category="primary" icon="lock_open" :isDisabled="isDisabled" :isLoading="isLoading" @onClick="register" size="large" class="register-button"/>
          </div>
          <div class="login">
            <span>Already have account ? </span>
            <span class="login-text" @click="toLogin">Login now</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <oba-loading width="480px" />
      </div>
    </template>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/auth"
import { createNamespacedHelpers } from 'vuex'

import FormBuilder from '@/components/formBuilder'
import Button from '@/components/button'
import loading from '@/components/loading'

import { generateProps } from '@/lib/propsGenerator'
import { config } from '@/lib/config.js'
import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'

const { mapMutations: mapMutationUser } = createNamespacedHelpers('user')

export default {
  name: 'oba-register',
  setup(){
    async function getEmail(projectName, docId){
      const config = {
        url: apiUrls.getUserById(projectName, docId),
        method: 'get',
        data: {
          docId: docId
        }
      }
      const response = await doRequest(config)
      return response
    }
    return { getEmail }
  },
  components: {
    'oba-form-builder': FormBuilder,
    'oba-button': Button,
    'oba-loading': loading,
  },
  data() {
    return {
      formKey: 0,
      invalidAuth: false,
      isLoading: false,
      isDisabled: false,
      invalidMessage: "",
      invitation: {},
      next: "acceptinvitation",
      defaultQueries: ["docId", "projectName"],
      isLoadingEmail: false,
      fields: [],
      data: [
        {
          "name": "email",
          "type": "text",
          "validations": {
            "required": true,
            "email": true
          },
          "inputType": "email"
        },
        {
          "name": "password",
          "type": "password",
          "placeholder": "Input password",
          "validations": {
            "required": true,
          },
          "icon": {
            name: "visibility",
            position: "right",
            function: (value, _this) => { 
              if (_this.extra.icon.name == "visibility") {
                _this.extra.icon.name = "visibility_off"
                _this.extra.inputType = "text"
              } else {
                _this.extra.icon.name = "visibility"
                _this.extra.inputType = "password"
              }
            },
          },
        }
      ]
    }
  },
  beforeMount() {
    const query = this.$route.query
    for (let key of this.defaultQueries) {
      if(query[key] == undefined) this.$router.push({name: "login"})
      let keyName = key
      this.invitation[keyName] = query[key]
    }
    this.loadEmail()
  },
  methods: {
    ...mapMutationUser({
      saveUserInfo: 'SAVE_USER_INFO'
    }),
    setLoadingAndDisable(state) {
      this.isLoading = state
      this.isDisabled = state
    },
    async loadEmail() {
      this.isLoadingEmail = true
      const response = await this.getEmail(this.invitation.projectName, this.invitation.docId)
      if(response.status == 200){
        this.data[0]['inputValue'] = response.data.email
        this.data[0]['isDisabled'] = true
        this.fields = generateProps(this.data)
        this.isLoadingEmail = false
      } else {
        this.isLoadingEmail = false
      }
      this.isLoadingEmail = false
    },
    register() {
      this.setLoadingAndDisable(true)
      const form = this.$refs['formRegister']
      const dataForm = form.collectData()
      if (dataForm.length == 0) {
        this.setLoadingAndDisable(false)
        return
      }
      const email = dataForm[0].value.toLowerCase()
      const password = dataForm[1].value
      // use emulator if environtment is development
      if(config.environtment == "development") {
        firebase.auth().useEmulator('http://localhost:9099')
      }
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        this.invalidAuth = false
        const user = userCredential.user
        const userInfo = user.toJSON()
        const idToken = await user.getIdToken()
        this.saveUserInfo({ email: userInfo.email, token: idToken, uid: userInfo.uid })
        this.setLoadingAndDisable(false)
        this.invitation['projectName'] = this.invitation.projectName
        this.$router.push({name: this.next, query: this.invitation})
      }).catch((error)=>{
        this.setLoadingAndDisable(false)
        this.invalidAuth = true
        this.invalidMessage = error.message
      })
    },
    toLogin() {
      let queries = { next: this.next, ...this.invitation }
      this.$router.push({name: "login", query: queries})
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style> 
