<template>
  <div class="input-project">
    <div class="input-project__form">
      <div class="input-project__form__wrapper">
        <div class="title"><span>Select Project</span></div>
        <div v-if="isLoadingProject" class="preloader-wrapper small active">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <div v-else class="input">
          <oba-dropdown-search
          id="projectList"
          :key="projectIncrement"
          v-model:inputValue="selectProject"
          :isDisabled="isDisabled"
          label="Project name"
          :extra="projectListDropdown.extra"
          class="input-field"
          ></oba-dropdown-search>
          <span class="error-msg">{{ errMsg }}</span>
        </div>
      </div>
      <div class="submit-btn">
          <oba-button
            label="Open"
            category="primary"
            size="large"
            :isDisabled="isDisabledSubmit"
            :isLoading="isLoading"
            @onClick="downloadConfig"
          >
          </oba-button>
        </div>
        <div class="logout-wrapper">Want to change account? <span class="logout" @click="logout">Change account</span></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { doRequest, errorMessage } from "@/lib/request"
import { apiUrls } from "@/lib/request/urls"
import { saveConfig, underscoreRemover } from "@/lib/transform"

import InputText from "@/components/form/inputText"
import obaDropdownSearch from '@/components/form/selectSearch'
import Button from "@/components/button"

import {config as setting} from "../../lib/config.js"
import { showNotification } from '@/lib/utils'

import firebase from "firebase/app"
import "firebase/auth"

const { mapActions: mapActionUser } = createNamespacedHelpers('user')


export default {
  name: "oba-input-project",
  components: {
    "oba-input-text": InputText,
    "oba-button": Button,
    obaDropdownSearch
  },
  setup() {
    const requestProjectList = async () => {
      // removing page_size = 100 to get all projects
      const config = {
        // url: "http://localhost:8001/api/project/myprojects/",
        // url: "https:metastacks-staging.web.app/api/project/myprojects",
        baseURL: setting.metaURL,
        url: "/project/myprojects/v2",
        method: 'get',
      }
      return await doRequest(config, {needToken: true})
    }

    const requestConfig = async (projectName) => {
      const config = {
        url: apiUrls.downloadConfigYaml(projectName),
        method: "get",
      }
      return await doRequest(config, { needToken: true })
    }

    

    return { requestConfig, requestProjectList }
  },
  data() {
    return {
      isLoadingProject: true,
      validations: {
        required: true,
      },
      inputValue: "",
      errMsg: null,
      isLoading: false,
      isDisabled: false,
      isDisabledSubmit: true,
      projectListDropdown:{
        extra:{
          options:[]
        }
      },
      selectProject: "",
      projectIncrement: 0,
      selectProjectDisplay: {}
    }
  },
  async beforeMount() {
    this.isLoadingProject = true
    this.isDisabled = true
    const response = await this.requestProjectList()
    if (response.status == 200) {
      this.projectListDropdown.extra.options = response.data.map((item, index) => {
        return {
          "id": index,
          "label": item.displayText,
          "value": item.name,
          "disable": false
        }
      })
    } else {
      showNotification('error', `Failed to fetch projects. ${response.statusText} (${response.status})`)
    }
    this.isLoadingProject = false
    this.isDisabled = false
  },
  watch:{
    'selectProject': function(newValue, oldValue){
      this.selectProjectDisplay = this.projectListDropdown.extra.options.find(item => {
        return item.value == newValue
      })
      this.incrementKey(newValue, oldValue)
      if(newValue == ""){
        this.isDisabledSubmit = true
      } else this.isDisabledSubmit = false
    },
    'projectListDropdown.extra.options': function(newValue, oldValue){
      this.incrementKey(newValue, oldValue)
    }
  },
  methods: {
    generateField(){
      this.fieldProjectList = generateProps(this.projectListField)
      this.formKey++
    },
    ...mapActionUser({
      resetUser: 'logout'
    }),
    collectData(){
      const form = this.$refs['formProjectList']
      const formData = form.collectValue()
    
      if(!formData) return null

      return formData[""]
    },
    async downloadConfig() {
      this.isLoading = true
      this.isDisabled = true
      this.isDisabledSubmit = true
      const projectName = this.selectProject.toLowerCase()
      const response = await this.requestConfig(projectName)
      if (response.status == 200) {
        const collections = response.data.collections.map(collection => collection.name)
        saveConfig(response.data, this.selectProjectDisplay)
        
        this.isLoading = false
        this.isDisabled = false
        this.isDisabledSubmit = false
        this.$router.push({ name: "tickets" })
      } else {
        this.isLoading = false
        this.isDisabled = false
        this.isDisabledSubmit = false
        showNotification('error', `${response.data}`)
      }
    },
    incrementKey(newValue, oldValue){
      if(newValue != oldValue) this.projectIncrement++
    },
    logout(){
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        this.resetUser()
      }).catch((error) => {
        // An error happened.
        alert(error.message)
      });
    }
  },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>