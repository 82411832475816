<template>
<div class="wrapper">
  <img src="@/assets/emptyState/empty-state.png">
  <div class="message">Nothing to show right now!</div>
</div>
</template>

<script>
export default {
  name: 'oba-empty-state',
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>