<template>
  <div v-if="isVisible" :class="['input-field', formWidthClass]">
    <!-- container for datepicker -->
    <div :id="containerDateId"></div>
    <label class="label-date" :for="customId">{{ customLabel }}</label>
    <input 
      type="text" 
      :id="customId"
      :class="[styleClass, errClass, 'datepicker']" 
      v-model="v$.value.$model"
      :disabled="isDisabled"
      :placeholder="extra.placeholder"
      autocomplete="off"
    />
    <span 
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <div v-if='!!helperText'>
      <span class="helper-text" :data-error="errorMsg">{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { generateRandomId } from '@/lib/utils'
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-datepicker',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default() {
        return {
          // add format props base on materialize format doc.
          format : 'dd mmm yyyy',
          placeholder: "dd/mmm/yyyy"
        }
      }
    }
  },
  data() {
    return {
      defaultFormat:  "dd mmm yyyy",
      containerDateId : ''
    }
  },
  beforeMount(){
    this.generateDateId()
    this.initComponent() 
  },
  renderTriggered({key}){
    if(key == "isVisible") this.initComponent() 
  },
  methods: {
    initComponent(){
      this.$nextTick(function()  {
        const elem = document.querySelector(`#${this.customId}`);
        let formatDate = ""
        if(this.extra) {
          formatDate = this.extra.format ? this.extra.format : this.defaultFormat
        }
      
        const container = document.querySelector(`#${this.containerDateId}`)
        
        let options = {
          autoClose: false,
          format: formatDate,
          onSelect: this.updateData,
          showClearBtn: true,
          container: container,
        }; 
        M.Datepicker.init(elem, options);

        const clearBtn = document.querySelector(`#${this.containerDateId} .datepicker-clear`)
        if(clearBtn)clearBtn.addEventListener("click", this.resetData)
      })
    },
    updateData(value){
      let format = this.defaultFormat
      if(this.extra) {
        format = this.extra.format ? this.extra.format : this.defaultFormat
      }
      /*  Upper case format for transforming materialize format
        to moment's format */
      const formatedDate = moment(value).format(format.toUpperCase())
      this.v$.value.$model = formatedDate
      this.validateData(this.v$.value.$model)
    },
    generateDateId(){
      const newId = generateRandomId()
      this.containerDateId = `date-${newId}`
    },
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>