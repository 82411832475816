<template>
  <div class="container-invitation">
    <div v-if="isLoading" class="loading">
      <oba-loading width="480px" />
    </div>
    <div v-if="invalidAuth || invalidProject">
      <img class="error-state" src="@/assets/emptyState/error-state.png"/>
      <h4>Sorry, something went wrong</h4>
      <p>We’re working on getting this fixed as soon as we can.</p>
    </div>
    <!-- <h4 v-if="invalidAuth">Invalid Token</h4> -->
    <!-- <h4 v-if="invalidProject">Project {{errMsg}}</h4> -->
    <!-- <button @click="login(email, password)">login</button> -->
  </div>
</template>

<script>
import button from '@/components/button'
import loading from "@/components/loading"
import firebase from "firebase/app";
import { config } from "@/lib/config.js";

import { doRequest, errorMessage } from "@/lib/request"
import { apiUrls } from "@/lib/request/urls"
import { saveConfig, underscoreRemover } from "@/lib/transform"

import { createNamespacedHelpers } from 'vuex'
const { mapMutations: mapMutationConfig } = createNamespacedHelpers('config')
const { mapMutations: mapMutationUser } = createNamespacedHelpers("user");

export default{
  components: {
    "oba-button": button,
    "oba-loading": loading,
  },
  setup() {
    //setup
    /**
     * Get config yaml from server
     * @param {*} projectName 
     * @returns {JSON} config
     */
    const requestConfig = async (projectName) => {
      const config = {
        url: apiUrls.downloadConfigYaml(projectName),
        method: "get",
      }
      return await doRequest(config, { needToken: true })
    }

    /**
     * Get fields type from server by projectName and collections
     * @param {*} projectName 
     * @param {*} collections 
     * @returns {JSON} fieldsType
     */
    const requestFieldsType = async (projectName, collections) => {
      const allRequest = []
      let response = { data: {}, status: 200 }
      collections.forEach(collection => {
        const config = {
          url: apiUrls.fieldType(projectName, collection),
          method: 'get'
        }

        allRequest.push(doRequest(config, { needToken: true }))
      })

      const allResponse = await Promise.all(allRequest)
      const failedRequest = allResponse.find(response => response.status != 200)
      if (failedRequest) {
        response.data = failedRequest.data
        response.status = failedRequest.status
        return response
      }

      collections.forEach((collection, i) => {
        const data = underscoreRemover(allResponse[i].data)
        response.data[collection] = data
      })

      return response
    }

    return {
      requestConfig,
      requestFieldsType
    };
  },
  data(){
    return {
      isLoadingCheck: false,
      isLoading: true,
      isDisabled: false,
      invalidAuth: false,
      invalidProject: false,
      errMsg: "",
      senderEmail: '',
      expired: false,
      invitation: {},
      defaultQueries: ["token", "projectName"]
    }
  },
  async beforeMount() {
    const query = this.$route.query
    for (let key of this.defaultQueries) {
      let keyName = key
      this.invitation[keyName] = query[key]
    }
    this.login(this.invitation.token)
  },
  methods: {
    ...mapMutationConfig({
      'saveFieldsType': 'SAVE_FIELDS_TYPE_TO_COLLECTION'
    }),
    ...mapMutationUser({
      saveUserInfo: "SAVE_USER_INFO",
    }),
    setLoadingAndDisable(state) {
      this.isLoading = state
      this.isDisabled = state
    },
    /**
     * Login to firebase using custom token
     * use emulator if environtment is development
     * @param {*} token 
     */
    login(token) {
      // use emulator if environtment is development
      if (config.environtment == "development") {
        firebase.auth().useEmulator("http://localhost:9099");
      }
      firebase
        .auth()
        .signInWithCustomToken(token)
        .then(async (userCredential) => {
          this.invalidAuth = false;
          const user = userCredential.user;
          const userInfo = user.toJSON();
          const idToken = await user.getIdToken();
          this.saveUserInfo({
            email: userInfo.email,
            token: idToken,
            uid: userInfo.uid,
          });
          if (this.$route.query.next) {
            let queries = { ...this.$route.query };
            let next = queries.next;
            delete queries.next;
            this.$router.push({ name: next, query: queries });
          } else {
            // this.$router.push({ name: "inputproject" });
            this.downloadConfig(this.invitation.projectName)
          }
        })
        .catch((error) => {
          this.setLoadingAndDisable(false);
          this.invalidAuth = true;
        });
    },
    /**
     * Download config yaml from server and save to vuex
     * @param {*} projectName 
     */
    async downloadConfig(projectName) {
      this.errMsg = null
      const response = await this.requestConfig(projectName)
      if (response.status == 200) {
        const collections = response.data.collections.map(collection => collection.name)
        saveConfig(response.data, projectName)
        const fieldsType = await this.requestFieldsType(projectName, collections)
        
        if (fieldsType.status != 200) {
          this.setLoadingAndDisable(false);
          if(fieldsType.data.error.reason){
            this.errMsg = fieldsType.data.error.reason
          }
          return
        }
        this.saveFieldsType(fieldsType.data)
        this.setLoadingAndDisable(false);
        this.$router.push({ name: "tickets" })
      } else {
        this.setLoadingAndDisable(false);
        this.invalidProject = true
        this.errMsg = errorMessage[response.status]
      }
    }
  }
}
</script>


<style lang='scss' scoped>
@import './index.scss';
</style> 
