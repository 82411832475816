<template>
    <oba-modal 
      class="modal-deactive" 
      id="modalDeactive" 
      :show="visible" 
      @close="closeModal">
        <template v-slot:modal-content>
          <div class="title-modal-add">
            Deactive User
          </div>
          <div class="input-modal-add">
            <p>Are you sure want to deactive this user?</p>
          </div>
        </template>
        <template v-slot:modal-footer>
          <div class="button-modal-add">
            <oba-button category="secondary" label="cancel" class="cancel" @click="triggertEdit(false)"/>
            <oba-button category="primary"  label="delete"/>
          </div>
      </template>
    </oba-modal>
</template>

<script>
import ObaModal from '@/components/modal'
import button from '@/components/button'

export default {
  name: 'delete-modal',
  components: {
    'oba-modal': ObaModal,
    'oba-button': button
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
  },
  data() {
    return {

    }
  },
  methods:{
    closeModal(){
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>