<template>
  <div v-if="isVisible" :class="formWidthClass">
    <span :class="['title_field', errClass]">{{customLabel}}</span>
    <p v-for="option in extra.options" :key="option.id">
      <label v-if="option.value.toLowerCase() != 'other'">
        <input class="with-gap" type="radio" 
        :checked="isChecked(option.value)"
        v-model="v$.value.$model"
        :value="option.value"
        :disabled="isDisabled"
        @change="updateData(option.value)"
        />
        <span>{{option.value}}</span>
      </label>
      <label v-if="option.value.toLowerCase() == 'other'">
        <input class="with-gap other" type="radio" 
        :checked="isChecked(option.value)"
        v-model="v$.value.$model"
        :value="otherValue"
        :disabled="isDisabled"
        @change="updateData(option.value)"
        />
        <span class="span-other">
          Other :<input type="text" :disabled="otherDisabled" :value="otherValue" @input="updateOther">
        </span>
      </label>
    </p>
    <span 
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <div>
      <span class="helper-text" :data-error="errorMsg">{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-radio-button',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default(){
        return {
          // Options bentuknya array of object
          // Berisi id dan value
          options: []
        }
      } 
    }
  },
  data() {
    return {
      otherDisabled: true,
      otherChecked: false,
      otherValue: '',
    }
  },
  beforeMount(){
    const other = this.extra.options.find(item => item.value.toLowerCase() == 'other')
    if(other){
      const options = this.getOptionsWithoutOther()
      if(!options.includes(this.v$.value.$model.toLowerCase())){
        this.otherValue = this.v$.value.$model
        this.toggleDisabled(false)
      }
      if(!this.otherValue){
        this.toggleDisabled(true)
      }
    }
  },
  methods: {
    getOptionsWithoutOther(){
      const options = this.extra.options.filter(item => item.value.toLowerCase() != 'other').map(option => option.value.toLowerCase())
      return options
    },
    updateData(value){
      if(value.toLowerCase() == 'other'){
        this.toggleDisabled(false)
      } else {
        this.otherValue = ''
        this.toggleDisabled(true)
      }
      this.publishValue(value, "radio")
      this.validateData(value)
    },
    updateOther(event){
      this.otherValue = event.target.value
      this.v$.value.$model = this.otherValue
      this.validateData(this.v$.value.$model)
    },
    isChecked(value){
      const options =  this.getOptionsWithoutOther()
      if(!options.includes(value.toLowerCase())){
        if(this.otherValue){
          return true
        }
      }
      
      return this.inputValue.toLowerCase() == value.toLowerCase()
    },
    toggleDisabled(value){
      this.otherDisabled = value
    },
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
