<template>
    <oba-modal 
      class="modal-edit" 
      id="modaledit" 
      :show="visible" 
      @close="closeEdit">
      <template v-slot:modal-content>
        <div class="title-modal"><h2>Edit Ticket</h2></div>
          <div  class="mid-content">
            <oba-form-builder :key="formKey" ref="editForm" :fields="listFieldEdit"/>
          </div>
      </template>
      <template v-slot:modal-footer>
          <div class="buttons">
            <oba-button 
              class="btn-cancel" 
              category="secondary" 
              label="Cancel" 
              @onClick="closeEdit">
            </oba-button>
            <oba-button 
              category="primary" 
              label="Edit" 
              :isDisabled="isRequesting" 
              :isLoading="isRequesting" 
              @onClick="submit">
            </oba-button>
          </div>
      </template>
    </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ObaModal from '@/components/modal'
import ObaButton from '@/components/button'
import FormBuilder from '@/components/formBuilder'

import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'
import { showNotification, generateSummary } from '@/lib/utils'
import { convertFromDataForm, convertToDataForm } from "@/lib/transform"
import { generateProps } from '@/lib/propsGenerator'


const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')

export default {
  name: 'edit-modal',
  components: {
    'oba-modal': ObaModal,
    'oba-button': ObaButton,
    'oba-form-builder': FormBuilder,
  },
  setup(){
    /**
     * Edit document in collection
     * @param {*} data 
     */
    async function editDocument(data){
      const config = {
        url: apiUrls.editTicket(this.projectName, this.pageState.collection, this.docId),
        method: 'put',
        data : data
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }
    /**
     * Upload file attachment to collection
     * @param {*} formData 
     */
    async function uploadFile(formData){
      const config = {
        url: apiUrls.uploadFile(this.projectName, this.pageState.collection, this.docId),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data : formData
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }
    /**
     * Delete file attachment from collection
     * @param {*} fieldName 
     * @param {*} attachmentUrl 
     */
    async function deleteFileAttachment(fieldName, attachmentUrl){
      const config = {
        url: apiUrls.deleteAttachment(this.projectName, this.pageState.collection, this.docId, 
          fieldName, attachmentUrl),
        method: 'delete'
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return {editDocument, uploadFile, deleteFileAttachment}
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    docId: {
      type: String,
      required: true,
    },
    dataDoc: {
      type: Object,
      required: false,
      default: {}
    }
  },
  data() {
    return {
      isRequesting: false,
      formKey: 0,
      listFieldEdit: [],
    }
  },
  beforeMount(){
    this.generateEditFields()
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateEditFields()
      }
    }
  },
  computed:{
    ...mapStateUser([
      'pageState'
    ]),
    ...mapStateConfig([
      'projectName'
    ]),
    ...mapGettersConfig([
      'getDataType',
      'getCollectionEntries',
      'getExcludedFields',
    ]),
  },
  methods:{
    /**
     * Clean data from collection entries
     * @param {*} data 
     */
    cleanData(data){
      // exclude field status dan assignee serta mengubah required true pada attachment
      let fieldList = []
      let copiedData = JSON.parse(JSON.stringify(data))
      // exclude fields that cannot be edited
      const excludeFields = ["status","assignee","summary"]
      // add excludeFields from config
      this.getExcludedFields(this.pageState.collection).forEach(field => {
        excludeFields.push(field.label)
      })
      
      copiedData.forEach(item => {
        if(!excludeFields.includes(item.name.toLowerCase())){
          item.validations = {}
          // remove autofill date
          if(item.autofill){
            item.autofill = false
          }
          fieldList.push(item)
        }
      })
      return fieldList
    },
    /**
     * Generate edit fields
     */
    async generateEditFields(){
      let entries = this.cleanData(this.getCollectionEntries(this.pageState.collection)) 
      this.listFieldEdit = await generateProps(entries)

      /**Add initial value in entry field */
      this.listFieldEdit.forEach(field =>{
        if(field.type != "file") {
          if(this.dataDoc[field.label]){
            field['inputValue'] = convertToDataForm(field.label, this.dataDoc[field.label], this.pageState.collection)
          }
        }
      })
      this.formKey++
    },
    closeEdit(){
      this.$emit('closeEdit')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    /**
     * Update document in collection (ticket)
     * @param {*} data 
     */
    async updateDocument(data){
      this.setLoadingAndDisable(true)
      const response = await this.editDocument(data)
      if(response.status == 200){
        showNotification('success', `Success, ticket has been updated`)
        this.setLoadingAndDisable(false)
      } else {
        showNotification('error', `Error, ${response.data}`)
        this.setLoadingAndDisable(false)
      }
    },
    /**
     * Submit edit form to update document in collection
     * First, collect data from
     */
    async submit(){
      this.setLoadingAndDisable(true)
      const data = this.collectData()
      if(data){
        /** Upload file first, then update data when uploading file success */
        for (const [key, value] of Object.entries(data.attachment)){
          if(value.length > 0){
            let formData = new FormData()
            for (const file of value){
              formData.append(key, file)
              await this.uploadFile(formData)
            }
          }
        }
        await this.updateDocument(data.field)
        this.$emit('submitEdit')
      }
      this.setLoadingAndDisable(false)
    },
    /**
     * Collect data from edit form
     */
    collectData(){
      let data  = {}
      let fieldData =  {}
      let attachmentData = {}
      const form = this.$refs['editForm']
      const rawData = form.collectValue()
    
      if(!rawData) return null

      /**separate data that contains attachments and does not contain*/
      for (const [key, value] of Object.entries(rawData)){
        const type = this.getDataType(key,this.pageState.collection)
        if(type != "attachment"){
          fieldData[key]  =  value
        }else {
          attachmentData[key] = value
        }
      }

      // generate summary
      let summary = generateSummary(this.pageState.collection,this.checkAssignee(fieldData))
      if(summary){
        fieldData['summary'] = summary
      }
      
      data["field"] = convertFromDataForm(fieldData, this.pageState.collection)
      data["attachment"] = attachmentData
      
      return data
    },
    checkAssignee(data){
      let formData = JSON.parse(JSON.stringify(data))
      if(!formData['assignee']){
        if(this.dataDoc['assignee']){
          formData['assignee'] = this.dataDoc['assignee'].uid
        }
      }
      return formData
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">

</style>

