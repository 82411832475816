<template>
  <div class="detail-container">
    <div v-if="loading" class="loading">
      <oba-loading width="480px"/>
    </div>
    <div v-else class="detail-wrapper">
      <!-- <span class="btn-back"> 
       <oba-button category="icon" icon="arrow_back" @onClick="back"></oba-button>
      </span> -->
      <div v-if="isEmpty" class="empty-state">
        <oba-empty-state/>
      </div>
      <template v-else>
        <div class="title">
          <oba-breadcrumb :collection="collectionName" :projectName="projectDisplayName"/>
          <h2>{{ detailTitle }}</h2>
        </div>
        <div v-if="assignAble || allowedStatus.length > 0 || editable || deleteAble" class="actions">
          <ul class="menus">
            <li v-if="editable"><a @click="toggleEdit(true)">Edit</a></li>
            <li v-if="assignAble"><a @click="toggleAssignee(true)">Assign</a></li>
            <li v-for="(status, index) in allowedStatus" :key="index">
              <a @click="changeStatus(status)">{{transformUpperCase(status.display)}}</a>
            </li>
            <li v-if="deleteAble"><a @click="toggleDeleteDoc(true)">Delete</a></li>
          </ul>
        </div>
        <div class="fields">
          <div class="left spacing">
            <div v-for="(field, index) in leftFields" :key="index" class="text">
              <div v-if="field.value" class="column fieldName">{{ transformUpperCase(field.displayText ? field.displayText : field.fieldName) }}</div>
              <div v-if="field.type == 'label' && field.value" class="column">
                <oba-label :label="field.value" :color="getColorStatus(field.value)" class="status"></oba-label>
              </div>
              <div v-if="field.type == 'date' && field.value" class="column">{{ field.value }}</div>
              <div v-if="field.type != 'label' && field.value && field.type != 'date'" class="column">{{ field.value }}</div>
            </div>
          </div>
          <div class="right">
            <div v-for="(field, index) in rightfields" :key="index" class="text">
              <div v-if="field.value" class="column fieldName">{{ transformUpperCase(field.displayText ? field.displayText : field.fieldName) }}</div>
              <div v-if="field.type == 'label' && field.value" class="column">
                <oba-label :label="field.value" :color="getColorStatus(field.value)" class="status"></oba-label>
              </div>
              <div v-if="field.type == 'date' && field.value" class="column">{{ field.value }}</div>
              <div v-if="field.type != 'label' && field.value && field.type != 'date'" class="column">{{ field.value }}</div>
            </div>
          </div>
        </div>
        <div class="attachments">
          <div class="left spacing">
            <div v-for="(field, index) in leftAttachment" :key="index" class="attachment">
              <div v-if="field.value.length != 0" class="column fieldName">{{ transformUpperCase(field.displayText ? field.displayText : field.fieldName) }}</div>
              <div class="loading" v-if="loadingAtachment.includes(field.fieldName)"><oba-circle-loading/></div>
              <oba-attachment v-else
                :sources="field.value" 
                :fieldName="field.fieldName" 
                :collection="collectionKey"
                @delete="onDeleteAttachment">
              </oba-attachment>
            </div>
          </div>
          <div class="right">
            <div v-for="(field, index) in rightAttachment" :key="index" class="attachment">
              <div v-if="field.value.length != 0" class="column fieldName">{{ transformUpperCase(field.displayText ? field.displayText : field.fieldName) }}</div>
              <div class="loading" v-if="loadingAtachment.includes(field.fieldName)"><oba-circle-loading/></div>
              <oba-attachment v-else
                :sources="field.value"
                :fieldName="field.fieldName" 
                :collection="collectionKey"
                @delete="onDeleteAttachment">
              </oba-attachment>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in subItems" :key="index" class="sub-item">
          <oba-sub-item
          :source="item"
          />
        </div>
        <oba-modal class="modal-assignee" id="modal-assignee" :show="showAssignee" @close="toggleAssignee(false)">
          <template v-slot:modal-content>
            <div class="title">
                <div class="collection-name">Assign : {{ detailTitle }}</div>
            </div>
            <div  class="mid-content">
              <oba-dropdown-search
                id="user-assign" 
                :key="userIncement"
                v-model:inputValue="selectedUserValue" 
                label="Assignee" 
                :extra="userDropdown.extra">
              </oba-dropdown-search>
              <span class="assign-to-me" @click="assignToMe">Assign to me</span>
            </div>
          </template>
          <template v-slot:modal-footer>
              <div class="buttons">
                <oba-button class="btn-cancel" category="secondary" label="Cancel" @onClick="toggleAssignee(false)"></oba-button>
                <oba-button category="primary" label="Assign" :isLoading="isRequesting" @onClick="assign"></oba-button>
              </div>
          </template>
        </oba-modal>
        <oba-modal class="modal-transition" id="modal-transition" :show="showTransition" @close="toggleTransition(false)">
          <template v-slot:modal-content>
            <div class="title-modal">
                <h2>{{ currentTransition }}</h2>
              </div>
              <div  class="mid-content">
                <oba-form-builder :key="formKey" ref="fieldForm" :fields="fieldLists" />
              </div>
          </template>
          <template v-slot:modal-footer>
              <div class="buttons">
                <oba-button class="btn-cancel" category="secondary" label="Cancel" @onClick="toggleTransition(false)"></oba-button>
                <oba-button category="primary" :label="currentTransition" :isDisabled="isRequesting" :isLoading="isRequesting" @onClick="submitData"></oba-button>
              </div>
          </template>
        </oba-modal>
        <delete-modal 
          :visible="showDeleteModal"
          :docId="docId"
          :collectionKey="collectionKey"
          :projectName="projectName"
          type="medium"
          @closeDelete="toggleDeleteDoc(false)"
          @onDeletedDoc="onDeletedDoc"
          >
        </delete-modal>
        <oba-modal 
          class="modal-delete-attachment" 
          id="modal-delete-attachment" 
          type="medium" 
          title="Delete Attachment"
          :show="isDeleteAttachment" 
          @close="toggleDelete(false)">
          <template v-slot:modal-content>
            <div  class="mid-content">
              <span>Are you sure want to delete this attachment ?</span>
            </div>
          </template>
          <template v-slot:modal-footer>
              <div class="buttons">
                <oba-button class="btn-cancel" category="secondary" label="Cancel" @onClick="toggleDelete(false)"></oba-button>
                <oba-button category="primary" label="Delete" :isDisabled="isRequesting" :isLoading="isRequesting" @onClick="deleteAttachment"></oba-button>
              </div>
          </template>
        </oba-modal>
        <edit-modal
          :visible="isEdit"
          :docId="docId"
          :dataDoc="detailDocument"
          @submitEdit="onEdit"
          @closeEdit="toggleEdit(false)">
        </edit-modal>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import Loading from '@/components/loading'
import ObaLabel from '@/components/label'
import ObaModal from '@/components/modal'
import ObaButton from '@/components/button'
import EmptyState from '@/components/emptyState'
import FormBuilder from '@/components/formBuilder'
import ObaDropdownSearch from '@/components/form/selectSearch'
import ObaAttachment from '@/components/attachment'
import DeleteModal from '@/views/detail/deleteModal'
import CircleLoading from '@/components/circleLoading'
import EditModal from '@/views/detail/editModal'
import subItem from '@/views/detail/subItem'

import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'
import { showNotification, generateSummary } from '@/lib/utils'
import { generateProps } from '@/lib/propsGenerator'
import breadCrumb from '../../components/breadCrumb'
import { transformDataDetail, underscoreRemover, convertFromDataForm, convertToDataForm, transformUpperCase, dataDocToLowercase, getAssignee } from '@/lib/transform'

const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')



export default {
  name: 'oba-detail-page',
  components: {
    'oba-attachment': ObaAttachment,
    'oba-label': ObaLabel,
    'oba-button': ObaButton,
    'oba-modal': ObaModal,
    ObaDropdownSearch,
    'oba-empty-state': EmptyState,
    'oba-form-builder': FormBuilder,
    'delete-modal': DeleteModal,
    'oba-circle-loading': CircleLoading,
    'edit-modal': EditModal,
    'oba-loading': Loading,
    'oba-sub-item': subItem,
    'oba-breadcrumb': breadCrumb
  },
  setup() {
    const editTicket = async (projectName, collectionKey, docId, data) => {
      const config = {
        url: apiUrls.editTicket(projectName, collectionKey, docId),
        method: 'put',
        data : data
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    const uploadFile  = async (projectName, collectionKey, docId, formData) => {
      const config = {
        url: apiUrls.uploadFile(projectName, collectionKey, docId),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data : formData
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    const deleteFileAttachment = async (projectName, collectionKey, docId, fieldName, attachmentUrl) => {
      const config = {
        url: apiUrls.deleteAttachment(projectName, collectionKey, docId, 
          fieldName, attachmentUrl),
        method: 'delete'
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    async function getDocument (){
      const config = {
        url: apiUrls.detailDocument(this.projectName, this.collectionKey, this.docId, 
          this.fieldName, this.attachmentUrl),
        method: 'get'
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return { editTicket, uploadFile, deleteFileAttachment, getDocument}
  },
  data() {
    return {
      docId: '',
      collectionName: '',
      collectionKey:'',
      textFields: [],
      attachmentFields: [],
      isEmpty: false,
      showAssignee: false,
      showTransition: false,
      userIncement: 0,
      formKey: 0,
      selectedUserValue: "",
      userDropdown:{
        extra:{
          options:[]
        }
      },
      currentTransition: "",
      changedStatusTo: "",
      fieldLists: [],
      assigneeField: null,
      statusField: null,
      isRequesting: false,
      showDeleteModal: false,
      isEdit: false,
      detailDocument: {},
      loadingAtachment: [],
      isDeleteAttachment: false,
      selectedAttachment: null,
      loading: false,
      subItems: [],
    }
  },
  beforeMount() {
    this.loadPage(this.$route.params)
  },
  beforeRouteUpdate(to, from){
    this.loadPage(to.params)
  },
  watch:{
    'selectedUserValue': function(newValue, oldValue){
      this.incrementKey(newValue, oldValue)
    },
    'userDropdown.extra.options': function(newValue, oldValue){
      this.incrementKey(newValue, oldValue)
    }
  },
  methods: {
    transformUpperCase,
    /**
     * Load page detail ticket
     * @param {*} paramsUrl 
     * paramsUrl is object from route params ex: {docId: '123', collection: 'ticket'}
     */
    async loadPage(paramsUrl){
      this.loading = true
      this.docId = paramsUrl.docId
      this.collectionKey = paramsUrl.collection
      this.pageState.collection = paramsUrl.collection
      this.collectionName = this.collectionKey
      /**get status, assignee fieled from config */
      this.assigneeField = this.getFieldAssignee(this.collectionKey)
      this.statusField = this.getFieldStatus(this.collectionKey)
      this.generateOptions() // generate options untuk dropdown pada menu assign
      await this.loadData()
      this.loading = false
    },
    onDeletedDoc(){
      this.toggleDeleteDoc(false)
      this.$router.back() 
    },
    onEdit(){
      this.loadData()
      this.toggleEdit(false)
    },
    /**
     * Get color from status in collection config to show in label
     * @param {*} value 
     */
    getColorStatus(value){
      let color = null
      const listStatus = this.getStatuses(this.collectionKey)
      listStatus.forEach( item =>{
        if(item.name.toLowerCase() == value.toLowerCase()) color = item.bgColor
      })
      return color
    },
    incrementKey(newValue, oldValue){
      if(newValue != oldValue) this.userIncement++
    },
    toggleAssignee(value){
      this.showAssignee = value
    },
    toggleTransition(value){
      this.showTransition = value
    },
    toggleDelete(value){
      this.isDeleteAttachment = value
      if(!value) this.selectedAttachment = null
    },
    toggleDeleteDoc(value){
      this.showDeleteModal = value
    },
    toggleEdit(value){
      this.isEdit = value
    },
    back() {
      this.$router.back()
    },
    /**
     * Split fields to left and right column
     * @param {*} fields 
     */
    splitFields(fields) {
      let halfFIelds = fields.length / 2
      let left = []
      let right = []
      
      if(Number.isInteger(halfFIelds)){
        left = fields.slice(0, halfFIelds)
        right = fields.slice(halfFIelds, fields.length)
      } else {
        left = fields.slice(0, Math.ceil(halfFIelds))
        right = fields.slice(Math.ceil(halfFIelds), fields.length)
      }
      return { left, right }
    },
    getCollectionsName(collections, key){
      const collectionsName = collections.filter(x => x.key == key).pop()
      return collectionsName.name
    },
    setSelectedUserValue(){
      if(this.assigneeField){
        this.selectedUserValue = this.detailDocument[this.assigneeField]
      }
    },
    /**
     * Load document from api and transform data to show in detail page
     */
    async loadData(){
      const response = await this.getDocument()
      if(response.status == 200){
        let dataFormated = underscoreRemover(response.data)
        dataFormated = dataDocToLowercase(dataFormated)
        this.detailDocument = dataFormated
        const result = transformDataDetail(dataFormated, this.collectionKey)
        this.subItems = this.getSubItem(this.collectionKey)
        if(this.subItems.length != 0){
          this.subItems.forEach(item => {
            item['parentValue'] = this.detailDocument[item.parentField]
          })
        }
        this.attachmentFields = this.excludeFields(result.attachmentFields)
        this.attachmentFields = this.sortingFields(this.attachmentFields)
        this.textFields = this.excludeFields(result.textFields)
        this.textFields = this.sortingFields(this.textFields)

        // check assignee field
        if(this.assigneeField){
          this.selectedUserValue = getAssignee(this.detailDocument[this.assigneeField]).uid
        }
        
      }else{
        this.isEmpty = true
        showNotification('error', `Error, data failed to load`)
      }
    },
    /**
     * Exclude fields from api that not in entry list collection from config
     * @param {*} collectionKey 
     */
    excludeFields(fields) {
      const excludedFields = this.getExcludedFields(this.collectionKey)
      return fields.filter(field => {
        const filteredData = excludedFields.find(item => field.fieldName.toLowerCase() == item.name.toLowerCase())
        if (filteredData) {
          return false
        }

        return true
      })
    },
    /**
     * Sorting fields from api based on entry list collection from config
     * @param {*} data 
     * data is fields from api ex: [{fieldName: 'name', value: 'John'}, {fieldName: 'age', value: '20'}]
     */
    sortingFields(data) {
      const result = []
      const entryList = this.getCollectionEntries(this.collectionKey)

      entryList.forEach(item => {
        const filteredData = data.find(field => field.fieldName.toLowerCase() == item.name.toLowerCase())
        if(filteredData){
          result.push(filteredData)
        }
      })
      return result
    },
    /**
     * Generate options to show in dropdown component
     */
    generateOptions(){
      this.userDropdown.extra.options = this.userList.map((item, index) =>{
        return {
          "id" : index,
          "label" : item.email,
          "value": item.uid,
          "disabled": false
        }
      })
    },
    /**
     * Update data document to api 
     * @param {*} data 
     */
    async updateDataDocument(data){
      this.isRequesting = true
      const response = await this.editTicket(this.projectName, this.collectionKey, this.docId, data)
      if(response.status == 200){
        this.loadData()
        showNotification('success', `Success, data has been updated`)
        this.toggleTransition(false)
        this.isRequesting = false
      } else {
        showNotification('error', `Error, ${response.data}`)
        this.toggleTransition(false)
        this.isRequesting = false
      }
    },
    injectAssignee(data){
      let formData = data
      formData['assignee'] = this.selectedUserValue
      return formData
    },
    async assign(){
      let data = {}
      //generate summary
      let summary = generateSummary(this.collectionKey, this.injectAssignee(this.detailDocument))

      data[this.assigneeField] = this.selectedUserValue
      
      // assign summary
      if(summary){
        data['summary'] = summary
      }
      this.updateDataDocument(data) 
      this.toggleAssignee(false)
    },
    assignToMe() {
      this.selectedUserValue = this.uid
      this.assign()
    },
    changeStatus(status){
      this.currentTransition = status.display
      this.changedStatusTo = status.to
      let data = {}
      data[this.statusField] = status.to
      if(status.hasOwnProperty('screen')){
        if(status.screen.length != 0){
          this.generateFields(status)
          this.toggleTransition(true)
        } else {
          this.updateDataDocument(data)
          this.loadData()
        }
      } else {
        this.updateDataDocument(data)
        this.loadData()
      }
    },
    async submitData(){
      this.isRequesting = true
      const data = this.collectData()
      if(data){
        /** Upload file first, then update data when uploading file success */
        for (const [key, value] of Object.entries(data.attachment)){
          if(value.length > 0){
            let formData = new FormData()
            for (const file of value){
              formData.append(key, file)
              await this.uploadFile(this.projectName, this.collectionKey, this.docId, formData)
            }
          }
        }
        await this.updateDataDocument(data.field)
      }
      this.isRequesting = false
    },
    async onDeleteAttachment(data){
      this.isDeleteAttachment = true
      this.selectedAttachment = data
    },
    async deleteAttachment(){
      this.isRequesting = true
      this.loadingAtachment.push(this.selectedAttachment.fieldName)
      const response = await this.deleteFileAttachment(this.projectName, this.collectionKey, 
      this.docId, this.selectedAttachment.fieldName, encodeURIComponent(this.selectedAttachment.link))
      if(response.status = 200){
        showNotification('success', `Success, data has been updated`)
        this.loadData()
      } else {
        showNotification('error', `Error, cannot delete attachment`)
      }
      this.loadingAtachment = []
      this.isDeleteAttachment = false
      this.isRequesting = false
      this.selectedAttachment = null
    },
    async generateFields(status){
      const entryList = status.screen
      this.fieldLists = await generateProps(entryList)
      /**Add initial value in entry field */
      this.fieldLists.forEach(field =>{
        if(field.type != "file") {
          if(this.detailDocument[field.label]){
            field['inputValue'] = convertToDataForm(field.label, this.detailDocument[field.label], this.collectionKey)
          }
        }
      })
      this.formKey++
    },
    collectData(){
      let data  = {}
      let fieldData =  {}
      let attachmentData = {}
      const form = this.$refs['fieldForm']
      const rawData = form.collectValue()
    
      if(!rawData) return null

      /**separate data that contains attachments and does not contain*/
      for (const [key, value] of Object.entries(rawData)){
        const type = this.getDataType(key,this.collectionKey)
        if(type != "attachment"){
          fieldData[key]  =  value
        }else {
          attachmentData[key] = value
        }
      }
      
      data["field"] = convertFromDataForm(fieldData, this.collectionKey)
      data["field"][this.statusField] = this.changedStatusTo
      data["attachment"] = attachmentData
      
      return data
    }
  },
  computed: {
    ...mapStateConfig([
      'projectName',
      'collections',
      'projectDisplay',
    ]),
    ...mapGettersConfig([
      'getCollectionEntries',
      'getTransitionStatus',
      'getFieldStatus',
      'getFieldAssignee',
      'getDataType',
      'getEditPermission',
      'getAssignPermission',
      'getExcludedFields',
      'getStatuses',
      'getDeletePermission',
      'getDisplayText',
      'getSubItem',
    ]),
    ...mapStateUser([
      'uid',
      'userList',
      'pageState',
    ]),
    projectDisplayName(){
      return this.projectDisplay ? this.projectDisplay : this.projectName
    },
    editable(){
      return this.getEditPermission(this.collectionKey, this.detailDocument[this.statusField])
    },
    assignAble(){
      return this.getAssignPermission(this.collectionKey)
    },
    deleteAble(){
      return this.getDeletePermission(this.collectionKey)
    },
    allowedStatus(){
      if(this.statusField){
        const activeStatus = this.detailDocument[this.statusField]
        return this.getTransitionStatus(this.collectionKey, activeStatus)
      } 
      return []
    },
    detailTitle() {
      if (this.detailDocument.summary) {
        return this.detailDocument.summary
      }
      return this.docId
    },
    leftFields() {
      const { left } = this.splitFields(this.textFields)
      return left
    },
    leftAttachment() {
      const { left } = this.splitFields(this.attachmentFields)
      return left
    },
    rightfields() {
      const { right } = this.splitFields(this.textFields)
      return right
    },
    rightAttachment() {
      const { right } = this.splitFields(this.attachmentFields)
      return right
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
