<template>
    <oba-modal 
      class="modal-delete" 
      id="modalDelete" 
      :show="visible" 
      @close="closeModal"
      title="Delete User">
        <template v-slot:modal-content>
          <div class="input-modal-add">
            <span>Are you sure want to delete this user?</span>
          </div>
        </template>
        <template v-slot:modal-footer>
          <div class="button-modal-add">
            <oba-button category="secondary" label="Cancel" class="cancel" @click="closeModal"/>
            <oba-button category="primary" 
            label="Delete" 
            :isDisabled="isRequesting" 
            :isLoading="isRequesting"
            @click="deleteUser"/>
          </div>
      </template>
    </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ObaModal from '@/components/modal'
import button from '@/components/button'

import { doRequest } from "@/lib/request/index.js"
import { showNotification } from '@/lib/utils'
import { apiUrls } from '@/lib/request/urls'

const {mapState: mapStateConfig } = createNamespacedHelpers('config')

export default {
  name: 'delete-modal',
  emits: ['submit','close'],
  setup(){
    async function deleteUser(projectName) {
      const config = {
        url: apiUrls.deleteUser(projectName, this.parentData.docId),
        method: 'delete'
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }
    return {deleteUser}
  },
  components: {
    'oba-modal': ObaModal,
    'oba-button': button
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: true
    },
    data: {
      type: Object,
      required: true,
      default: {}
      // menerima Object berbentuk :
      // {
      //   docId: "asdasdadsadsa",
      //   columns: []
      // }
    },
  },
  data() {
    return {
      parentData: {},
      isRequesting: false
    }
  },
  beforeMount(){
    this.generateParentData()
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateParentData()
      }
    },
  },
  computed:{
    ...mapStateConfig([
      'projectName'
    ]),
  },
  methods:{
    generateParentData(){
      this.parentData = this.data
    },
    closeModal(){
      this.$emit('close')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    async deleteUser(){
      this.setLoadingAndDisable(true)
      const response = await this.deleteUser(this.projectName)
      if(response.status >= 200 && response.status <= 299){
        this.setLoadingAndDisable(false)
        showNotification('success', `Success, user has been delete`)
        this.$emit('submit')
      } else{
        this.setLoadingAndDisable(false)
        showNotification('error', `Error, user failed to delete`)
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>