<template>
<div v-if="isRequesting" class="loading">
  <oba-loading width="480px"/>
</div>
<div v-if="!isRequesting">
  <div class="title">{{transformUpperCase(source.displayText ? source.displayText : source.name)}}</div>
  <div class="table">
    <oba-table-sub-items :collection="source.collection" :header="tableHeader" :body="tableBody" @selectRow="onSelectedRow" />
    <oba-pagination 
            :initPage="selectedPage" 
            :maxPage="maxPage" 
            @changePage="onPageChanged"/>
  </div>
</div>
<div v-if="createPermission" class="create-subitem" @click="toggleCreateTicket(true)">Add {{transformUpperCase(source.displayName ? source.displayName : source.name)}}</div>
<oba-create-ticket
  :visible="displayCreate"
  :collection="source.collection"
  :subItem="source"
  @closeCreate="toggleCreateTicket(false)"
  @onCreate="onCreateDocument" >  
</oba-create-ticket>
</template>

<script>
import { transformDataTable, transformUpperCase, underscoreRemover } from '@/lib/transform.js'
import { createNamespacedHelpers } from 'vuex'
import { doRequest } from '@/lib/request/index.js'
import apiUrls from '@/lib/request/urls.js'
import tableSubItems from '@/components/tableSubItems'
import Loading from '@/components/loading'
import createTicket from '@/components/createTicket'
import pagination from '@/components/pagination'

const { mapState: mapStateConfig, mapGetters: mapGettersConfig, mapMutations: mapMutationsConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')

export default {
  name: "oba-sub-item",
  components: {
    'oba-table-sub-items': tableSubItems,
    'oba-loading': Loading,
    'oba-create-ticket': createTicket,
    'oba-pagination': pagination
  },
  setup(){
    async function getListTickets(projectName, collectionName){
      const config = {
        url: apiUrls.listDocument(projectName, collectionName),
        method: 'post',
        data: {
          page: this.selectedPage,
          filters: [
            {
              field: this.source.childField,
              operator: "equal",
              value: this.source.parentValue
            }
          ]
        }
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }
    const requestFieldsType = async (projectName, collection) => {
      const config = {
        url: apiUrls.fieldType(projectName, collection),
        method: 'get'
      }
      return await doRequest(config, { needToken: true })
    }

    return { getListTickets, requestFieldsType }
  },
  props: {
    source: {
      type: Object,
      required: false,
      default: {}
      // properties:
      // {
      //   "name": ""
      //   "type": "",
      //   "collection": "",
      //   "childField": "",
      //   "parentField": "",
      //   "table": []
      // }
    }
  },
  data(){
    return {
      tableBody: [],
      tableHeader: [],
      selectedPage: 1,
      maxPage: 1,
      isRequesting: false,
      displayCreate: false,
    }
  },
  beforeMount(){
    this.loadData()
  },
  computed: {
    ...mapStateConfig([
      'projectName',
    ]),
    ...mapStateUser([
      'pageState',
    ]),
    ...mapGettersConfig([
      'getSelectedCollection',
      'getCreatePermission',
    ]),
    createPermission(){
      return this.getCreatePermission(this.source.collection)
    }
  },
  methods: {
    ...mapMutationsConfig({
      'saveFieldsType': 'SAVE_FIELDS_TYPE_TO_COLLECTION',
    }),
    transformUpperCase,
    /**
     * Get list documents from server and transform it to table body
     */
    async getListDocuments(){
      this.isRequesting = true
      const response = await this.getListTickets(this.projectName, this.source.collection)
      if(response.status == 201){
        this.tableBody = transformDataTable(this.tableHeader, response.data.data, this.source.collection)
        this.maxPage = response.data.lastPage || 1
      } 
      this.isRequesting = false
    },
    onSelectedRow(selectedRow){
      this.$router.push({
        name: "detailpage", 
        params: { collection: this.source.collection, docId: selectedRow.docId },
      })
    },
    async loadData(){
      await this.getFieldsType()
      this.tableHeader = this.source.table
      this.getListDocuments()
    },
    toggleCreateTicket(value){
      this.displayCreate = value
    },
    onCreateDocument(){
      this.loadData()
    },
    onPageChanged(page){
      this.selectedPage = page
      this.getListDocuments()
    },
    /**
     * Get fields type from server by projectName and collections
     */
    async getFieldsType(){
      const selectedCollection = this.getSelectedCollection(this.source.collection)
      if(!selectedCollection.fieldsType){
        const fieldsType = await this.requestFieldsType(this.projectName, this.source.collection)
      
        if (fieldsType.status != 200) {
          if(fieldsType.data.error.reason){
            showNotification('error', `${fieldsType.data.error.reason} (${fieldsType.status})`)
          }
          return
        }
        const cleanedFieldsType = underscoreRemover(fieldsType.data)
        const data = {
          collection: this.source.collection,
          fieldsType: cleanedFieldsType
        }
        this.saveFieldsType(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>