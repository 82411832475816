<template>
  <nav>
    <div class="clean">
      <div  class="col s12">
        <a v-for="crumb in currentCrumb" :key="crumb" :class="['breadcrumb', currentCrumb.indexOf(crumb) == currentCrumb.length - 1 ? 'current-page' : '']">{{ crumb }}</a>
      </div>
    </div>
  </nav>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateConfig, mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-breadcrumb',
  props: {
    collection: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentCrumb: [this.projectName]
    }
  },
  beforeMount() {
    this.currentCrumb[1] = this.collectionDisplayText(this.collection)
  },
  watch:{
    'collection': function (newValue, oldValue){
      if(newValue){
        this.currentCrumb[1] = this.collectionDisplayText(newValue)
      }
    }
  },
  computed: {
    ...mapGettersConfig([
      'getSelectedCollection',
    ]),
  },
  methods: {
    collectionDisplayText(value) {
      const collection = this.getSelectedCollection(value)
      if(collection){
        return collection.displayText
      } else if (value == 'reports'){
        return 'Add Ons'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>