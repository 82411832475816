<template>
   <div v-if="isVisible" :class="['input-field', formWidthClass]">
    <textarea 
      :id="customId"
      :class="['materialize-textarea', styleClass, errClass]"
      class="text-area"
      v-model="v$.value.$model"
      :disabled="isDisabled"
      :placeholder="extra.placeholder"
      @input="updateData"
      autocomplete="off">
    </textarea>
    <label class="active" :for="customId">{{customLabel}}</label>
    <span 
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <span v-if="helperText" class="helper-text">{{ helperText }}</span>
  </div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-textarea',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default() {
        return {
          placeholder: ""
        }
      }
    },
   
  },
  beforeMount() {
    this.$nextTick(function(){
      // Check input value in text area, if input value is not empty resize the textarea height to fit the content
      if(this.inputValue!= ""){
        const element = document.querySelector(`#${this.customId}`);
        M.textareaAutoResize(element);
      }
    })
  },
  methods: {
    updateData(event) {
      this.validateData(event.target.value)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
