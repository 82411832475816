<template>
  <div v-if="isVisible" :class="['input-field', formWidthClass]" :id="containerDateTimeId">
    <label class="label-datetime" :for="customId">{{ customLabel }}</label>
    <input 
      type="text" 
      :id="customId"
      :class="[styleClass, errClass, 'datepicker', 'timepicker']"
      v-model="v$.value.$model"
      :disabled="isDisabled"
      autocomplete="off"
    />
    <span 
      v-for="error of v$.$errors" :key="error.$uid"
      class="helper-text" :data-error="error.$message">
    </span>
    <div v-if='!!helperText'>
      <span class="helper-text" :data-error="errorMsg">{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { generateRandomId } from '@/lib/utils'
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-datetime',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default() {
        return {
          // add format props base on materialize format doc.
          formatDate : 'dd mmm yyyy',
        }
      }
    }
  },
  data() {
    return {
      defaultDateFormat:  "dd mmm yyyy",
      containerDateTimeId : '',
      formatedDate: '',
      formatedTime: '',
      formatedDateTime: '',
    }
  },
  beforeMount(){
    this.generateDateId()
    this.initComponent() 
  },
  renderTriggered({key}){
    if(key == "isVisible") this.initComponent() 
  },
  methods: {
    initComponent(){
      this.$nextTick(function()  {
        const elem = document.querySelector(`#${this.customId}`);
        let formatDate = ""
        if(this.extra) {
          formatDate = this.extra.formatDate ? this.extra.formatDate : this.defaultDateFormat
        }
      
        const container = document.querySelector(`#${this.containerDateTimeId}`)
        
        let optionsDate = {
          format: formatDate,
          onSelect: this.updateDate,
          showClearBtn: false,
          autoClose: true,
          container: container
        }
        let optionsTime = {
          defaultTime: "now",
          twelveHour: false,
          autoClose: true,
          onCloseStart: this.updateDateTime,
          onSelect: this.updateTime,
          showClearBtn: true,
        }
        M.Timepicker.init(elem, optionsTime)
        M.Datepicker.init(elem, optionsDate)

        const clearBtnTime = document.querySelector(`#${this.containerDateTimeId} .timepicker-clear`)
        if(clearBtnTime){
          clearBtnTime.addEventListener("click", this.resetData)
        }
      })
    },
    updateDate(value){
        let format = this.defaultFormat
        if(this.extra) {
          format = this.extra.formatDate ? this.extra.formatDate : this.defaultDateFormat
        }
        /*  Upper case format for transforming materialize format
          to moment's format */
        const formatedDate = moment(value).format(format.toUpperCase())

        this.formatedDate = formatedDate
    },
    updateTime(hour, minutes){
      // combine hour and minutes from picker to single string
      let time = moment(new Date(0,0,0,hour,minutes,0,0)).format("HH:mm")
      this.formatedTime = time
    },
    updateDateTime(){
      if(this.formatedDate && this.formatedTime){
        this.formatedDateTime = `${this.formatedDate}, ${this.formatedTime}`
      }
      this.v$.value.$model = this.formatedDateTime
      this.validateData(this.v$.value.$model)
    },
    generateDateId(){
      const newId = generateRandomId()
      this.containerDateTimeId = `date-${newId}`
    },
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>