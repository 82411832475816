import { createRouter, createWebHistory } from "vue-router"
import Login from '@/views/login'
import ListTicket from '@/views/ListTicket'
import InputProject from '@/views/inputProject'
import DetailPage from '@/views/detail'
import DashboardChart from '@/views/dashboardChart'
import Management from '@/views/management'
import AcceptInvitation from '@/views/acceptInvitation'
import Register from '@/views/register'
import Home from '@/views/home'
import CustomAuth from "@/views/customAuth"
import AddOns from "@/views/addOns"
import ImportUsers from '@/views/customs/importUsers'
import BulkEdit from "@/views/customs/bulkEdit"
import ImportConfig from '@/views/customs/importConfig'
import Customs from '@/views/customs'

import store from './store'

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { isAuthenticated: true },
    redirect: {name: "tickets"},
    children: [
      {
        path: "tickets",
        name: "tickets",
        component: ListTicket,
      },
      {
        path: "management",
        name: "management",
        component: Management,
      },
      {
        path: "addons",
        name: "addons",
        component: AddOns,
      },
      {
        path: "custom",
        name: "custom",
        component: Customs,
        children: [
          {
            path: "/custom/bulk-edit",
            name: "bulkedit",
            component: BulkEdit
          },
          {
            path: "/custom/import-config",
            name: "importconfig",
            component: ImportConfig
          },
          {
            path: "/custom/import-users",
            name: "importusers",
            component: ImportUsers
          }
        ]
      }
    ],
  },
  {
    path: "/input-project",
    name: "inputproject",
    component: InputProject,
    meta: { isAuthenticated : true }
  },
  {
    path: "/detail/:collection/:docId",
    name: "detailpage",
    component: DetailPage,
    meta: { isAuthenticated: true}
  },
  {
    path: "/dashboard-chart",
    name: "dashboardchart",
    component: DashboardChart,
    meta: { isAuthenticated: true}
  },
  {
    path: "/accept-invitation",
    name: "acceptinvitation",
    component: AcceptInvitation,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/custom/auth",
    name: "CustomAuth",
    component: CustomAuth
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authToken = store.state.user.authToken

  if (to.meta.isAuthenticated) {
    if (authToken) {
      if((window.location.pathname == '/' || window.location.pathname == '/tickets') && !store.state.config.projectName && from.fullPath !='/tickets'){
        next({ name: 'login'})
      }
      next()
    } else {
      next({ name: 'login'})
    }
  } else {
    next()
  }
})

export default router
