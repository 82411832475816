<template>
  <div class="import-users-wrapper">
    <div class="input-container">
      <div class="title">
        Import Users from Excel File
      </div>
      <div class="row">
        <div>
          <input-file :id="containerFieldId" ref="file" :label="field.label" v-model:inputValue="fileExcel"
            :extra="field.extra" :validations="field.validations" :helperText="helperText" class="input" />
        </div>
      </div>
      <div class="search">
        <oba-button category="primary" @onClick="submit" label="Submit" :isDisabled="isRequesting"
          :isLoading="isRequesting" />
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/form/inputFile'
import button from '@/components/button'

import { doRequest } from '@/lib/request/index.js'
import apiUrls from '@/lib/request/urls.js'
import { showNotification, generateRandomId } from '@/lib/utils.js'
import { createNamespacedHelpers } from 'vuex'
import Excel from 'exceljs'
import { useVuelidate } from '@vuelidate/core'

const { mapState: mapStateConfig, mapMutations: mapMutationsConfig } = createNamespacedHelpers('config')

export default {
  name: 'import-users',
  components: {
    InputFile,
    'oba-button': button
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  data() {
    return {
      data: null,
      fileExcel: null,
      isRequesting: false,
      field: {
        label: 'Select File',
        id: 'file',
        validations: {
          required: true
        },
        extra: {
          isMultiple: false,
          fileNames: [],
          accept: "file",
        },
      },
      helperText: '',
      containerFieldId: null,
    }
  },
  computed: {
    ...mapStateConfig([
      'projectName',
    ]),
  },
  beforeMount() {
    this.generateFieldId()
  },
  watch: {
    fileExcel: {
      handler: function () {
        this.getTotalRows()
      },
    }
  },
  methods: {
    ...mapMutationsConfig({
      'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION'
    }),
    /**
     * Submit import excel send request to server
     */
    async submit() {
      this.isRequesting = true
      this.v$.$touch()
      if (!this.v$.$error) {
        const { data, status } = await this.importExcel()
        if (status == 200) {
          const success = data.statusInfo.success
          const failed = data.statusInfo.failed
          if (!failed) {
            showNotification('success', `Imported! Success: ${success} Failed: ${failed}`)
            // delay 1s to wait for server to update data
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.saveCurrentCollection("members")
            this.$router.push({
              name: 'management'
            })
          } else {
            showNotification('error', `Imported! Success: ${success} Failed: ${failed}`)
          }
        } else {
          showNotification('error', `Failed! ${data}`)
        }
      }
      this.isRequesting = false
    },
    /**
     * Import excel send request to server
     * excel file will be sent as form data
     * @returns {Promise<*>}
     */
    async importExcel() {
      this.isRequesting = true
      let fd = new FormData();
      fd.append('file', this.fileExcel[0])
      const config = {
        url: apiUrls.importUsers(this.projectName),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data: fd
      }
      const response = await doRequest(config, { needToken: true })
      this.isRequesting = false
      return response
    },
    /**
     * Get workbook from file excel
     * @param file
     * @returns {Promise<*>}
     */
    async getWorkbookFromFile(file) {
      const wb = new Excel.Workbook();
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const buffer = reader.result;
          const workbook = wb.xlsx.load(buffer)
          resolve(workbook);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },
    /**
     * Get total rows from file excel
     * @returns {Promise<void>}
     */
    async getTotalRows() {
      if (this.fileExcel) {
        const workbook = await this.getWorkbookFromFile(this.fileExcel[0])
        const worksheet = workbook.worksheets[0];
        let totalData = worksheet.rowCount - 1
        this.helperText = `${totalData} user(s) to import detected from file`
      }
    },
    /**
     * Generate random id for input file
     */
    generateFieldId() {
      const newId = generateRandomId()
      this.containerFieldId = `excel-${newId}`
    }
  }
}


</script>

<style lang="scss" scoped>
@import './index.scss'
</style>