import { generateRandomId } from '@/lib/utils'

const formInputMixin = {
  props:{
    extra: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    validations: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    id: {
      type: String,
      required: true
    },
    cssClass: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    type: {
      type: String,
      required: false,
      default: ""
    },
    inputValue: {
      type: String,
      required: false,
      default: ""
    },
    dependency: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    dependencyOptionsId: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    displayText: {
      type: String,
      required: false,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    },
    formWidthClass: {
      type: String,
      required: false,
      default: "col s12"
    },
    errorMsg: {
      type: String,
      required: false,
      default: ""
    },
    successMsg: {
      type: String,
      required: false,
      default: ""
    },
    helperText: {
      type: String,
      required: false,
      default: ""
    },
  },
  data(){
    return {
      isVisible: this.visible,
      customId: '',
    }
  },
  beforeMount(){
    this.generateId()
    if (this.dependency) this.registerListener()
    if (this.dependencyOptionsId) this.registerListenerOptions()
  },
  beforeUnmount(){
    if (this.dependency) this.emitter.off(`${this.dependency.entry}`)
    if (this.dependencyOptionsId) this.emitter.off(`options-${this.dependencyOptionsId}`)
  },
  computed: {
    errClass() {
      return this.v$.value.$error ? "invalid" : ""
    },
    styleClass() {
      return this.cssClass.join(" ")
    },
    customLabel(){
      if(this.displayText){
        return this.validations.hasOwnProperty("required") ? `${this.displayText} *` : this.displayText
      }
      return this.validations.hasOwnProperty("required") ? `${this.label} *`: this.label
    }
  },
  methods: {
    generateId(){
      const newId = generateRandomId()
      this.customId = `oba-${newId}`
    },
    toggleVisible(visible){
      this.isVisible = visible
      this.$emit('update:visible', visible)
    },
    visibilityCheck(selectedValue, fieldValue){
      let visible = false
      if(selectedValue.type == "checkbox"){
        visible = selectedValue.value.includes(fieldValue) ? true : false
      }else{
        visible = selectedValue.value == fieldValue ? true : false
      }
      return visible
    },
    registerListener(){
      //set visibility base on emit value from other component 
      this.emitter.on(`${this.dependency.entry}`, (data) => {
        const visible  = this.visibilityCheck(data, this.dependency.targetValue)
        this.toggleVisible(visible)
      });
    },
    registerListenerOptions(){
      //set options base on emit value from other component 
      this.emitter.on(`options-${this.dependencyOptionsId}`, () => {
        this.initComponent()
        this.v$.value.$model = ""
        this.$emit('update:inputValue', null)
        this.v$.$reset()
      });
    },
    publishValue(value,type){
      this.emitter.emit(`${this.id}`, {
        type: type,
        value: value
      })
      this.emitter.emit(`options-${this.id}`)
   },
   emitValue(value){
    this.$emit('update:inputValue', value)
   },
   validate(){
    this.v$.value.$touch()
   },
   validateData(value){
     this.validate()
     this.emitValue(value)
   },
   resetData(){
    this.v$.value.$model = ""
    this.validateData(this.v$.value.$model)
   }
  }
}

export default formInputMixin

 