<template>
  <!-- :key="$route.fullPath" is used to force replacement of an element/component 
  with same route but have different params -->
  <div>
    <oba-header />
    <div class="app-container">
      <router-view ></router-view>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
import firebaseInit from './firebaseConf'
import header from './components/header'

export default {
  name: 'App',
  components: {
    'oba-header': header
  },
  mounted() {
    M.AutoInit()
    firebaseInit()
  },
}
</script>