<template>
    <div v-if="isVisible" :class="['input-field', formWidthClass]">
      <input type="text" :id="customId" class="autocomplete" 
      :class="[styleClass, errClass]"
      :disabled="isDisabled"
      v-model="v$.value.$model"
      autocomplete="off"
      @input="updateInput"
      />
      <label class="active-autocomplete" :for="customId">{{customLabel}}</label>
      <span 
        v-for="error of v$.$errors" :key="error.$uid"
        class="helper-text" :data-error="error.$message">
      </span>
      <span v-if="helperText" class="helper-text">{{ helperText }}</span>
    </div>
</template>

<script>
import setValidations  from '@/lib/validation'
import formInputMixin from '@/lib/mixins/formInputMixin'

export default {
  name: 'oba-autocomplete',
  setup(props) {
    const v$ = setValidations(props.validations, props.inputValue, props.label)
    return { v$ }
  },
  mixins: [formInputMixin],
  props: {
    extra: {
      type: Object,
      required: false,
      default(){
        return {
          // Value options bentuknya object
          // Contoh : {"name": value, "name": value}
          // Value berisi link untuk image  apabila ada image
          // Value diisi null apabila tidak ada link imagenya
          options: {},
        }
      } 
    }
  },
  beforeMount(){
    this.initComponent() 
  },
  renderTriggered({key}){
    if(key == "isVisible") this.initComponent() 
  },
  methods: {
    initComponent(){
      this.$nextTick(function() {
        const elem = document.querySelector(`#${this.customId}`);
        // add option ke dalam list autocomplete
        let options = {
          data: this.extra.options,
          onAutocomplete : this.updateData
        }

        M.Autocomplete.init(elem, options)
      })
    },
    updateData(value){
      this.validateData(value)
      this.v$.value.$model = value
    },
    updateInput(event){
      this.validateData(event.target.value)
      this.v$.value.$model = event.target.value
    },
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>