<template>
    <div :class="['floating-menu', isShowMenu ? 'show' : '']" id="floatingMenu">
      <span v-for="item, index in menu" :key="index" @click.stop="selected(item)" class="item">
        {{item.label}}
      </span>
    </div>
</template>

<script>
export default {
    name: 'oba-floating-menu',
    props: {
        menu: {
            type: Array,
            required: true
        },
        isShowMenu: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        selected(item){
            this.$emit('selected', item)
        }
    }
}
</script>
<style scoped >
@import "./index.scss";
</style>