<template>
  <div>
    <div class="title">
      Import File
    </div>
    <div class="row">
      <div class="col s12">
        <input-file :id="containerFieldId" ref="file" :label="field.label" v-model:inputValue="fileExcel" :errorField="errorMessage ? 'invalid' : ''"
        :extra="field.extra" :validations="field.validations" :helperText="helperText"/>
        <div class="col error-msg" v-if="errorMessage">{{errorMessage}}</div>
      </div>
    </div>
    <div class="search">
      <oba-button category="secondary" @onClick="cancel" label="Cancel" class="cancel"/>
      <oba-button category="primary" @onClick="submit" label="Import" :isDisabled="isRequesting" :isLoading="isRequesting"/>
    </div>
  </div>
</template>

<script>
import InputFile from '../../components/form/inputFile'
import button from '@/components/button'

// third party lib
import { doRequest } from '@/lib/request/index.js'
import { showNotification, generateRandomId } from '@/lib/utils.js'
import apiUrls from '@/lib/request/urls.js'
import { createNamespacedHelpers } from 'vuex'
// third party lib
import { useVuelidate } from '@vuelidate/core'
import Excel from 'exceljs'

const { mapState: mapStateConfig } = createNamespacedHelpers('config')
const { mapState: mapStateUser } = createNamespacedHelpers('user')

export default {
  name: 'import-excel',
  components: {
    InputFile,
    'oba-button': button
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  data() {
    return  {
      data: null,
      fileExcel: null,
      isRequesting: false,
      field: {
        label: 'Select File',
        id: 'file',
        validations: {
          required: true
        },
        extra: {
          isMultiple: false,
          fileNames: [],
          accept: "file",
        },
      },
      helperText: '',
      containerFieldId: null,
      errorMessage: '',
    }
  },
  emits: ["cancelImport", "imported"],
  beforeMount(){
    this.generateFieldId()
  },
  watch: {
    fileExcel: {
      handler: function () {
        this.getTotalRows()
        this.errorMessage = ''
      },
    }
  },
  computed: {
    ...mapStateConfig([
      'collections',
      'projectName',
      'currentCollection'
    ]),
    ...mapStateUser([
      'pageState'
    ]),
  },
  methods: {
    async importExcel() {
      this.isRequesting = true
      let fd = new FormData();
      fd.append('file',this.fileExcel[0])
      const config = {
        url: apiUrls.importExcel(this.projectName, this.currentCollection),
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data : fd
      }
      const response = await doRequest(config, {needToken: true})
      this.isRequesting = false
      return response
    },
    async submit() {
      this.v$.$touch()
      if (!this.v$.$error) {
        let response = await this.importExcel()
        if(response.status == 200){
          showNotification('success', `Import data success`)
          this.$emit("imported");
        } else {
          this.errorMessage = response.data
          this.helperText = ''
        }
      }
    },
    async getWorkbookFromFile(file) {
      const wb = new Excel.Workbook();
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const buffer = reader.result;
          const workbook = wb.xlsx.load(buffer)
          resolve(workbook);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },
    async getTotalRows() {
      if (this.fileExcel) {
        const workbook = await this.getWorkbookFromFile(this.fileExcel[0])
        const worksheet = workbook.worksheets[0];
        let totalData = worksheet.rowCount - 1
        this.helperText = `Upload ${totalData} data from file`
      }
    },
    cancel() {
      this.$emit("cancelImport");
    },
    generateFieldId(){
      const newId = generateRandomId()
      this.containerFieldId = `excel-${newId}`
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>