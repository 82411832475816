import store from '@/store'
import moment from 'moment'
import wkt from 'wkt'

/**
 * Transform all field name on document into lowercase
 * @param {*} document 
 * {
 *   "key1": "value1",
 *   "Key2": "value2"
 * }
 * @returns 
 * {
 *  "key1": "value1",
 *  "key2": "value2"
 * }
 */
function dataDocToLowercase(document) {
  // transform field on document from server
  const result = {}
  for (const [key, value] of Object.entries(document)) {
    result[key.toLowerCase()] = document[key]
  }
  return result
}

/**
 * Transform all field name on transition screen into lowercase
 * @param {*} transitions 
 * {
 *    "name": "validasi - from menunggu validasi to done",
 *    "display": "Validasi",
 *    "from": "menunggu validasi",
 *    "to": "done",
 *    "screen": [
 *      {
 *        "name": "status",
 *      }
 *    ]
 * }
 * @param {*} entries 
 * [
 *  {
 *      "name": "summary",
 *      "type": "text",
 *      "validations": {
 *          "required": true
 *      }
 *  },
 *  {
 *      "name": "status",
 *      "type": "text",
 *      "validations": {
 *          "required": true
 *      }
 *  }
 * ]
 * @returns 
 */
function transformTransition(transitions, entries) {
  // transform list transition status on collection into lowercase
  transitions.map( transition => {
    transition.name = transition.name.toLowerCase()
    transition.to = transition.to.toLowerCase()
    transition.from = transition.from.toLowerCase()
    if(transition.screen.length != 0){
      transition.screen = transformFieldLabelToLowercase(transition.screen)
      if(entries != 0){
        transition.screen = transition.screen.map(item => {
          const collectionEntry = entries.find(entry => entry.name.toLowerCase() == item.name.toLowerCase())
          if(collectionEntry){
            let collectionScreen = JSON.parse(JSON.stringify(collectionEntry))
            // if there are property isDisabled, then inject the property into the transition screen
            if(item.isDisabled){
              collectionScreen['isDisabled'] = item.isDisabled
            }
            return collectionScreen
          }
        })
      }
    }
    return transition
  })
  return transitions
}

/**
 * Transform all status name into lowercase
 * @param {*} listStatus 
 * [
 *   {
 *      "key": "menunggu validasi",
 *      "name": "Menunggu Validasi"
 *   }
 * ]
 * @returns 
 * [
 *  {
 *     "key": "menunggu validasi",
 *    "name": "menunggu validasi"
 *  }
 * ]
 */
function transformStatusToLowercase(listStatus) {
  return listStatus.map(status => {
    status.key = status.key.toLowerCase()
    status.name = status.name.toLowerCase()
    return status
  })
}

/**
 * Transform name field each collections from config into lowercase if needed
 * @param {*} fields
 * [
 *  {
 *   "name": "Summary",
 *   "type": "text",
 *   "validations": {
 *      "required": true
 *    }
 *  },
 * ]
 * @returns
 * [
 *  {
 *    "name": "summary",
 *    "type": "text",
 *    "validations": {
 *    "required": true
 *  }
 * ]
 * */
function transformFieldLabelToLowercase(fields) {
  return fields.map(field => {
    field.name = field.name.toLowerCase()
    return field
  })
}

/**
 * Transform all collections from config into lowercase if needed
 * @param {*} collections
 * [
 *  {
 *    "name": "Collection 1",
 *    "entries": [
 *      {
 *         "name": "Summary",
 *         "type": "text",
 *         "validations": {
 *            "required": true
 *          }
 *      }
 *     ]
 *  }
 * ]
 * @returns
 * [
 *  {
 *    "name": "collection 1",
 *    "entries": [
 *     {
 *       "name": "summary",
 *       "type": "text",
 *       "validations": {
 *         "required": true
 *       }
 *     }
 *    ]
 *  }
 * ]
 * */
function transformCollections(collections){
  return collections.map(collection => {
    // Transform all field name to lowercase each entries in each collections
    collection.entries = transformFieldLabelToLowercase(collection.entries)
    
    // Transform all field name in transition screen into lowercase
    if(collection.transitions.length != 0){
      collection.transitions = transformTransition(collection.transitions, collection.entries)
    }
    return collection
  })
}

/**
 * Save config to store vuex
 * @param {*} data 
 * data is contain of config stored on firebase storage on yaml format
 * @param {*} project 
 * {"id":0,"label":"Acaraki Buy Back","value":"acarakibuyback","disable":false}
 */
function saveConfig(data, project) {
  let config = {
    collections: transformCollections(data.collections),
    projectName: project.value ? project.value : project,
    projectDisplay: project.label ? project.label : project,
    dashboardUrls: data.dashboard ? data.dashboard : data.dashboardv2,
  }
  store.commit('config/SAVE_CONFIG', config)
  store.commit('user/SAVE_USER_GROUP', data.groups)
}

/**
 * Transform first letter of field name into uppercase
 * @param {*} fieldName 
 * "template id"
 * @returns 
 * "Template id"
 */
function transformUpperCase(fieldName){
  return fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
}

/**
 * Remove field if field start with underscore
 * @param {*} objectData
 * {
 *  "status":"menunggu pembelian",
 *  "summary":"Acaraki - tanggal dan waktu pembelian - ibrahim@adxasia.co.id",
 *  "_createdBy":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *  "_dateCreated":"2023-05-20T10:21:41.091+0000",
 *  "_dateModified":"2023-05-20T10:21:41.091+0000",
 *  "id":"PUeWzgbgRI1ijSJQMKx5"
 * }
 * @returns
 * {
 *  "status":"menunggu pembelian",
 *  "summary":"Acaraki - tanggal dan waktu pembelian - ibrahim@adxasia.co.id",
 *  "id":"PUeWzgbgRI1ijSJQMKx5"
 * }
 */
function underscoreRemover(objectData){
  let returnData = { ...objectData }
  for( let key of Object.keys(objectData)){
    if (key.indexOf('_') != -1) {
      if(key[0] == "_"){
        delete returnData[key]
      }
    }
  }
  return returnData
}

/**
 * Get assignee object if assigne is null or undefined then return empty object 
 * with email, uid, and fullname property
 * @param {*} assignee 
 * {"uid":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR","email":"ibrahim@adxasia.co.id","fullname":""} or null
 * @returns 
 * {"uid":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR","email":"ibrahim@adxasia.co.id","fullname":""}
 */
function getAssignee(assignee){
  if(!assignee){
    return {
      email: '',
      uid: '',
      fullName: ''
    }
  } 
  return assignee
}

/**
 * Transform data detail into textFields and attachmentFields
 * exclude id and summary field
 * to classify data into textFields and attachmentFields based on type on config
 * stored on vuex
 * @param {*} data 
 * {
 *  "summary":"Acaraki - tanggal dan waktu pembelian - ibrahim@adxasia.co.id",
 *  "assignee":{
 *    "uid":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *    "email":"ibrahim@adxasia.co.id",
 *    "fullname":""
 *   },
 *  "status":"menunggu pembelian",
 *  "id":"PUeWzgbgRI1ijSJQMKx5"
 * }
 * @param {*} currentCollection 
 * "collection_name"
 * @returns 
 * {
 *   textFields: [
 *    {
 *      "fieldName":"assignee",
 *      "value":"ibrahim@adxasia.co.id",
 *      "type":"text"
 *    },
 *    {
 *      "fieldName":"status",
 *      "value":"menunggu pembelian",
 *      "type":"label"
 *    }
 *   ],
 *  attachmentFields: []
 * }
 */
function transformDataDetail(data, currentCollection) {
  const textFields = []
  const attachmentFields = []
  // field2 ini tidak menjadi bagian dari field2 yang di tampilkan scara "table"
  // yang terbagi 2 kiri dan kanan
  const excludedFields = ["id", "summary"]
  for (const [fieldName, value] of Object.entries(data)) {
    if (excludedFields.includes(fieldName)) {
      continue
    }
    const isShowZeroValue = store.getters['config/getIsShowZeroValue'](fieldName, currentCollection)
    const type = store.getters['config/getDataType'](fieldName, currentCollection)
    const displayText = store.getters['config/getDisplayText'](fieldName, currentCollection)
    if (type == "attachment") {
      if (value.length > 0) {
        attachmentFields.push({ fieldName: fieldName, value: value, type: type, displayText: displayText })
      }
    } else {
      let item = { fieldName: fieldName, value: value, type: type, displayText: displayText }
      if(fieldName.toLowerCase() == 'status'){
        item.type = "label"
      } else if (fieldName.toLowerCase() == 'assignee') {
        let valueAssignee = getAssignee(value)
        item.value = valueAssignee.fullname || valueAssignee.email
      } else {
        if(type == 'date') {
          item.value = item.value ? moment(new Date(value)).format('DD MMM YYYY'): item.value
        } else if (type == 'long' || type == 'float') {
          item.value = item.value ? item.value.toLocaleString('id'): item.value
        } else if (type == 'geopoint' || type == 'geo_shape') {
          item.value = item.value ? transformFromWkt(item.value, type): item.value
        } else if (type == 'datetime') {
          item.value = item.value ? transformFromIsoString(item.value) : item.value
        }
        //checking if value is object or array for spliting display value
        if(Array.isArray(item.value) ) {
          if(item.value.length > 0) item.value =  item.value.join(" ; ")
        }
      }
      // pengecekan value berguna untuk tidak menampilkan field dengan value yang kosong
      if(value && value.length != 0){
        textFields.push(item)
      }else{
        if(isShowZeroValue){
          item.value = "0"
          textFields.push(item)
        }
      }
    }
  }
  return { textFields, attachmentFields }
}
/**
 * Convert value based on type (long, date, datetime, float)
 * @param {*} fieldName 
 * "tanggal"
 * @param {*} value 
 * "2021-05-20T10:21:41.091+0000"
 * @param {*} collectionKey 
 * "collection_name"
 * @returns 
 * "20 Mei 2021"
 */
function convertToDataForm(fieldName, value, collectionKey){
  const type = store.getters['config/getDataType'](fieldName, collectionKey)
  if (type == 'long'){
    return value.toString()
  } else if (type == 'date'){
    return moment(new Date(value)).format('DD MMM YYYY')
  } else if (type == 'datetime'){
    return transformFromIsoString(value)
  } else if (type == 'float'){
    return value.toString()
  } else {
    return value
  }
}

/**
 * Convert value based on type (long, date, datetime, float)
 * @param {*} type
 * "date"
 * @param {*} value
 * "20 Mei 2021"
 * @returns
 * 2021-05-20T10:21:41.091+0000
 */
function convertValueBasedOnType(type, value) {
  let convertedValue = null
  const dataType = type.toLowerCase()
  if(value){
    if (dataType == "long" || dataType == "number" || dataType == "float"){
      convertedValue = parseFloat(value)
    } else if (dataType == "date"){
      convertedValue = moment(new Date(value)).format('YYYY-MM-DD')
    } else if (dataType == "datetime"){
      convertedValue = transformToIsoString(value)
    } else if (dataType == "boolean"){
      if(value.toLowerCase() == "true" || value.toLowerCase() == "ya" || value.toLowerCase() == "1" || value.toLowerCase() == "yes"){
        convertedValue = true
      } else {
        convertedValue = false
      } 
    } else {
      // apabila tipe data text / array / object
      if(Array.isArray(value)){
        convertedValue = value.length != 0 ? value : null
      } else {
        convertedValue = value
      }
    }
  }
  return convertedValue
}

/**
 * Convert data from data form into data that can be saved into db or elasticsearch
 * Type of data is get from config stored on vuex
 * @param {*} data
 * {
 * "tanggal": "20 Mei 2021",
 * "status": "menunggu pembelian",
 * }
 * @param {*} collectionKey
 * "collection_name"
 * @returns
 * {
 * "tanggal": "2021-05-20T10:21:41.091+0000",
 * "status": "menunggu pembelian",
 * }
 */
function convertFromDataForm(data, collectionKey) {
  let result = {}
  for (const [keyData, valueData] of Object.entries(data)) {
    let type = store.getters['config/getDataType'](keyData, collectionKey)
    result[keyData] = convertValueBasedOnType(type, valueData)
  }
  return result
}

/**
 * Transform data row into data that can be displayed on table
 * @param {*} columnsConfig 
 * ["summary","status","assignee","sumber barang"]
 * @param {*} rowDataBody 
 * {
 *  "assignee":{"uid":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR","email":"ibrahim@adxasia.co.id","fullname":""},
 *  "status":"menunggu pembelian",
 *  "summary":"Acaraki - 10 May 2023, 17:27 - ibrahim@adxasia.co.id",
 *  "_createdBy":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *  "_dateCreated":"2023-05-20T10:21:41.091+0000",
 *  "_dateModified":"2023-05-20T11:28:14.453+0000",
 *  "_modifiedBy":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *  "total value pembelian (rp)":1000,
 *  "notes":null,
 *  "id":"PUeWzgbgRI1ijSJQMKx5"
 * }
 * @param {*} currentCollection 
 * "collection_name" ex: "barangmasuk"
 * @returns 
 * {
 *  "docId":"PUeWzgbgRI1ijSJQMKx5",
 *  "columns":[
 *   {
 *    "fieldName":"summary",
 *    "value":"Acaraki - 10 May 2023, 17:27 - ibrahim@adxasia.co.id",
 *    "type":"text"
 *   },
 *   {
 *     "fieldName":"status",
 *     "value":"menunggu pembelian",
 *     "type":"label"
 *   },
 *  ]
 * }
 */
function transformRowData(columnsConfig, rowDataBody, currentCollection){
  const cleanedRowDataBody = dataDocToLowercase(underscoreRemover(rowDataBody))
  const lowercaseColumnsConfig = columnsConfig.map(item => {
    return item.toLowerCase()
  })
  // return value untuk key type berdasarkan key
  // table hanya membutuhkan 2 types yaitu label dan text
  let columnsData = []
  for( var value of lowercaseColumnsConfig){    
    const type = store.getters['config/getDataType'](value,currentCollection)
    const isShowZeroValue = store.getters['config/getIsShowZeroValue'](value,currentCollection)
    let data = {
      fieldName: value,
      value: cleanedRowDataBody[value],
      type: "text",
    }
    if(value.toLowerCase() == "status"){ 
      data.type = "label"
    } else if (value.toLowerCase() == "assignee"){
      let assignee = cleanedRowDataBody[value]
      data.value = ""
      // check if there's assignee property
      if (assignee) {
        // check if there's fullname property. then use it if exist
        if (assignee.fullname) {
          data.value = assignee.fullname
        } else {
          // check if there's email property. the use it if exist
          if (assignee.email) {
            data.value = assignee.email
          }
        }
        // data.value = assignee.fullname ? assignee.fullname : assignee.email
      }
      // data.value = cleanedRowDataBody[value].fullname ? cleanedRowDataBody[value].fullname : cleanedRowDataBody[value].email
    }

    // check type
    if(type == "date"){
      data.value = cleanedRowDataBody[value] ? moment(new Date(cleanedRowDataBody[value])).format('DD MMM YYYY') : ''
    } else if ( type == "long" || type == "float" ){
      if(isShowZeroValue && !data.value){
        data.value = 0
      }
      data.value = data.value ? data.value.toLocaleString('id') : data.value
    } else if (type == "geopoint" || type == "geo_shape") {
      data.value = data.value ? transformFromWkt(data.value, 'geopoint') : data.value
    } else if ( type == "datetime"){
      data.value = data.value ? transformFromIsoString(data.value) : data.value
    }

    // cek apabila valuenya array, maka harus diubah jadi string
    if(Array.isArray(cleanedRowDataBody[value])){
      data.value = cleanedRowDataBody[value].join(" ; ")
    }
    
    columnsData.push(data)
  }
  return {
    docId: cleanedRowDataBody['id'],
    columns: columnsData
  }
}

/**
 * Transform data from db into data that can be displayed on table
 * @param {*} columnsConfig
 * ["summary","status","assignee","sumber barang"]
 * @param {*} rowDataBody
 * list of data from db
 * [
 *  {
 *   "assignee":{"uid":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR","email":"ibrahim@adxasia.co.id","fullname":""},
 *   "status":"menunggu pembelian",
 *   "summary":"Acaraki - 10 May 2023, 17:27 - ibrahim@adxasia.co.id",
 *   "_createdBy":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *   "_dateCreated":"2023-05-20T10:21:41.091+0000",
 *   "_dateModified":"2023-05-20T11:28:14.453+0000",
 *   "_modifiedBy":"r0zXnZlVTpNDFj2AxgVYJtpHGPXR",
 *   "total value pembelian (rp)":1000,
 *   "notes":null,
 *   "id":"PUeWzgbgRI1ijSJQMKx5"
 *  },
 * ]
 * @param {*} currentCollection
 * "collection_name" ex: "barangmasuk"
 * @returns
 * list of data that can be displayed on table with format
 * [
 *  {
 *    "docId": "id from firebase",
 *    "columns": [
 *      {
 *        "fieldName": "field name",
 *        "value": "value",
 *        "type": "label or text depending on field type"
 *      }
 *    ]
 *  }
 * ]
 */
function transformDataTable(columnsConfig, rowDataBody, currentCollection){
  return rowDataBody.map(item =>{
    return transformRowData(columnsConfig, item, currentCollection)
  })
}

/**
 * Transform data form(lat, lng) into wkt format for geopoint elastic search
 * @param {*} value
 * "-6.22592,106.8302336"
 * @param {*} type
 * "geopoint"
 * @returns
 * "POINT (-6.22592 106.8302336)"
 * */
function transformToWkt(value, type){
  let transformedValue = null
  if(value){
    if(type.toLowerCase() == 'geopoint'){
      const splitValue = value.split(",")
      transformedValue = `POINT (${splitValue[0]} ${splitValue[1]})`
    }
  }
  return transformedValue
}

/**
 * Transform data from wkt format into lat,lng format 
 * for geopoint or geo_shape elastic search
 * @param {*} value
 * "POINT (-6.22592 106.8302336)"
 * @param {*} type
 * "geopoint"
 * @returns
 * "-6.22592,106.8302336"
 */
function transformFromWkt(value, type){
  let transformedValue = null
  if(value){
    if(type.toLowerCase() == 'geopoint' || type.toLowerCase() == 'geo_shape'){
      const geoJson = wkt.parse(value)
      transformedValue = geoJson.coordinates.join(',')
    }
  }
  return transformedValue
}

/**
 * Transform data from datetime format into date and time format
 * @param {*} data
 * "2021-05-20T10:21:41.091+0000"
 * @returns
 * "20 Mei 2021 @ 17:21"
 */
function transformDateTime(data){
  const tanggal = moment(data).format('DD MMM YYYY')
  const time = moment(data).format('HH:mm')
  return `${tanggal} @ ${time}`
}

/**
 * Transform data from datetime format into iso string format
 * @param {*} value
 * "15 May 2023, 16:27"
 * @returns
 * "2023-05-15T09:27:00.000Z"
 * */
function transformToIsoString(value){
  const date = moment(new Date(value)).toISOString()
  return date
}

/**
 * Transform data from iso string format into datetime format
 * @param {*} value
 * "2023-05-15T09:27:00.000Z"
 * @returns
 * "15 May 2023, 16:27"
 */
function transformFromIsoString(value){
  const date = moment(value).format('DD MMM YYYY, HH:mm')
  return date
}

export {
  transformDataDetail,
  underscoreRemover,
  saveConfig,
  convertFromDataForm,
  transformDataTable,
  convertValueBasedOnType,
  convertToDataForm,
  dataDocToLowercase,
  transformUpperCase,
  transformFieldLabelToLowercase,
  transformStatusToLowercase,
  transformTransition,
  transformToWkt,
  transformFromWkt,
  transformDateTime,
  transformToIsoString,
  transformFromIsoString,
  getAssignee,
}