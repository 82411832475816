<template>
  <table>
    <thead>
      <th class="checkbox" v-if="header.length != 0">
        <label>
          <input class="filled-in" type="checkbox" :checked="checkedAll" @click="selectAll">
          <span class="th-checkbox-span"></span>
        </label>
      </th>
      <th class="select-menu" v-if="header.length != 0"><span>
        <i class="material-icons bulk-select" @click="displaySelect">{{displaySelectMenu ? 'expand_less' : 'expand_more'}}</i>
        <div v-if="displaySelectMenu" class="menu-list">
          <ul>
            <li @click="selectAllData">Select all</li>
            <li @click="selectCurrentPage">Current page</li>
          </ul>
        </div>
      </span></th>
      <th v-for="item in header" :key="item">
        {{item}}
      </th>
    </thead>
    <tbody>
      <tr v-for="(row, index) in body" :key="row.docId">
        <td v-if="row.columns.length != 0"><label><input class="filled-in" type="checkbox" :checked="checkedAll" @click="selectDocument(row.docId)"><span class="td-checkbox-span"></span></label></td>
        <td v-if="row.columns.length != 0"></td>
        <td v-for="(item,  idx) in row.columns" :key="`${index}-${idx}`" @click.stop="selectRow(row.docId, row.columns)">
          <oba-label v-if="item.type == 'label'" 
            :label="item.value" 
            :color="getColorStatus(item.value)"
          />
          <span v-else>{{item.value}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Label from '@/components/label'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-table',
  emits: ['selectRow','selectDoc','selectAllData'],
  components: {
    'oba-label': Label,
  },
  props: {
    collection: {
      type: String,
      required: true
    },
    header:{
      type: Array,
      required : false,
      default: []
      /** Sample for header table structure
       *  data = [ 
            {label : "Label Name" , width: "15%"}, 
            {label : "Label Name 2" , width: "15%"}
          ]
      * */
    },
    body: {
      type:Array,
      required : false,
      default: []
      /** Sample for body table structure
       * data = [
       *  {
            docId: 'WQJENWMMDMsss',
            columns: [ 
              {fieldName: "ID", value: "installation-OBA-100",  type: "text"},
              {fieldName: "Name", value: "Done",  type: "label"},  
       *    ]
          }
        ]
      * */
    },
  },
  data(){
    return {
      isChecked: false,
      checkedAll: false,
      listChecked: [],
      displaySelectMenu: false,
    }
  },
  computed: {
    ...mapGettersConfig([
      'getStatuses'
    ])
  },
  methods:{
    selectRow(id, item){
      let rowData = {}
      Object.values(item).forEach( data =>{
        rowData[data.fieldName] = data.value
      })
      rowData['docId'] = id
      this.$emit('selectRow', rowData)
    }, 
    getColorStatus(value){
      /**
       * get hex color status from config
       */
      const listStatus = this.getStatuses(this.collection)
      let color = null
      listStatus.forEach( item =>{
        if(item.name.toLowerCase() == value.toLowerCase()) color = item.bgColor
      })
      return color
    },
     displaySelect(){
      this.displaySelectMenu = !this.displaySelectMenu
    },
    selectDocument(docId){
      const item = this.listChecked.find(id => id == docId)
      if(item){
        const index = this.listChecked.indexOf(docId)
        this.listChecked.splice(index, 1)
      } else {
        this.listChecked.push(docId)
      }
      this.$emit('selectDoc', this.listChecked)
    },
    selectAll(){
      this.checkedAll = !this.checkedAll
      let listDocument = []
      if(this.checkedAll){
        this.body.forEach(doc => {
          const item = listDocument.find(id => id == doc.docId)
          if(item){
            const index = listDocument.indexOf(doc.docId)
            listDocument.splice(index, 1)
          } else {
            listDocument.push(doc.docId)
          }
        })
      }
      this.listChecked = listDocument
      this.$emit('selectDoc', this.listChecked)
    },
    selectAllData(){
      this.checkedAll = false
      this.selectAll()
      this.$emit('selectAllData', true)
      this.displaySelectMenu = !this.displaySelectMenu
    },
    selectCurrentPage(){
      this.checkedAll = false
      this.selectAll()
      this.displaySelectMenu = !this.displaySelectMenu
    },

  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
