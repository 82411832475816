<template>
  <button v-if="visible" :type="type" :class="getButtonClass" @click.stop="onClick" :disabled="isDisabled">
    <i v-if="icon" class="material-icons left">{{icon}}</i>
    {{displayLabel}}
    <div v-if="isLoading" class="preloader-wrapper small active">
      <div class="spinner-layer spinner-green-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div><div class="gap-patch">
          <div class="circle"></div>
        </div><div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'oba-button',
  emits: ['onClick'],
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    // type : button / submit
    type: {
      type: String,
      required: false,
      default: "button"
    },
    // category : primary, secondary, link, icon
    category: {
      type: String,
      required: true
    },
    // size : large, medium, small, tiny
    size: {
      type: String,
      required: false,
      default: "medium"
    },
    // list icon in https://materializecss.com/icons.html
    icon: {
      type: String,
      required: false,
      default: ""
    },
    iconPosition: {
      type: String,
      required: false,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      disable: this.isDisabled ? "disabled" : "",
    }
  },
  computed: {
    getButtonClass(){
      let buttonClass = []
      if(this.category == "primary" | this.category == "secondary") {
        buttonClass.push("btn")
      }
      buttonClass.push(this.getBtnCategoryClass())
      if(this.isDisabled){
        buttonClass.push(this.disable)
      }
      if(this.size == "small") {
        buttonClass.push("small-size")
      } else if (this.size == "large") {
        buttonClass.push("large-size")
      }
      return buttonClass.join(" ")
    },
    displayLabel(){
      // Label pada kategori link harus di capitalize manual dengan method, 
      // Karena pada category tersebut styling css classnya tidak bisa memakai text-transform ketika ada icon
      // sehingga pada class btn-flat materialize css tidak mendukung overide menjadi capitalize
      if(this.category == "link"){
        return this.label
      }
      return this.label
    }
  },
  methods: {
    getBtnCategoryClass(){
      let buttonClass = ""
      switch(this.category){
        case "link":
          buttonClass = "btn-flat"
          break
        case "icon":
          buttonClass = "btn-floating"
          break
        case "primary":
          buttonClass = "primary"
          break
        case "secondary":
          buttonClass = "secondary"
          break
        default:
          buttonClass = "btn"
      }
      return buttonClass
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      // Directly return the joined string
      return splitStr.join(' '); 
    },
    onClick(event) {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>