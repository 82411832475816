<template>
  <div :class="['tooltip', position, {long:isLong}]">
    <div class="content" @click="isHover = true" @mouseover="isHover = true" @mouseout="isHover = false">
      <slot name="content"></slot>
    </div>
    <span ref="text" class="tooltiptext" :class="{hover:isHover}">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "oba-tooltip",
  props: {
    text: { required: true },
    position: { required: false, default: 'top' },
    isLong: { required: false, default: false },
  },
  mounted() {
  },
  data() {
    return {
      isHover: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';

</style>
