<template>
  <div :class="['groups', displayName ? 'add-row-gap':'']">
    <div 
      v-for="(item, index) in sources" 
      :key="index" 
      class="attachment"
      @mouseenter="toggleOverlay($event, true)"
      @mouseleave="toggleOverlay($event, false)"
      >
      <div v-if="isImage(item)" class="background" :style="{'background-image': `url('${imgLoader(item)}')`}"></div>
      <div v-else class="background doc"><img src="@/assets/icon/document.png" alt=""></div>
      <div class="overlay">
        <div class="icons">
          <a :href="imgLoader(item)" target="_blank">
            <i class="material-icons">visibility</i>
          </a>
          <a v-if="deleteAble" @click="deleteAttachment(item)"><i class="material-icons">delete</i></a>
        </div>
      </div>
      <div class="attachment-name" v-if="displayName">{{getAttachmentNames(item)}}</div>
    </div>
  </div>
</template>

<script>
import { config } from '@//lib/config.js'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersConfig } = createNamespacedHelpers('config')

export default {
  name: 'oba-attachment',
  emits: ['delete'],
  props: {
    sources: {
      type: Array,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGettersConfig([
      'getAttachmentNamePermission',
      'getDeletePermission'
    ]),
    displayName(){
      return this.getAttachmentNamePermission(this.collection)
    },
    deleteAble(){
      return this.getDeletePermission(this.collection)
    }
  },
  methods: {
    toggleOverlay(event, showOverlay) {
      const overlay = event.currentTarget.querySelector('.overlay')
      if (showOverlay) {
        overlay.classList.add('show')
      } else {
        overlay.classList.remove('show')
      }
    },
    deleteAttachment(url) {
      // TO DO
      // emit id / filename of attachment
      let data = {
        fieldName: this.fieldName,
        link: url
      }
      this.$emit('delete', data)
    },
    imgLoader(path){
      return `${config.imgPrefix}${path}`
    },
    isImage(path){
       return (path.match(/\.(jpg|jpeg|png|gif)$/i))
    },
    getAttachmentNames(value){
      let name = value.replace(/.*[\/\\]/, '')
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
