<template>
<div class="side-nav-wrapper">
  <ul id="slide-out" :class="['sidenav', isActive ? 'showNav' : 'hideNav']">
  <li class="logo"><p>{{projectDisplay}}</p></li>
  <li class="title" @click="triggerCollapse()">
    <span class="material-icons">folder</span><a>{{`${title}`}}</a><span class="material-icons">{{expandShow}}</span>
  </li>
  <div class="collapse" v-if="collapsShow">
    <li v-for="collection in collectionList" :key="collection.name" :class="['list', isSelected(collection) ? 'active' : '', isPageLoading ? 'disabled': '']">
      <a v-if="!collection.hidden" @click="!isPageLoading && select(collection)">{{collection.displayText ? collection.displayText : collection.name}}</a>
    </li>
  </div>

  <div class="bottom">
    <div class="bottom-menu">
      <div v-if="isShown" class="dashboard-wrapper">
        <div v-if="Array.isArray(dashboardUrls)">
          <li class="title" @click="triggerCollapseDashboard()">
            <span class="material-icons">dashboard</span><a>Dashboard</a><span class="material-icons">{{expandDashboard}}</span>
          </li>
          <div class="collapse"  v-if="collapseDasboard">
            <li v-for="(dashboard, index) in dashboardUrls" :key="index" :class="['list', isSelected(dashboard) ? 'active' : '', isPageLoading ? 'disabled': '']">
              <a @click="!isPageLoading && openDashboard(index)">{{dashboard.name}}</a>
          </li>
          </div>
        </div>
        <li class="list" v-else>
          <a @click="openDashboard()"> <span class="material-icons">dashboard</span>Dashboard</a>
        </li>
      </div>
      <li class="title" @click="triggerCollapseManagement" v-if="isAdmin">
        <span class="material-icons">settings</span><a>Project management</a><span class="material-icons">{{expandManagement}}</span>
      </li>
      <div class="collapse" v-if="collapseManagement">
        <li v-for="collection in collectionManagementList" :key="collection.name" :class="['list', isSelected(collection) ? 'active' : '', isPageLoading ? 'disabled': '']">
          <a v-if="collection.name == 'bulk'" @click="triggerCollapseBulk">{{collection.displayText ? collection.displayText : collection.name}} <span class="material-icons">{{expandBulk}}</span></a>
          <div class="collapse" v-if="collapseBulk && collection.name=='bulk'">
            <li v-for="bulk in collectionBulkList" :key="bulk.name" :class="['list', isSelected(bulk) ? 'active' : '', isPageLoading ? 'disabled': '']">
              <a @click="!isPageLoading && openManagementPage(bulk)">{{bulk.displayText ? bulk.displayText : bulk.name}}</a>
            </li>
          </div>
          <a v-if="!collection.hidden && collection.name != 'bulk'" @click="!isPageLoading &&  openManagementPage(collection)">{{collection.displayText ? collection.displayText : collection.name}}</a>
        </li>
      </div>
      <li class="title" @click="triggerCollapseAddOns()" v-if="isAdmin">
        <span class="material-icons">extension</span><a>Add Ons</a><span class="material-icons">{{expandAddOns}}</span>
      </li>
      <div class="collapse" v-if="collapseAddOns">
        <li v-for="collection in collectionAddOnList" :key="collection.name" :class="['list', isSelected(collection) ? 'active' : '', isPageLoading ? 'disabled': '']">
          <a @click="!isPageLoading && openAddOnsPage(collection)">{{collection.displayText ? collection.displayText : collection.name}}</a>
        </li>
      </div>
    </div>
  </div>
  </ul>
</div>
</template>

<script>
import modal from '@/components/modal'
import button from '@/components/button'

import { createNamespacedHelpers } from 'vuex'

const { mapMutations: mapMutationsConfig, mapState: mapStateConfig } = createNamespacedHelpers('config')
const { mapMutations: mapMutationsUser, mapActions: mapActionUser, mapState: mapStateUser, mapGetters: mapGettersUser } = createNamespacedHelpers('user')

export default {
  name: 'oba-side-nav',
  emits: ['selected'],
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    initial: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false,
      default: "Collections"
    },
    collectionList: {
      // List berbentuk array of objects dengan key "name" dan "displayText"
      // contoh : 
      // [{"name": "collection1", "displayText": "Collection 1"}]
      type: Array,
      required: true,
    }
  },
  components: {
    'oba-modal': modal,
    'oba-button': button,
  },
  data() {
    return {
      selected: this.initial,
      isLoading: false,
      isShown: false,
      collapsShow: false,
      expandShow: "chevron_right",
      collapseManagement: false,
      collapseBulk: false,
      collapseAddOns: false,
      collapseDasboard: false,
      expandManagement: "chevron_right",
      expandBulk: "chevron_right",
      expandAddOns: "chevron_right",
      expandDashboard: 'chevron_right',
      collectionManagementList: [
        {
          name: "queue",
          displayText: "Queue"
        },
        {
          name: "bulk",
          displayText: "Bulk Operations",
        },
        {
          name: "members",
          displayText: "Member Management"
        },
        {
          name: "importConfig",
          displayText: "Project Configuration"
        }
      ],
      collectionBulkList: [
        {
          name: "bulkEdit",
          displayText: "Bulk Edit"
        },
        {
          name: "importUsers",
          displayText: "Import Users"
        }
      ],
      collectionAddOnList: [
        {
          name: "reports",
          displayText: "Reports"
        }
      ],
      group: '',
    }
  },
  async beforeMount() {
    this.isShownDashboard()
    this.group = this.getGroup(this.uid)
    const currentPage = this.$router.currentRoute.value.name
    if (currentPage == 'tickets') {
      const names = this.collectionList.map(value => value.name)
      if (!this.selected || !names.includes(this.selected)) {
        this.saveCurrentCollection(names[0])
      }
      this.triggerCollapse() 
    } else if (currentPage == 'management') {
      const names = ['queue', 'members']
      if (!this.selected || !names.includes(this.selected)) {
        this.saveCurrentCollection(names[0])
      }
      this.triggerCollapseManagement()
    } else if (currentPage == 'addons') {
      const names = ['reports']
      if (!this.selected || !names.includes(this.selected)) {
        this.saveCurrentCollection(names[0])
      }
      this.triggerCollapseAddOns()
    }
    
  },
  watch: {
    'userList': function (newValue, oldValue) {
      if(newValue.length > 0){
        this.fetchGroup()
      }
    },
    'currentCollection': function (){
      if(this.currentCollection == 'queue' || this.currentCollection == 'members'){
        this.collapseManagement = true
        this.expandManagement = "expand_more"
        this.collapsShow = false
        this.expandShow = "chevron_right"
      }
    }
  },
  computed: {
    ...mapStateConfig([
      'dashboardUrls',
      'currentCollection',
      'projectName',
      'projectDisplay'
    ]),
    ...mapGettersUser([
      'getGroup',
      'isPageLoading'
    ]),
    ...mapStateUser([
      'uid',
      'userList',
    ]),
    isAdmin(){
      if(this.group){
        return this.group.toLowerCase() == 'admin'
      }
      return false
    },
  },
  methods: {
    ...mapMutationsConfig({
      resetConfig: 'RESET_CONFIG',
      'saveCurrentCollection': 'SAVE_CURRENT_COLLECTION'
    }),
    ...mapMutationsUser([
      'DELETE_PAGE_STATE',
      'CLEAR_USER_LIST',
      'SET_PAGE_LOADING_STATE'
    ]),
    ...mapActionUser({
      resetUser: 'logout'
    }),
    select(collection){
      if(this.currentCollection != collection.name){
        this.SET_PAGE_LOADING_STATE(true)
      }
      this.selected = collection.name
      this.$emit('selected', collection.name)
      this.$router.push({ name: "tickets" });
    },
    fetchGroup(){
      this.group = this.getGroup(this.uid)
    },
    isSelected(collection){
      if(this.currentCollection == collection.name){
        return true
      }
      return false
    },
    openDashboard(index){
      let routeData = this.$router.resolve({name: 'dashboardchart', query: {index: index}});
      window.open(routeData.href, '_blank');
    },
    
    openManagementPage(collection){
      let routeName = ''
      switch(collection.name ){
        case "importConfig":
          routeName = "importconfig"
          break;
        case "importUsers":
          routeName = "importusers"
          break;
        case "bulkEdit":
          routeName = "bulkedit"
          break;
        default:
          if(this.currentCollection != collection.name){
            this.SET_PAGE_LOADING_STATE(true)
          }
          routeName = "management"
        
      }
      this.$emit('selected', collection.name)
      this.$router.push({name: routeName})
    },
    openAddOnsPage(collection){
      if(this.currentCollection != collection.name){
        this.SET_PAGE_LOADING_STATE(true)
      }
      this.$emit('selected', collection.name)
      this.$router.push({
        name: 'addons'
      })
    },
    isShownDashboard(){
      this.isShown = this.dashboardUrls ? true : false
    },
    triggerCollapse() {
      if (this.collapsShow == false) {
        this.collapsShow = true
        this.expandShow = "expand_more"
      } else {
        this.collapsShow = false
        this.expandShow = "chevron_right"
      }
    },
    triggerCollapseManagement() {
      if(this.isAdmin){
        if (this.collapseManagement == false) {
          this.collapseManagement = true
          this.expandManagement = "expand_more"
        } else {
          this.collapseManagement = false
          this.expandManagement = "chevron_right"
        }
      }
    },
    triggerCollapseBulk(){
      if(this.isAdmin){
        if(this.collapseBulk == false){
          this.collapseBulk = true
          this.expandBulk = "expand_less"
        } else{
          this.collapseBulk = false
          this.expandBulk = "expand_more"
        }
      }
    },
    triggerCollapseAddOns() {
      if (this.collapseAddOns == false) {
        this.collapseAddOns = true
        this.expandAddOns = "expand_more"
      } else {
        this.collapseAddOns = false
        this.expandAddOns = "chevron_right"
      }
    },
    triggerCollapseDashboard(){
      if (this.collapseDasboard == false) {
        this.collapseDasboard = true
        this.expandDashboard = "expand_more"
      } else {
        this.collapseDasboard = false
        this.expandDashboard = "chevron_right"
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';

.side-nav-wrapper {
  .modal-wrapper {
    .modal-content {
      padding-right: 0px;
      .modalAccount {
        .font-white {
          color: #ffffff
        }

        .title {
          padding-left: 24px;
        }

        .separator {
          div{
            height: 1px;
            background-color: #999999
          }
        }

        .message {
          padding: 24px 0px 24px 24px;
          text-align: justify;
          span {
            font-size: 14px;
          }
        }
      }
    }

    .modal-footer {
      .menuAccount{
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;
        margin-right: 18px;
      }
    }
  }
}

</style>