<template>
    <oba-modal 
      class="modal-report" 
      id="download-report-1"
      title="Report Template"
      :show="visible" 
      @close="close">
      <template v-slot:modal-content>
          <div  class="mid-content">
            <oba-form-builder :key="formKey" ref="reportForm" :fields="listFieldDownloadReport"/>
          </div>
      </template>
      <template v-slot:modal-footer>
          <div class="buttons">
            <oba-button 
              class="btn-cancel" 
              category="secondary" 
              label="Cancel" 
              @onClick="close">
            </oba-button>
            <oba-button 
              category="primary" 
              label="Download" 
              :isDisabled="isRequesting" 
              :isLoading="isRequesting" 
              @onClick="submit">
            </oba-button>
          </div>
      </template>
    </oba-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ObaModal from '@/components/modal'
import ObaButton from '@/components/button'
import FormBuilder from '@/components/formBuilder'

import { apiUrls } from '@/lib/request/urls'
import { doRequest } from '@/lib/request'
import { showNotification } from '@/lib/utils'
import { generateProps } from '@/lib/propsGenerator'


const {mapState: mapStateConfig} = createNamespacedHelpers('config')
const {mapState: mapStateUser} = createNamespacedHelpers('user')

export default {
  name: 'download-report',
  components: {
    'oba-modal': ObaModal,
    'oba-button': ObaButton,
    'oba-form-builder': FormBuilder,
  },
  setup(){
    async function createDownloadReport(data){
      const config = {
        url: apiUrls.createReport(this.projectName, this.pageState.collection, this.docId),
        method: 'post',
        data : {
          templateId: data["template id"],
          filters: this.filters
        }
      }
      const response = await doRequest(config, {needToken: true})
      return response
    }

    return { createDownloadReport }
  },
  props:{
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    filters: {
      type: Array,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isRequesting: false,
      formKey: 0,
      fieldDownloadReport: [
        {
          name: "template id",
          displayText: "Template ID",
          type: "text",
          validations: {
            required: true
          }
        }
      ],
      listFieldDownloadReport: []
    }
  },
  beforeMount(){
    this.generateEditFields()
  },
  watch:{
    'visible': function (newValue, oldValue){
      if(newValue){
        this.generateEditFields()
      }
    }
  },
  computed:{
    ...mapStateUser([
      'pageState'
    ]),
    ...mapStateConfig([
      'projectName'
    ]),
  },
  methods:{
    async generateEditFields(){
      this.listFieldDownloadReport = await generateProps(this.fieldDownloadReport)
      this.formKey++
    },
    close(){
      this.$emit('close')
    },
    setLoadingAndDisable(state) {
      this.isRequesting = state
    },
    async submit(){
      this.setLoadingAndDisable(true)
      const data = this.collectData()
      if(data){
        const response = await this.createDownloadReport(data)
        if(response.status == 201){
          showNotification('success', `Success, BAST queued`)
          this.setLoadingAndDisable(false)
          this.$emit('submit')
        } else {
          showNotification('error', `Error, Failed to create BAST`)
          this.setLoadingAndDisable(false)
        }
      }
      this.setLoadingAndDisable(false)
    },
    collectData(){
      const form = this.$refs['reportForm']
      const formData = form.collectValue()
    
      if(!formData) return null

      return formData
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">

</style>

