import initialProps from "./initialProps"
import moment from 'moment'

// declare all initial props
const propsInputText = initialProps.inputTextProps
const propsInputCheckbox = initialProps.inputCheckboxProps
const propsInputRadio = initialProps.inputRadioProps
const propsInputAutocomplete = initialProps.inputAutocompleteProps
const propsInputDate = initialProps.inputDateProps
const propsInputDropdown = initialProps.inputDropdownProps
const propsInputTextarea = initialProps.inputTextareaProps
const propsInputFile = initialProps.inputFileProps
const propsSeparator = initialProps.separatorProps
const propsInputDateTime = initialProps.inputDateTimeProps


/**
 * Get component props for each input type
 * 
 * @param {Object} compProps 
 * Initial props from component. Below is an example from text props
 * {
    type: "",
    id: "",
    label: "",
    inputValue: "",
    dependency: null,
    validations: {},
    isDisabled: false,
    visible: true,
    class: [],
    formWidthClass: "",
    helperText: "",
    displayText: "",
    extra: {
      placeholder: "",
      inputType: "text"
    }
  }
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * 
 * @returns {JSON} copiedProps
 * Object from compProps that the same in item will be copied and update the values of compProps into item key.
 */

function injectComponentProps(compProps, item) {
  // Deep copy props into new object to cut pass by reference
  let copiedProps = JSON.parse(JSON.stringify(compProps))
  for (var key of Object.keys(copiedProps)) {
    if (Object.keys(item).includes(key)) {
      copiedProps[key] = item[key]
    }
  }
  // Id dan label diambil dari property name
  copiedProps['id'] = item.name.toLowerCase()
  copiedProps['label'] = item.name

  // Check dependency, jika ada maka visibility jadi false untuk hide form
  if (copiedProps['dependency']){
    copiedProps['visible'] = false
  }
  return copiedProps
}

/**
 * Get props from input separator
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return the props needed for separator
 */
function transformSeparator(item) {
  let props = injectComponentProps(propsSeparator, item)
  if (item.description) {
    props.extra.description = item.description
  }
  return props
}


/**
 * Get props from input checkbox
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return the props needed for checkbox 
 */
function transformToInputCheckbox(item) {
  let props = injectComponentProps(propsInputCheckbox, item)
  if(!props.inputValue){
    props.inputValue = []
  }
  props.extra.options = item.choices.map(item =>{
    return {
      id: item,
      value: item
    }
  })
  return props
}

/**
 * Get props from input text
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return the props need for text or geopoint
 */
function transformToInputText(item) {
  let props = injectComponentProps(propsInputText, item)
  if(item.type == 'geopoint'){
    props.extra.inputType = 'text'
  } else if (item.type == 'number'){
    props.extra.numberType = item.numberType
    props.extra.inputType = item.type
  } else {
    props.extra.inputType = item.type
  }
  if (item.placeholder) {
    props.extra.placeholder = item.placeholder
  }

  if (item.icon) {
    props.extra.icon = item.icon
  }

  return props
}

/**
 * Get props from input radio button
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return the props needed for radio button
 */
function transformToInputRadio(item) {
  let props = injectComponentProps(propsInputRadio, item)
  props.extra.options = item.choices.map(item =>{
    return {
      id: item,
      value: item
    }
  })
  return props
}

/**
 * Get props from input autocomplete
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return props needed for Autocomplete input
 */
function transformToInputAutocomplete(item) {
  let props = injectComponentProps(propsInputAutocomplete, item)
  let tempValue = {}
  item.choices.forEach( option => {
    // Options bentuknya object
    // Contoh : {"name": value, "name": value}
    // Value berisi link untuk image  apabila ada image
    // Value diisi null apabila tidak ada link imagenya
    // karena tidak ada kebutuhan memakai image, maka defaultnya null
    tempValue[option] = null
  })
  props.extra.options = tempValue
  return props
}

/**
 * Get props from input dropdown
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return props needed for dropdown
 */
function transformToInputDropdown(item) {
  let props = injectComponentProps(propsInputDropdown, item)
  props.extra.options = item.choices.map( option => {
    return {
      id: option,
      label: option,
      value: option,
      disabled: false
    }
  })
  return props
}

/**
 * Get props from input text area
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return the props needed for text area
 */
function transformToInputTextarea(item) {
  let props = injectComponentProps(propsInputTextarea, item)
  return props
}

/**
 * Get props from input date
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return props needed for date input with the format
 */
function transformToInputDate(item) {
  let props = injectComponentProps(propsInputDate, item)
  if (item.format) {
    props.extra.format = item.format
  }
  // add today as default value
  if(item.autofill){
    props.inputValue = moment(new Date()).format('DD MMM YYYY')
  }
  return props
}

/**
 * Get props from input datetime
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return props needed for datetime 
 */
function transformToInputDateTime(item){
  let props = injectComponentProps(propsInputDateTime, item)
  if(item.autofill){
    props.inputValue = moment(new Date()).format('DD MMM YYYY, HH:mm')
  }
  return props
}

/**
 * Get props from input file
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} props
  Return props needed for file input
 */
function transformToInputFile(item) {
  let props = injectComponentProps(propsInputFile, item)
  if(!props.inputValue){
    props.inputValue = {}
  }
  if(item.hasOwnProperty('isMultiple')){
    props.extra.isMultiple = item.isMultiple
  }
  if(item.hasOwnProperty('accept')){
    props.extra.accept = item.accept
  }
 
  return props
}

/**
 * Get props from input needed from config
 * @param {Object} item 
  Object from the component need to show. These property retrieved from the config file
  {
    name: ,
    description: ,
    choice: ,
    type: ,
    placeholder: ,
    icon: ,
    format: ,
    autofill: ,
  }
 * @returns {JSON} data
  Return props needed from each datatype for the input
 */
function transformData(item) {
  // trigger method generate data based on input type
  let data = {}
  switch(item.type) {
    case "geopoint":
    case "number":
    case "password":
    case "text":
      data = transformToInputText(item)
      break
    case "checkbox":
      data = transformToInputCheckbox(item)
      break
    case "dropdown":
      data = transformToInputDropdown(item)
      break
    case "autocomplete":
      data = transformToInputAutocomplete(item)
      break
    case "radio":
      data = transformToInputRadio(item)
      break
    case "textarea":
      data = transformToInputTextarea(item)
      break
    case "date":
      data = transformToInputDate(item)
      break
    case "datetime":
      data = transformToInputDateTime(item)
      break
    case "file":
      data = transformToInputFile(item)
      break
    case "separator":
      data = transformSeparator(item)
    default:
      break
  }
  return data
}

/**
 * 
 * @param {Object} dataConfig entries from the config
 * @returns {Object} dataConfig
 * dataConfig that has been updated with props for each entries
 */
// generate props from data sent to a proper props
function generateProps(dataConfig) {
  return dataConfig.map(item => {
    return transformData(item)
  });
}

export { generateProps }